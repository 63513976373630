
import { defineComponent, reactive, toRefs, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import AV from 'leancloud-storage'
import { useRouter } from 'vue-router'
import Util from '@/utils/util.js'
import LoginByAccount from './loginByAccount.vue'
import LoginByScan from './loginByScan.vue'
import HLDialog from '@/components/dialog.js'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/outline'
const faqs = [
  // {
  //   question: '账号密码登录',
  //   answer: '若您没有账号密码，请使用扫码登录。'
  // },
  {
    question: '扫码登录',
    answer:
      '如在微信小程序内绑定过图书码，则使用微信首页右上角扫一扫功能，扫描当前屏幕上的二维码，即可登录。'
  },
  {
    question: '浏览器',
    answer:
      '建议使用最新版本Chrome浏览器。(不支持IE浏览器。Edge ≥ 79	| Firefox ≥ 78	| Chrome ≥ 64	| Safari ≥ 12)'
  }, {
    question: '显示器屏幕分辨率',
    answer:
      '建议显示器屏幕分辨率为1920x1080。'
  },
  {
    question: '网页权限',
    answer: '使用过程中，请调大音量。并确保录音权限打开。'
  }

  // ,
  // {
  //   question: '申请成为老师',
  //   answer: '点击链接，申请成为老师。',
  //   router: 'ApplyTeacher'
  // }
]

export default defineComponent({
  name: 'Login',
  props: {},
  components: {
    LoginByAccount,
    LoginByScan,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronDownIcon
  },

  setup() {
    // BizQQWPA.addCustom({
    //   aty: '0', // 指定工号类型,0-自动分流，1-指定工号，2-指定分组
    //   nameAccount: '370406497', // 指定的聊天 QQ 号码
    //   selector: 'contactQQ' // 触发聊天的标签id
    // })
    const router = useRouter()
    const store = useStore()
    const state = reactive({
      requesting: false,
      qrUrl: '',
      currentTab: 1,
      currentStudent: {},
      intervalMaxCount: 15
    })
    onMounted(async () => {
      if (AV.User.current()) {
        AV.User.logOut()
      }
      // 判断是否为移动端
      const flag = navigator.userAgent.match(
        /(username|pad|pod|iusername|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows username)/i
      )
      console.log(flag)
      if (flag) {
        HLDialog({
          content: '仅在Web端使用',
          doneAndNext: () => { }
        })
      }
    })

    const toRouter = (routerName) => {
      router.push({ name: routerName })
    }

    return {
      toRouter,
      faqs,
      ...toRefs(state)
    }
  }
})
