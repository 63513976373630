<template>
  <div class="font-HLFont ">
    <input
      type="checkbox"
      id="hl-dialog-modal"
      class="modal-toggle"
      checked
    >
    <div class="modal">
      <div class="modal-box w-1/2 h-3/4  ">
        <div
          class="flex flex-col items-center"
          style="font-size: 18px;color: #11152B;letter-spacing: 4px;font-weight: bold;"
        >答题提示</div>
        <div class="scrollbar-track-gray-200 scrollbar-thin scrollbar  h-5/6 pb-3 ">

          <!--情景问答 -->
          <div
            v-if="question.questionType.series==4"
            class="flex flex-col   justify-center w-full"
          >

            <div
              style="margin-top: 60px;"
              :key="item"
              v-for="(item,tempIndex) in [0,1]"
            >
              <div
                style="text-indent:22px;font-size: 13px;margin-top:10px;"
                v-for="(intro) in originalListeningTexts[tempIndex].intro"
                :key="intro"
              >{{tempIndex+1}}.{{intro}}</div>
              <div style="text-indent:22px;font-size: 16px;font-weight: bold;color: black;letter-spacing: 0px;margin-top: 10px;;">
                参考答案
              </div>

              <div
                class="flex flex-col"
                style="text-indent:22px;font-size: 13px;margin-top:3px;"
              >
                <div> {{referenceAnswers[tempIndex].referenceAnswer}}</div>
              </div>
            </div>

          </div>
          <div v-else>
            <div class="flex flex-col items-center justify-center w-full  ">

              <div
                v-if="originalListeningTexts && originalListeningTexts.length>0"
                class="w-full"
                style="font-size: 15px;font-weight: bold;color: #11152B;letter-spacing: 2px;margin-top:20px;"
              >
                听力原文
              </div>
              <div
                class="w-full "
                v-if="originalListeningTexts && originalListeningTexts.length>0"
                style="text-indent:32px; background: #FCFAFD;padding-top:20px;padding-bottom:20px;border-radius: 24px;margin-top:10px;padding-left:10px;padding-right:10px;"
              >
                <div
                class="pl-4 pr-4"
                  v-for="(item) in originalListeningTexts"
                  :key="item"
                >
                  <div
                    :style="[((item.intro[0].indexOf('Hi')>-1 || item.intro[0].indexOf('Dear') >-1) && item.intro.length>3 && introIndex> item.intro.length-3)?'text-align:right':'text-align:left;','text-indent:22px;font-size: 13px;margin-top:5px;']"
                    v-for="(intro,introIndex) in item.intro"
                    :key="intro"
                  >{{intro}}</div>
                </div>
              </div>
            </div>
            <!--   -->
            <div
              v-if="referenceAnswers && referenceAnswers.length>0"
              class="flex flex-col mt-2"
            >

              <div
                class="flex flex-col"
                v-for="(item)  in referenceAnswers"
                :key="item"
                style="margin-top:10px;"
              >
                <div v-if="question.questionType.series===1 ||  question.questionType.series===2 ">
                  <div class="flex-row flex  items-center">
                    <div
                      class="flex flex-row  justify-center items-center"
                      style="width:110px;height:33px;background: #E0F7EF;border-radius: 15px;font-size: 14px;font-weight: bold;color: black;letter-spacing: 0px;"
                    >
                      {{item.indexInOneDay}}.参考答案
                    </div>

                    <div
                      class="ml-10 flex flex-row   justify-center items-center"
                      style="width:42px;height:33px;background: #F1F7FF;border-radius: 33px;font-size: 14px;font-weight:400;letter-spacing: 0px;"
                    >
                      {{item.referenceAnswer}}
                    </div>
                  </div>

                </div>
                <div  v-if="question.questionType.series===3 ||  question.questionType.series===5 ">
                <div class="flex-col flex  ">
                    <div
                      class="flex flex-row  justify-center items-center"
                      style="width:110px;height:33px;background: #E0F7EF;border-radius: 15px;font-size: 14px;font-weight: bold;color: black;letter-spacing: 0px;"
                    >
                      {{item.indexInOneDay}}.参考答案
                    </div>

                    <div
                      class="flex flex-row   justify-center items-center"
                      style="text-indent:32px; background: #FCFAFD;padding-top:20px;padding-bottom:20px;border-radius: 24px;margin-top:10px;padding-left:10px;padding-right:10px;"
                    >
                      {{item.referenceAnswer}}
                    </div>
                  </div>

                </div>

              </div>

            </div>

          </div>
        </div>
        <div class="modal-action fixed -top-3 right-4 ">
          <!-- <XIcon /> -->
          <XIcon
            @click="doneAndNext"
            for="hl-dialog-modal"
            class="text-gyjdGreen-500 h-7 w-7  cursor-pointer border-gyjdGreen-500 border-2 rounded-lg"
            aria-hidden="true"
          />
          <!-- <label
            @click="doneAndNext"
            for="hl-dialog-modal"
            class="btn btn-gyjdGreen-500 "
          >关闭</label> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, ref, reactive, toRefs, onMounted, defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import _ from 'lodash'
import { XIcon } from '@heroicons/vue/solid'
import AV from 'leancloud-storage'

export default defineComponent({
  components: {
    XIcon
  },
  name: 'HL-answer-tips',
  props: {
    question: {
      type: Object,
      required: true
    },
    allQuestion: {
      type: Array,
      required: true
    },
    allHasGroupQuestionsJSON: {
      type: Array,
      required: true
    },
    doneAndNext: {
      type: Function,
      default: () => {
        return Function
      }
    }

  },
  setup (props, { emit }) {
    const state = reactive({
      referenceAnswers: ref(null),
      originalListeningTexts: ref(null)
    })

    // eslint-disable-next-line vue/no-setup-props-destructure
    const currentQuestion = props.question
    const questionType = currentQuestion.questionType
    const originalListeningTexts = []
    const referenceAnswers = []

    if (currentQuestion.groupQuestion) {
      // 套题
      // 找到这套题的第一题（含小数的）
      const groupQuestion = props.allHasGroupQuestionsJSON.find(gq => {
        return gq.groupQuestion && gq.groupQuestion.objectId === currentQuestion.groupQuestion.objectId
      })

      if (questionType.series === 1 || questionType.series === 2 || (questionType.series === 4 && currentQuestion.grade.series == 3)) {
        props.allHasGroupQuestionsJSON.map(question => {
          if (question.groupQuestion && question.groupQuestion.objectId === groupQuestion.objectId) {
            question.questionData[0].map(subject => {
              if (subject.intro && subject.intro.length > 0 && subject.voice && subject.voice.url.length > 0) {
                originalListeningTexts.push({
                  indexInOneDay: question.indexInOneDay,
                  intro: subject.intro.split('&')
                })
              }
            })
          }
        })
      }

      // 不含小数（xxxxx.5）
      const questionsInThisGroup = props.allQuestion.filter(gq => {
        return gq.groupQuestion && gq.groupQuestion.id === currentQuestion.groupQuestion.objectId
      })
      questionsInThisGroup.map(question => {
        if (question.answerType === 0) {
          // 听力选择题
          const sn = question.answerSelection[0].sn
          referenceAnswers.push({
            answerType: question.answerType,
            indexInOneDay: question.indexInOneDay,
            referenceAnswer: sn === 1 ? 'A' : sn === 2 ? 'B' : sn === 3 ? 'C' : 'D'
          })
        } else if (question.answerType === 2 && question.standardAnswerText && question.standardAnswerText.length > 0) {
          // 口语测评题
          referenceAnswers.push({
            answerType: question.answerType,
            indexInOneDay: question.indexInOneDay,
            referenceAnswer: question.standardAnswerText
          })
        }
      })
      state.originalListeningTexts = originalListeningTexts
      state.referenceAnswers = referenceAnswers
    } else {
      if (questionType.series === 1 || questionType.series === 2 || (questionType.series === 4 && currentQuestion.grade.series === 3)) {
        currentQuestion.questionData[0].map(subject => {
          // 处理bug

          // 第二个
          if (subject.intro && subject.intro.length > 0) {
            originalListeningTexts.push({
              indexInOneDay: currentQuestion.indexInOneDay,
              intro: subject.intro.split('&')
            })
          }
        })
      }

      if (currentQuestion.answerType === 0) {
        // 听力选择题
        const sn = currentQuestion.answerSelection[0].sn
        referenceAnswers.push({
          answerType: currentQuestion.answerType,
          indexInOneDay: currentQuestion.indexInOneDay,
          referenceAnswer: sn === 1 ? 'A' : sn === 2 ? 'B' : sn === 3 ? 'C' : 'D'
        })
      } else if (currentQuestion.answerType === 2 && currentQuestion.standardAnswerText && currentQuestion.standardAnswerText.length > 0) {
        // 口语测评题
        referenceAnswers.push({
          answerType: currentQuestion.answerType,
          indexInOneDay: currentQuestion.indexInOneDay,
          referenceAnswer: currentQuestion.standardAnswerText
        })
      }
      state.originalListeningTexts = originalListeningTexts
      state.referenceAnswers = referenceAnswers
    }

    return {
      ...toRefs(state)
    }
  }
})
</script>
