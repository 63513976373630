<template>
  <div
    class="  min-h-screen w-full bg-gray-100 p-10 h-full flex flex-row justify-center items-center "
  >
    <div
      class="w-11/12 h-11/12 min-h-full min-w-full flex flex-row  "
      style=" background: #FFFFFF;border: 1px solid #F8F8F8;box-shadow: 0px 3px 39px rgba(195, 195, 195, 0.16);"
    >
      <div
        v-if="!selectedGradeId && initDone"
        class="p-10 w-full h-full flex flex-col justify-center items-center"
      >
        <button
          v-for="(unit, index) in gradesStatus"
          :key="unit.gradeId"
          @click="toQuestionType(unit, index)"
          class="btn btn-outline my-2 w-1/2 flex flex-row justify-between"
        >
          <img :src="unit.showIcon" class="w-8 h-8" />
          <div class="">{{ unit.showName }}</div>
          <div class="mr-10 text-green-600">{{ unit.percent }}%</div>
        </button>
      </div>
      <div
        v-if="!selectedGradeId && !initDone"
        class="p-60 w-full h-full flex flex-col justify-center items-center"
      >
        <button class="btn btn-sm btn-outline btn-primary loading">
          loading
        </button>
      </div>
      <TrainingQuestionType
        v-if="deviceChecked && selectedGradeId && unitName && initDone"
        :gradeId="selectedGradeId"
        :unitName="unitName"
        @toBack="toBack"
      />
      <DeviceCheck
        v-if="!deviceChecked && selectedGradeId && unitName && initDone"
        @checkDone="deviceCheckDone"
      />
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from "vue";
import { useStore } from "vuex";
import AV from "leancloud-storage";
import _ from "lodash";
import Util from "@/utils/util.js";
import TrainingQuestionType from "@/views/study/trainingQuestionType.vue";
import DeviceCheck from "@/views/study/deviceCheck.vue";
export default {
  components: {
    TrainingQuestionType,
    DeviceCheck,
  },
  setup() {
    const store = useStore();
    const state = reactive({
      gradesStatus: [],
      initDone: false,
      selectedGradeId: ref(null),
      unitName: ref(null),
      deviceChecked: ref(null),
    });
    onMounted(async () => {
      store.state.student = await new AV.Query("GYJDStudent")
        .equalTo("user", AV.User.current())
        .include("group")
        .include("school")
        .include("teacher")
        .include("user")
        .equalTo("default", true)
        .first();
      store.state.studentJSON = Util._jsonify(store.state.student);
      await onLoad();
      state.deviceChecked = localStorage.getItem("deviceChecked");
    });
    const onLoad = async () => {
      await getStudySchedule();
      await getAllGradesByThisGradeSeries();
      await getAllQuestionTypeInThisGrade();
      state.grades = await new AV.Query("GYJDGrade")
        .equalTo("level", store.state.studentJSON.group.gradeIndex + "")
        .find();
      const gradesStatusFirst = [];
      for (let i = 0; i < state.grades.length; i++) {
        const grade = state.grades[i];
        gradesStatusFirst.push({
          showName: grade.showName,
          showEName: grade.showEName,
          showIcon: grade.showIcon,
          index: i,
        });
      }
      const gradesStatus = [];
      for (let i = 0; i < state.grades.length; i++) {
        const grade = Util._jsonify(state.grades[i]);
        // console.log(grade)
        const allQuestionTypeIsDoneCounts = await getQuestionTypesDoneCountsInGrade(
          state.allQuestionType,
          state.grades[i]
        );

        const percent =
          _.floor(
            _.divide(allQuestionTypeIsDoneCounts, state.allQuestionType.length),
            2
          ) * 100;
        console.log();

        gradesStatus.push({
          showName: grade.showName,
          showIcon: grade.showIcon,
          index: i,
          gradeId: grade.id ? grade.id : grade.objectId,
          percent,
          ifCanContinue: true,
        });
      }
      state.gradesStatus = gradesStatus;
      state.initDone = true;
    };
    const getAllGradesByThisGradeSeries = async () => {
      // 非常重要
      const allGrades = await new AV.Query("GYJDGrade")
        .ascending("indexInt")
        .equalTo("level", store.state.studyScheduleJSON.grade.level)
        .find();
      store.state.allGrades = allGrades;
      store.state.allGradesJSON = Util._jsonify(allGrades);
      state.allGrades = allGrades;
      state.allGradesJSON = Util._jsonify(allGrades);
    };
    const getStudySchedule = async () => {
      const studySchedule = await new AV.Query("GYJDStudySchedule")
        .include("grade")
        .equalTo("student", store.state.student)
        .equalTo("validated", true)
        .first();
      store.state.studySchedule = studySchedule;
      store.state.studyScheduleJSON = Util._jsonify(studySchedule);
      state.studySchedule = studySchedule;
      state.studyScheduleJSON = Util._jsonify(studySchedule);
    };
    const getAllQuestionTypeInThisGrade = async () => {
      const allQuestionType = await new AV.Query("GYJDQuestionType").find();
      store.state.allQuestionType = allQuestionType;
      store.state.allQuestionTypeJSON = Util._jsonify(allQuestionType);
      state.allQuestionType = allQuestionType;
      state.allQuestionTypeJSON = Util._jsonify(allQuestionType);
    };
    const getQuestionTypesDoneCountsInGrade = async (questionTypes, grade) => {
      return await new AV.Query("GYJDEverySetAnswerStatistics")
        .containedIn("questionType", questionTypes)
        .equalTo("grade", grade)
        .equalTo("student", store.state.student)
        .equalTo("anyDay", store.state.studyScheduleJSON.currentDay)
        .count();
    };

    const toQuestionType = async (unit) => {
      state.unitName = unit.showName;
      state.selectedGradeId = unit.gradeId;
    };
    const toBack = () => {
      state.selectedGradeId = ref(null);
      state.unitName = ref(null);
    };
    const deviceCheckDone = () => {
      _.delay(
        function() {
          state.deviceChecked = true;
        },
        500,
        ""
      );
    };
    return {
      onLoad,
      getAllGradesByThisGradeSeries,
      getStudySchedule,
      getAllQuestionTypeInThisGrade,
      getQuestionTypesDoneCountsInGrade,
      toQuestionType,
      toBack,
      deviceCheckDone,
      ...toRefs(state),
    };
  },
};
</script>
