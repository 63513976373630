<template>
  <div class="  mt-20">

      <div v-if="isEvaluation">
        <button class="btn btn-error max-w-80 mt-10 loading">
          语音文件测评中...
        </button>
      </div>

      <div v-if="!isEvaluation" @click="toStop" class=" cursor-pointer">
        <VueLottiePlayer
          name="recordingAnim"
          loop
          width="80px"
          height="80px"
          :animationData="require('@/assets/lotties/recording.json')"
          renderer="canvas"
          @animControl="(anim) => (recordingAnim = anim)"
        />
        <div v-if="seconds > 1" class="mt-2 underline ">点击停止录音</div>
      </div>

  </div>
</template>
<script>
import {
  reactive,
  toRefs,
  defineComponent,
  onMounted,
  ref,
  watch,
  onBeforeUnmount
} from 'vue'
import Recorder from 'recorder-core/recorder.mp3.min'
import VueLottiePlayer from 'vue-lottie-player'
import AV from 'leancloud-storage'
import _ from 'lodash'
export default defineComponent({
  components: {
    VueLottiePlayer
  },
  name: 'HL-record',
  props: ['question', 'maxSeconds'],
  emits: ['onEnd', 'beingEvaluation'],
  setup (props, { emit }) {
    console.log(props.maxSeconds)
    const state = reactive({
      recordingAnim: ref(null),
      isEvaluation: false,
      ifDoAnswer: false,
      isShowResult: false,
      error: ref(null),
      seconds: 0,
      recorder: ref(null),
      timer: setInterval(async function () {
        if (state.seconds >= props.maxSeconds) {
          state.timer && clearInterval(state.timer)
          if (!state.ifDoAnswer) {
            await stopRecording()
          }
        } else {
          state.seconds++
        }
      }, 1000)
    })
    onMounted(async () => {
      await onLoad()
    })

    const onLoad = async () => {
      state.recorder = Recorder({
        type: 'mp3',
        sampleRate: 16000,
        bitRate: 64000
      })
      _.delay(
        function () {
          state.recorder.open(
            function () {
              state.error = ref(null)
              state.recorder.start()
              // success && success()
            },
            function () {
              // 用户拒绝未授权或不支持
              state.error = '麦克风未授权'
            }
          )
        },
        500,
        ''
      )
    }

    const startRecording = async () => {
      //   console.log('start')
      state.recorder.start()
    }
    const stopRecording = async () => {
      state.recorder.stop(
        async function (blob) {
          // console.log(blob, duration)
          try {
            state.recorder.close()
            state.recorder = ref(null)
            state.recordingAnim && state.recordingAnim.stop()
            state.isEvaluation = true
            state.ifDoAnswer = true
            const file = new AV.File(
              'GYJD-web-' + AV.User.current().id + '.mp3',
              blob
            )
            await file.save()

            emit('beingEvaluation')

            let standardAnswerText = ''
            props.question.keywords.map(keyword => {
              standardAnswerText += keyword
            })
            const userRecordedMp3Url = process.env.NODE_ENV === 'development' ? 'https://file.nj.hlingsoft.com/gmWx5u8TAiBPMPt2TObDjAbPY7jU9xzg/01.mp3' : file.url()

            AV.Cloud.run('LJLEvaluation', { userRecordedMp3Url, standarText: standardAnswerText.trim() }).then(function (data) {
              // 处理结果
              console.log(data)
              emit('onEnd', {
                evaluationData: data,
                userVoiceUrl: file.url(),
                evaluationSeconds: state.seconds
              })
            }, function (err) {
              // 处理报错
              console.log(err)
              emit('onEnd', {
                evaluationData: {},
                evaluationSeconds: state.seconds
              })
            })
          } catch (e) {
            console.log(e)
            emit('onEnd', {
              evaluationData: {},
              evaluationSeconds: state.seconds
            })
          }
        },
        function (msg) {
          console.log('录音失败:' + msg)
          state.recorder.close() // 可以通过stop方法的第3个参数来自动调用close
          state.recorder = ref(null)
          state.recordingAnim = ref(null)
          state.isEvaluation = false
          state.isShowResult = false
          onLoad()
        }
      )
    }
    const toStop = async () => {
      if (state.seconds > 1) {
        if (!state.ifDoAnswer) {
          await stopRecording()
        }
      }
    }

    onBeforeUnmount(async () => {
      state.recordingAnim && state.recordingAnim.stop()
      state.recorder && state.recorder.close()
      state.recorder = ref(null)
    })
    return {
      onLoad,
      toStop,
      startRecording,
      stopRecording,
      ...toRefs(state)
    }
  }
})
</script>
