
import { createApp } from 'vue'
import ConfirmVue from './Confirm.vue'
function ConfirmDialog ({ content, close, doneAndNext }) {
  return new Promise(function (resolve) {
    const confirmInstance = createApp(ConfirmVue, {
      content: content || '操作确认',
      close: async () => {
        unmount()
        await close()

        resolve('done')
      },
      doneAndNext: async () => {
        unmount()
        await doneAndNext()
        resolve('done')
      }
    })

    const unmount = () => {
      confirmInstance.unmount()
      document.body.removeChild(parentNode)
    }
    const parentNode = document.createElement('div')
    document.body.appendChild(parentNode)
    confirmInstance.mount(parentNode)
  })
}
export default ConfirmDialog
