<template>
  <div class="   p-20 pt-10 h-full w-full pb-10 min-h-full min-w-full ">
    <nav aria-label="Progress">
      <ol role="list" class=" flex space-y-0 space-x-8">
        <li v-for="(step, index) in steps" :key="step.name" class="md:flex-1">
          <a
            v-if="step.status === 'complete'"
            @click="toStep(step, index)"
            class="group pl-4 py-2 flex flex-col border-l-4 border-indigo-600 hover:border-indigo-800 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
          >
            <span
              class="text-xs text-indigo-600 font-semibold tracking-wide uppercase group-hover:text-indigo-800"
              >{{ step.id }}</span
            >
            <span class="text-sm  text-indigo-600  font-medium">{{
              step.name
            }}</span>
          </a>
          <a
            @click="toStep(step, index)"
            v-else-if="step.status === 'current'"
            :href="step.href"
            class="pl-4 py-2 flex flex-col border-l-4 border-indigo-600 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
            aria-current="step"
          >
            <span
              class="text-xs text-indigo-600 font-semibold tracking-wide uppercase"
              >{{ step.id }}</span
            >
            <span class="text-sm text-indigo-600 font-medium">{{
              step.name
            }}</span>
          </a>
          <a
            v-else
            :href="step.href"
            class="group pl-4 py-2 flex flex-col border-l-4 border-gray-200 hover:border-gray-300 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
          >
            <span
              class="text-xs text-gray-600 font-semibold tracking-wide uppercase group-hover:text-gray-700"
              >{{ step.id }}</span
            >
            <span class="text-sm  text-gray-400 font-medium">{{
              step.name
            }}</span>
          </a>
        </li>
      </ol>
    </nav>

    <!-- 具体界面-->

    <div
      v-if="currentStepIndex == 0"
      class="w-11/12 h-5/6 flex flex-row  justify-center items-center mt-10 p-10"
      style=" background: #FFFFFF;border: 1px solid #F8F8F8;box-shadow: 0px 3px 39px rgba(195, 195, 195, 0.16);"
    >
      <div class="flex-1 justify-center items-center flex flex-col">
        <div class="my-10 ml-40 mr-20 text-gray-500">
          使用微信扫描右侧二维码获取图书资源
        </div>
      </div>
      <div class="flex-1  justify-center items-center flex">
        <img src="@/assets/images/miniapp_barcode.png" class="w-40 h-40" />
      </div>
    </div>
    <div
      v-if="currentStepIndex == 1"
      class="w-11/12 h-5/6 flex flex-row justify-center items-center mt-10 p-10"
      style=" background: #FFFFFF;border: 1px solid #F8F8F8;box-shadow: 0px 3px 39px rgba(195, 195, 195, 0.16);"
    >
      <div class="block w-3/5 rounded-lg overflow-hidden">
        <VuePlayerVideo
          v-if="readingVideoJSON && readingVideoJSON.videos"
          autoplay
          :src="readingVideoJSON.videos[0]"
        />
      </div>
    </div>

    <div
      v-if="currentStepIndex == 2"
      class="w-11/12 h-5/6 flex flex-row justify-center items-center mt-10 p-10"
      style=" background: #FFFFFF;border: 1px solid #F8F8F8;box-shadow: 0px 3px 39px rgba(195, 195, 195, 0.16);"
    >
      <div class="block w-3/5 rounded-lg overflow-hidden">
        <VuePlayerVideo
          v-if="readingVideoJSON && readingVideoJSON.videos"
          autoplay
          :src="readingVideoJSON.videos[1]"
        />
      </div>
    </div>
    <div
      v-if="currentStepIndex == 3"
      class="w-11/12 h-full flex flex-row justify-center items-center mt-10 p-10 "
      style=" background: #FFFFFF;border: 1px solid #F8F8F8;box-shadow: 0px 3px 39px rgba(195, 195, 195, 0.16);"
    >
      <DoReadingAnswer :period="period" />
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, defineComponent } from 'vue'
import { useStore } from 'vuex'
import AV from 'leancloud-storage'
import DayJS from 'dayjs'
import VuePlayerVideo from 'vue3-player-video'
import Util from '@/utils/util.js'

import DoReadingAnswer from '@/views/activity/doReadingAnswer.vue'
export default defineComponent({
  components: {
    VuePlayerVideo,
    DoReadingAnswer
  },
  name: 'HL-activity-main',
  props: ['period'],
  setup (props) {
    const store = useStore()
    // complete
    const state = reactive({
      steps: [
        { id: '第一步', name: '获取绘本资源', href: '#', status: 'complete' },
        {
          id: '第二步',
          name: 'Ready for Reading',
          href: '#',
          status: 'current'
        },
        {
          id: '第三步',
          name: 'Ready for Challenging',
          href: '#',
          status: 'upcoming'
        },
        { id: '第四步', name: '读后练习', href: '#', status: 'upcoming' }
      ],
      currentStepIndex: 0,
      period: props.period,
      readingVideoJSON: {}
    })

    onMounted(async () => {
      const interval = setInterval(async function () {
        if (store.state.studentJSON) {
          await onLoad()
          console.log(state)
          interval && clearInterval(interval)
        }
      }, 300)
    })
    const onLoad = async () => {
      let readingSchedule = await new AV.Query('GYJDReadingSchedule')
        .equalTo('student', store.state.student)
        .equalTo('period', state.period)
        .first()
      if (!readingSchedule) {
        readingSchedule = new AV.Object('GYJDReadingSchedule')
        readingSchedule.set('currentStep', 0)
        readingSchedule.set('period', state.period)

        readingSchedule.set('student', store.state.student)
        readingSchedule.set('user', AV.User.current())
        await readingSchedule.save()
      }
      state.readingSchedule = readingSchedule

      state.readingScheduleJSON = Util._jsonify(readingSchedule)

      state.steps.map((step, index) => {
        if (index > state.readingScheduleJSON.currentStepIndex) {
          step.status = 'upcoming'
        } else if (index === state.readingScheduleJSON.currentStepIndex) {
          step.status = 'current'
        } else {
          step.status = 'complete'
        }
      })

      const readingVideo = await new AV.Query('GYJDReadingVideo')
        .startsWith('level', store.state.studentJSON.group.gradeIndex + '')
        .equalTo('period', state.period)
        .first()

      state.readingVideo = readingVideo
      state.readingVideoJSON = Util._jsonify(readingVideo)
    }

    const toStep = async (step, index) => {
      state.currentStepIndex = index
      console.log(state.currentStepIndex)
    }
    return {
      onLoad,
      toStep,

      ...toRefs(state)
    }
  }
})
</script>
<style scoped>
/* a:hover {
  color: rgba(99, 102, 241, var(--tw-text-opacity));
} */
</style>
