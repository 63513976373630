import AV from 'leancloud-storage'
/**
 * 用户答题的数据
 */
class Answer extends AV.Object {
  get submitUnionCode () {
    return this.get('submitUnionCode')
  }

  set submitUnionCode (value) {
    this.set('submitUnionCode', value)
  }

  get channel () {
    return this.get('channel')
  }

  set channel (value) {
    this.set('channel', value)
  }

  get user () {
    return this.get('user')
  }

  set user (value) {
    this.set('user', value)
  }

  get student () {
    return this.get('student')
  }

  set student (value) {
    this.set('student', value)
  }

  /** 当前学生所在的群组 */
  get space () {
    return this.get('space')
  }

  /** 当前学生所在的群组 */
  set space (value) {
    this.set('space', value)
  }

  /** 这一道题属于哪一个等级，仅为题目的上一级 */
  get grade () {
    return this.get('grade')
  }

  /** 这一道题属于哪一个等级，仅为题目的上一级 */
  set grade (value) {
    this.set('grade', value)
  }

  /** 这道题的题目 */
  get question () {
    return this.get('question')
  }
  /** 这道题的题目 */

  set question (value) {
    this.set('question', value)
  }

  /** 这道题的题型 */
  get questionType () {
    return this.get('questionType')
  }
  /** 这道题的题型 */

  set questionType (value) {
    this.set('questionType', value)
  }

  /** remainTime */
  get remainTime () {
    return this.get('remainTime')
  }

  /** 试卷的剩余时间 */
  set remainTime (value) {
    this.set('remainTime', value)
  }

  /** 这一道题的用时时间 */
  get useSeconds () {
    return this.get('useSeconds')
  }

  /** 这一道题的用时时间 */
  set useSeconds (value) {
    this.set('useSeconds', value)
  }

  get totalUseSeconds () {
    return this.get('totalUseSeconds')
  }

  set totalUseSeconds (value) {
    this.set('totalUseSeconds', value)
  }

  /** 答题的日期（文本）,答案提交的那天。格式为YYYY-MM-DD */
  get todayString () {
    return this.get('todayString')
  }

  /** 答题的日期（文本）,答案提交的那天。格式为YYYY-MM-DD */
  set todayString (value) {
    this.set('todayString', value)
  }

  /** 冗余时间类型 */
  get today () {
    return this.get('today')
  }

  /** 冗余时间类型 */
  set today (value) {
    this.set('today', value)
  }

  /** 正确答题的那天。如果第一次就正确，和today属性内容一致。如果是错题，两者不一致，格式为YYYY-MM-DD */
  get rightDayString () {
    return this.get('rightDayString')
  }

  /** 正确答题的那天。如果第一次就正确，和today属性内容一致。如果是错题，两者不一致，格式为YYYY-MM-DD */
  set rightDayString (value) {
    this.set('rightDayString', value)
  }

  /** 冗余时间类型 */
  get rightDay () {
    return this.get('rightDay')
  }

  /** 冗余时间类型 */
  set rightDay (value) {
    this.set('rightDay', value)
  }

  /** 这道题的分数 */
  get score () {
    return this.get('score')
  }

  /** 这道题的分数 */
  set score (value) {
    this.set('score', value)
  }

  /** 这道题的分数 */
  get scoreInGrade () {
    return this.get('scoreInGrade')
  }

  /** 这道题的分数 */
  set scoreInGrade (value) {
    this.set('scoreInGrade', value)
  }

  /** 是否正确 */
  get correct () {
    return this.get('correct')
  }

  /** 是否正确 */
  set correct (value) {
    this.set('correct', value)
  }

  // 下面是选择题，用户选择的选项
  // 是个数组，适配多选的情况，按顺序，也可以用作拼接题
  get userChoice () {
    return this.get('userChoice')
  }

  set userChoice (value) {
    this.set('userChoice', value)
  }

  // 下面是口语数据部分，冗余，为了好查询
  // 如果有口语测评，则保存云知声原始的数据
  set answerDetail (value) {
    this.set('answerDetail', value)
  }

  get answerDetail () {
    return this.get('answerDetail')
  }

  set voiceUrl (value) {
    this.set('voiceUrl', value)
  }

  get voiceUrl () {
    return this.get('voiceUrl')
  }

  /** 流利度 */
  set fluency (value) {
    this.set('fluency', value)
  }

  /** 流利度 */
  get fluency () {
    return this.get('fluency')
  }

  /** 发音 */
  set pronunciation (value) {
    this.set('pronunciation', value)
  }
  /** 发音 */

  get pronunciation () {
    return this.get('pronunciation')
  }

  /** 口语录音时长 */
  set evaluationSeconds (value) {
    this.set('evaluationSeconds', value)
  }

  /** 口语录音时长 */
  get evaluationSeconds () {
    return this.get('evaluationSeconds')
  }

  /** 完整度 */
  set integrity (value) {
    this.set('integrity', value)
  }

  /** 完整度 */
  get integrity () {
    return this.get('integrity')
  }

  /** 输入的标准文本 */
  set sample (value) {
    this.set('sample', value)
  }

  /** 输入的标准文本 */
  get sample () {
    return this.get('sample')
  }

  /** 用户实际朗读的文本（语音识别结果） */
  set usertext (value) {
    this.set('usertext', value)
  }
  /** 用户实际朗读的文本（语音识别结果） */

  get usertext () {
    return this.get('usertext')
  }

  /** 口语测评的语速 */
  set voiceSpeed (value) {
    this.set('voiceSpeed', value)
  }

  /** 口语测评的语速 */
  get voiceSpeed () {
    return this.get('voiceSpeed')
  }

  /** 评测类型：general（朗读评测）、askandanswer（情景问答）、composition（作文） */
  set evalType (value) {
    this.set('evalType', value)
  }
  /** 评测类型：general（朗读评测）、askandanswer（情景问答）、composition（作文） */

  get evalType () {
    return this.get('evalType')
  }

  /** 处于当前等级中的哪一天 */
  get anyDay () {
    return this.get('anyDay')
  }
  /** 处于当前等级中的哪一天 */

  set anyDay (value) {
    this.set('anyDay', value)
  }

  /** 是否有效 */
  get validated () {
    return this.get('validated')
  }
  /** 是否有效 */

  set validated (value) {
    this.set('validated', value)
  }

  /** 是否进行作答 */
  get ifDoAnswer () {
    return this.get('ifDoAnswer')
  }

  /** 是否进行作答 */
  set ifDoAnswer (value) {
    this.set('ifDoAnswer', value)
  }

  /** 这个答案对应单元答题记录的统计 */
  get everySetAnswerStatistics () {
    return this.get('everySetAnswerStatistics')
  }

  /** 这个答案对应单元答题记录的统计 */
  set everySetAnswerStatistics (value) {
    this.set('everySetAnswerStatistics', value)
  }
}
AV.Object.register(Answer, 'GYJDAnswer')
export default Answer
