<template>
    <div style="font-size:14px; " class="flex flex-col items-center w-full">
  <!-- 倒计时区域-->
  <div style="height:40px;background: #FFE7C1;border-radius: 4px;margin-bottom:10px;" class="w-1/2 mt-4 flex flex-row  justify-center items-center">
   <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
    <div style="font-weight: 900;color: black;font-size: 14px;letter-spacing: 1px;margin-left:18px;">{{systemOperationTip}}
      <HLVoiceGroup v-if="systemOperationType==0" @voicePlayEnd="questionItemVoiceEnd" :showLottie="false" :autoPlay="false" :allUrls="allUrls" :times="2" :order="index">
      </HLVoiceGroup>
      <!-- 播放滴一声，进入下一题-->
      <HLVoiceGroup v-if="systemOperationType==3"  :times="1"  @voicePlayEnd="toNextQuestion" :showLottie="false" :autoPlay="false" :allUrls="['https://file.nj.hlingsoft.com/JMad9VoFnKXQiSloiTOAGXMGVy2538tk/beep.mp3']">
      </HLVoiceGroup>

    </div>
    <div v-if="systemOperationSeconds>0" style="font-weight: 900;font-size: 14px;letter-spacing: 1px;margin-left:18px;">
      {{systemOperationSeconds}}</div>
  </div>

  <div class="flex flex-col  justify-center  pb-40   w-3/4 pl-5" >
    <div class="flex flex-row mt-5 " style="font-size: 15px;color: #11152B;font-weight: 700;" >
      {{index + 1}}. {{question.questionData[0][0].intro}}

    </div>

    <div class="flex flex-row aminated fadeIn  " >
      <!-- 选项是图片-->
      <div v-if="question.questionData[1][0].image  && question.questionData[1][0].image.url && question.questionData[1][0].image.url.length >0 ">

        <div class="flex flex-row items-center justify-center mt-5" >
          <div @click="toChoiceWord(choice,index)"    v-for="(choice,index) in question.questionData[1]" :key="choice.sn" :class="[choiceIndex ==index?'que1':'que1w','flex flex-row cursor-pointer m-5 w-1/3']" >
            <div>{{index==0?'A':index==1?'B':index==2?'C':'D'}}</div>
            <div style="flex-grow: 1;">
              <img style="width:120px;height:120px" mode="widthFix" :src="choice.image.url" />
            </div>
          </div>
        </div>
      </div>

      <!-- 选项是文字的 -->
      <div v-else>

        <div class="flex  flex-col mt-5">
          <div @click="toChoiceWord(choice,index)" v-for="(choice,index) in question.questionData[1]"  :key="choice.sn"  :class="[choiceIndex ==index?'xuanzhon2 text-white':'text-black','flex flex-row cursor-pointer mt-5 w-full p-3 pr-10 ']"   >
          	<div :class="[choiceIndex==index?'xuanzhon':'weixuanzhon']"
				style="width:20px;height:20px;border-radius: 50%;"></div>
            <div style="margin-left:20px;font-weight:400;width:30px;">{{index==0?'A':index==1?'B':index==2?'C':'D'}}</div>

            <div class="flex" style=" justify-content:start;margin-left:30px;text-align:left;font-weight:400;letter-spacing: 0px;">
            <div>{{choice.intro}}</div>

            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</div>
</template>
<script  >
import { reactive, toRefs, defineComponent, onBeforeUnmount } from 'vue'
import HLVoiceGroup from '@/views/components/common/voiceGroup.vue'

export default defineComponent({
  components: {
    HLVoiceGroup

  },
  name: 'HL-tingduihua',
  props: ['question'],
  emits: ['setAnswerData', 'toNextQuestion'],
  setup (props, { emit }) {
    const allUrls = []
    props.question.questionData[0].map(obj => {
      if (obj.voice && obj.voice.url && obj.voice.url.length > 0) {
        allUrls.push(obj.voice.url)
      }
    })

    const state = reactive({
      index: props.question.indexInOneDay - 1,
      allUrls,
      question: props.question,
      seconds: 1,
      correct: false,
      ifDoAnswer: false, // 是否进行了作答
      currentQuestionIndex: 0,
      systemOperationSeconds: 5,
      systemOperationTimer: setInterval(function () {
        state.systemOperationSeconds--

        if (state.systemOperationSeconds === 0) {
          state.systemOperationTimer && clearInterval(state.systemOperationTimer)
          state.systemOperationType = 0
          state.systemOperationTip = '正在播放题目音频'
        }
      }, 1000),
      systemOperationTip: '阅读题目倒计时',
      systemOperationType: 1, // 0代表播放音频、1代表阅读题目、2代表回答倒计时、3代表播放滴，需要进入下一题
      requesting: false,
      choiceIndex: -1,
      timerInterval: setInterval(function () {
        state.seconds += 1
      }, 1000)

    })

    const questionItemVoiceEnd = async () => {
      state.systemOperationTimer && clearInterval(state.systemOperationTimer)
      state.systemOperationType = 2
      state.systemOperationSeconds = 5
      state.systemOperationTip = '答题倒计时'

      state.systemOperationTimer = setInterval(function () {
        state.systemOperationSeconds--
        // console.log(state.systemOperationSeconds)
        if (state.systemOperationSeconds === 0) {
          // 答题倒计时结束了
          state.systemOperationTimer && clearInterval(state.systemOperationTimer)
          // 跳转到下一题

          state.systemOperationType = 3
          state.systemOperationTip = '准备进入下一题'
        }
      }, 1000)
    }

    const toChoiceWord = async (choice, index) => {
    //   if (!state.requesting) {
      const resultData = {}
      state.choiceIndex = index
      resultData.correct = choice.sn === state.question.answerSelection[0].sn
      resultData.userChoice = choice
      resultData.score = resultData.correct ? 100 : 0
      resultData.useSeconds = state.seconds
      resultData.ifDoAnswer = true
      state.timerInterval && clearInterval(state.timerInterval)

      state.requesting = true
      state.correct = resultData.correct
      emit('setAnswerData', resultData)
    //   }
    }
    const toNextQuestion = () => {
      state.timerInterval && clearInterval(state.timerInterval)
      state.systemOperationTimer && clearInterval(state.systemOperationTimer)

      setTimeout(function () {
        emit('toNextQuestion', {})
      }, 500)
    }
    onBeforeUnmount(async () => {
      // 销毁定时器
      state.timerInterval && clearInterval(state.timerInterval)
      state.systemOperationTimer && clearInterval(state.systemOperationTimer)
      // 销毁动画
      state.bgAnimation && state.bgAnimation.destroy()
    })
    return {
      toChoiceWord,
      questionItemVoiceEnd,
      toNextQuestion,
      ...toRefs(state)
    }
  }
})

</script>
