<template>

            <HLLearningData v-if="student" :student="student" :other="true"/>

</template>

<script>
import { reactive, toRefs, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import AV, { Query } from 'leancloud-storage'
import DayJS from 'dayjs'
import Util from '@/utils/util.js'
import HLLearningData from '@/views/learningData/components/index.vue'

export default {
  components: { HLLearningData },

  setup (props, { emit }) {
    const router = useRouter()
    const route = useRoute()

    const state = reactive({
      allTestPaper: [],
      allGradeDoneCount: [],
      student: ref(null)
    })
    console.log(route, route.query.studentId)
    if (!route.query.studentId) {
      router.push({ name: 'Student' })
    }
    onMounted(async () => {
      if (route.query.studentId) {
        const student = await new AV.Query('GYJDStudent').include('group')
          .include('school')
          .include('teacher')
          .include('user').get(route.query.studentId)
        state.student = student
        state.studentJSON = Util._jsonify(student)
      }
    })

    return {

      ...toRefs(state)
    }
  }
}
</script>
