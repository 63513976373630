<template>
  <!-- 题干-->
  <div class="w-full max-h-full mt-4 flex flex-col justify-center items-center">
    <article
      v-for="(questionItem,index)  in question.questionData[0]"
      :key="index"
      class="prose prose-lg"
    >
      {{ questionItem.intro }}
    </article>
  </div>

  <div class="flex flex-col justify-center items-center">

    <div
      v-for="(choice,index) in ['true','false']"
      :key="choice"
    >
      <button
        class="btn btn-outline w-60 mt-10 btn-primary "
        @click="toChoiceWord(choice,index)"
        :disabled="requesting && choiceIndex!=index"
      >{{index==0?'TRUE':'FALSE'}}</button>

    </div>
      <div  class="mt-5">
        <EmojiHappyIcon   v-if="requesting && correct"  class=" animate-bounce  w-10 h-10 text-green-600"/>
        <EmojiSadIcon   v-if="requesting && !correct"  class="animate-bounce  w-10 h-10 text-red-600"/>
      </div>
  </div>

</template>
<script>
import { reactive, toRefs, defineComponent } from 'vue'

import { EmojiHappyIcon, EmojiSadIcon } from '@heroicons/vue/outline'
export default defineComponent({
  components: {
    EmojiHappyIcon,
    EmojiSadIcon
  },
  name: 'HL-panduanti',
  props: ['question'],
  emits: ['setAnswerData'],
  setup (props, { emit }) {
    const state = reactive({
      question: props.question,
      seconds: 1,
      correct: false,
      choiceIndex: -1,
      requesting: false,
      timerInterval: setInterval(function () {
        state.seconds += 1
      }, 1000)

    })
    const toChoiceWord = async (choice, index) => {
      if (!state.requesting) {
        state.requesting = true
        state.choiceIndex = index
        const resultData = {}
        resultData.correct = String(state.question.isCorrect).toUpperCase() === String(choice).toUpperCase()
        resultData.userChoice = choice
        resultData.score = resultData.correct ? 100 : 0
        resultData.useSeconds = state.seconds
        state.timerInterval && clearInterval(state.timerInterval)
        state.correct = resultData.correct
        emit('setAnswerData', resultData)
      }
    }
    return {
      toChoiceWord,
      ...toRefs(state)
    }
  }
})

</script>
