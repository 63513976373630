import DayJS from 'dayjs'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const _jsonify = (target) => {
  if (target && typeof target.toFullJSON === 'function') {
    const fullJson = target.toFullJSON()
    fullJson.createdAt = DayJS(fullJson.createdAt).format('YYYY-MM-DD HH:mm:ss')
    fullJson.updatedAt = DayJS(fullJson.updatedAt).format('YYYY-MM-DD HH:mm:ss')
    return fullJson
  }
  if (Array.isArray(target)) return target.map(_jsonify)
  return target
}
const formatCorrect = (correct, total) => {
  if (total === 0) return 0
  return Number((correct * 100 / total).toFixed())
}
export default {
  _jsonify,
  formatCorrect

}

// declare module 'Util'

// declare module 'Util' {
//   export function getRandomNumber(): number
// }
