
import { createApp } from 'vue'
import AnswerTipsVue from './answerTips.vue'
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function AnswerTipsComponent ({ question, allHasGroupQuestionsJSON, allQuestion }) {
  return new Promise((resolve) => {
    const confirmInstance = createApp(AnswerTipsVue, {
      question: question,
      allHasGroupQuestionsJSON: allHasGroupQuestionsJSON,
      allQuestion: allQuestion,
      close: function () {
        unmount()

        resolve('done')
      },
      doneAndNext: async () => {
        unmount()
        resolve('done')
      }
    })

    const unmount = () => {
      confirmInstance.unmount()
      document.body.removeChild(parentNode)
    }

    const parentNode = document.createElement('div')
    document.body.appendChild(parentNode)

    confirmInstance.mount(parentNode)
  })
}
export default AnswerTipsComponent
