<template>
  <div
  style="background:#F4F6F1"
    class="w-full h-full  py-4  flex flex-row justify-center items-center "
  >
   <div

      class="w-11/12 h-full  flex flex-col bg-white   rounded-2xl"
    >
     <div class="flex flex-row justify-center h-3/4 items-center">
        <div @click="startTest(2)"
          class="w-1/4 h-60 flex flex-col justify-center  items-center shadow-xl rounded-xl  cursor-pointer"
        >
          <u class="text-gyjdGreen-500 text-xl">考试模式</u>
          <p class=" text-xs mt-10 w-2/3"  style="text-indent:1.5rem">
            点击进入模拟考试，模拟考试模式考生严格按照考试流程答题，提前体验考场氛围。
          </p>
        </div>
        <div
        @click="startTest(3)"
          class="w-1/4 h-60 flex flex-col justify-center items-center shadow-xl rounded-xl  cursor-pointer ml-24"
        >
          <u class="text-gyjdGreen-500 text-xl">练习模式</u>
          <p class=" mt-10 text-xs w-2/3 " style="text-indent:1.5rem">
            点击进入练习模式，练习模式下考生可以进行答题跳转，自主控制练习进度，提高练习效率。
          </p>
        </div>

      </div>
      <div class="text-center text-red-400">
        注：随机组卷的试卷不会被保存，每次生成的试卷内容不同，可使用考试练习模式进行练习。
      </div>

    </div>

  </div>

</template>
<script >
import { reactive, toRefs, ref, onMounted } from 'vue'
import AV from 'leancloud-storage'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Util from '@/utils/util'
export default {
  name: 'MockExamination',
  props: {},
  components: {},
  setup () {
    const store = useStore()
    const router = useRouter()
    const state = reactive({
      randomGrade: ref(null)
    })
    const startTest = (operationType) => {
      console.log('进入模拟考试')
      // router.push({ name: "Home" });
      router.push({ name: 'Answer', params: { gradeId: state.randomGrade.id, operationType: operationType } })
    }
    onMounted(async () => {
      if (!store.state.student) {
        store.state.student = await new AV.Query('GYJDStudent')
          .equalTo('user', AV.User.current())
          .include('group')
          .include('school')
          .include('teacher')
          .include('user')
          .equalTo('default', true)
          .first()
        store.state.studentJSON = Util._jsonify(store.state.student)
      }

      const studySchedule = await new AV.Query('GYJDStudySchedule').include('grade').equalTo('student', store.state.student).equalTo('validated', true).first()
      // console.log(studySchedule)
      store.state.studySchedule = studySchedule
      store.state.studyScheduleJSON = Util._jsonify(studySchedule)

      // 非常重要
      const gradesInCurrentLevel = await new AV.Query('GYJDGrade').ascending('indexInt').equalTo('level', store.state.studyScheduleJSON.grade.level).find()
      store.state.gradesInCurrentLevel = gradesInCurrentLevel
      state.randomGrade = gradesInCurrentLevel.filter(grade => {
        return grade.series === 0
      })[0]
    })

    return {
      startTest,

      ...toRefs(state)
    }
  }
}
</script>
