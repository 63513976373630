<template>
  <div v-if="allQuestionLength > 0" class="flex   flex-col w-full h-full">
    <div
      class="flex flex-row pl-10 pr-10  pb-5 text-lg text-indigo-500 justify-center   border-b-2 align-baseline "
    >
      <!-- 题型名字-->
      <div
        class=" flex flex-row text-lg text-indigo-500 border-b-2  align-baseline cursor-pointer"
        @click="toBack"
      >
        <ArrowCircleLeftIcon class="h-8 w-8 " />

        <div class=" italic ml-4">{{ questionTypeJSON.name }}</div>
      </div>

      <div class="flex-1 text-indigo-500 italic ml-20 ">
        <vue-countdown
          :class="[showResult ? 'hidden' : '']"
          @progress="onCountDownProgress"
          v-slot="{ minutes, seconds }"
          :time="countDonwDefaultSeconds * 1000"
        >
          {{ minutes }}分{{ seconds }}秒
        </vue-countdown>
      </div>
      <div class="flex-1 text-right">
        <span class="text-2xl">{{ currentQuestionIndex + 1 }}</span
        >/<span class="text-gray-400">{{ allQuestionLength }}</span>
      </div>
    </div>

    <div v-if="countDownProgressData">
      <div class="" v-if="showResult === false">
        <!-- <transition enter-active-class="transition ease-out duration-200" enter-from-class="transform opacity-0 " enter-to-class="transform opacity-100 " leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95"> -->
        <component
          :is="questionTypeComponent"
          :key="questionJSON.objectId"
          @setAnswerData="setAnswerData"
          :question="questionJSON"
        />
        <!-- </transition> -->
      </div>
      <div v-else class="flex flex-row justify-center items-center p-20">
        <div class="flex-1 flex flex-col justify-center h-full ">
          <img
            class="w-12  "
            src="https://6e62-nbec-4gtpmm6p00694b42-1304760731.tcb.qcloud.la/images/answer/left_quotation_marks.png"
          />
          <div class="mt-10">
            本轮正确率<span class="ml-3 font-extrabold text-lg text-indigo-600"
              >{{ correctRate }}%</span
            >
          </div>
          <div class="mt-4">
            本轮总用时<span class="ml-3 font-extrabold text-lg text-indigo-600"
              >{{ totalUseSeconds }}秒</span
            >
          </div>
          <img
            class="w-12 mt-4 self-end"
            src="https://6e62-nbec-4gtpmm6p00694b42-1304760731.tcb.qcloud.la/images/answer/right_quotation_marks.png"
          />
        </div>
        <HLLottiePlayer
          class="flex-1"
          name="resultAnimation"
          loop
          :animationData="require('@/assets/lotties/gold.json')"
        />
      </div>
    </div>
  </div>
  <div
    v-if="allQuestionLength == 0"
    class="p-60 w-full h-full flex flex-col justify-center items-center"
  >
    <button class="btn btn-sm btn-outline btn-primary loading">loading</button>
  </div>
</template>
<script>
import {
  reactive,
  toRefs,
  onMounted,
  defineComponent,
  ref,
  markRaw,
  watch
} from 'vue'
import { useStore } from 'vuex'
import _ from 'lodash'
import { ArrowCircleLeftIcon } from '@heroicons/vue/outline'
import AV from 'leancloud-storage'
import DayJS from 'dayjs'
import Util from '@/utils/util.js'
import HLTingLiXuanWenBen from '@/views/components/questionType/tinglixuanwenben.vue'
import HLTingLiXuanTingLi from '@/views/components/questionType/tinglixuantingli.vue'
import HLKouYuGenDu from '@/views/components/questionType/kouyugendu.vue'
import HLKouYuFuShu from '@/views/components/questionType/kouyufushu.vue'
import HLQingJIngDuiHua from '@/views/components/questionType/qingjingwenda.vue'
import VueLottiePlayer from 'vue-lottie-player'
import Training from './training.vue'
import Dialog from '@/components/dialog.js'
export default defineComponent({
  components: {
    ArrowCircleLeftIcon,
    HLTingLiXuanWenBen,
    HLTingLiXuanTingLi,
    HLLottiePlayer: VueLottiePlayer,
    Training,
    HLKouYuGenDu,
    HLKouYuFuShu,
    HLQingJIngDuiHua
  },
  name: 'HL-doAnswer',
  props: ['questionTypeId', 'gradeId'],
  emits: ['toBack'],
  setup (props, { emit }) {
    const store = useStore()

    const state = reactive({
      period: props.period,
      needSaveAnswer: false,
      currentQuestionIndex: 0,
      allQuestionLength: 0,
      questionJSON: {},
      questionTypeJSON: {},
      countDonwDefaultSeconds: 60 * 60,
      allAnswer: [],
      questionTypeComponent: ref(null),
      showResult: false,
      correctRate: 0,
      totalUseSeconds: 0,
      countDownProgressData: ref(null)

      // countDownRemainData: {}
    })

    onMounted(async () => {
      state.allAnswer = []
      await getQuestionsByQuestionTypeAndGradeAndIndexInOneDay()
    })

    // 找到所有的题目
    const getQuestionsByQuestionTypeAndGradeAndIndexInOneDay = async () => {
      const questionType = await new AV.Query('GYJDQuestionType').get(
        props.questionTypeId
      )
      const grade = await new AV.Query('GYJDGrade').get(props.gradeId)
      let allQuestions = []
      if (questionType.get('series') === 5) {
        allQuestions = _.sampleSize(
          await new AV.Query('GYJDQuestion')
            .include('questionType')
            .equalTo('questionType', questionType)
            .equalTo('grade', grade)
            .equalTo('validated', true)
            .find(),
          1
        )
      } else {
        allQuestions = _.sampleSize(
          await new AV.Query('GYJDQuestion')
            .include('questionType')
            .equalTo('questionType', questionType)
            .equalTo('grade', grade)
            .equalTo('validated', true)
            .find(),
          4
        )
      }
      if (allQuestions.length > 0) {
        state.allQuestions = allQuestions
        state.allQuestionsJSON = Util._jsonify(allQuestions)
        state.allQuestionLength = allQuestions.length
        state.questionJSON = state.allQuestionsJSON[state.currentQuestionIndex]
        state.questionType = questionType
        state.questionTypeJSON = Util._jsonify(questionType)
      } else {
        Dialog({
          content: '题目维护中...',

          doneAndNext: () => {
            toBack()
          }
        })
      }
    }
    watch(
      () => state.questionJSON,
      () => {
        // console.log(state.questionJSON.questionType)
        if (state.questionJSON.questionType.series === 1) {
          state.questionTypeComponent = markRaw(HLTingLiXuanWenBen)
        } else if (state.questionJSON.questionType.series === 2) {
          state.questionTypeComponent = markRaw(HLTingLiXuanTingLi)
        } else if (state.questionJSON.questionType.series === 3) {
          state.questionTypeComponent = markRaw(HLKouYuGenDu)
        } else if (state.questionJSON.questionType.series === 4) {
          state.questionTypeComponent = markRaw(HLKouYuFuShu)
        } else if (state.questionJSON.questionType.series === 5) {
          state.questionTypeComponent = markRaw(HLQingJIngDuiHua)
        }
      },
      {
        deep: true // 深度监听的参数
      }
    )

    const setAnswerData = async (data) => {
      console.log(data)
      const preAnswerRemainSeconds =
        state.allAnswer.length > 0
          ? state.allAnswer[state.allAnswer.length - 1].remainTime
          : state.countDonwDefaultSeconds
      state.allAnswer.push(data)

      data.remainTime = state.countDownProgressData.totalSeconds
      data.totalUseSeconds = preAnswerRemainSeconds - data.remainTime

      await saveAnswer(data)
      const timerId = _.delay(
        async function (data) {
          timerId && clearTimeout(timerId)

          toNextQuestion()
        },
        1500,
        data
      )
    }
    const toNextQuestion = async () => {
      // await delay()
      if (state.currentQuestionIndex === state.allQuestionLength - 1) {
        // 跳转到结果界面
        let useSeconds = 0
        let correctCount = 0

        state.allAnswer.forEach((answer) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          useSeconds += answer.useSeconds
          if (answer.correct) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            correctCount++
          }
        })
        state.useSeconds = useSeconds
        state.totalUseSeconds =
          state.countDonwDefaultSeconds -
          state.countDownProgressData.totalSeconds
        state.wrongCount = state.allAnswer.length - correctCount
        state.correctCount = correctCount
        state.correctRate =
          _.floor(_.divide(correctCount, state.allAnswer.length), 2) * 100
        await hasEverySetAnswerStatistics()
        state.showResult = true
      } else {
        // 下一题
        state.showResult = false
        state.currentQuestionIndex += 1
        state.questionJSON = state.allQuestionsJSON[state.currentQuestionIndex]
      }
    }
    const saveAnswer = async (currentAnswerData) => {
      console.log(currentAnswerData)
      const answer = new AV.Object('GYJDAnswer')

      answer.set('channel', 'web')
      // 处理web端计时器bug
      answer.set(
        'totalUseSeconds',
        currentAnswerData.totalUseSeconds >= currentAnswerData.useSeconds
          ? currentAnswerData.totalUseSeconds
          : currentAnswerData.useSeconds
      )
      answer.set('remainTime', currentAnswerData.remainTime)

      answer.set('user', AV.User.current())
      answer.set('student', store.state.student)
      answer.set('question', state.allQuestions[state.currentQuestionIndex])
      answer.set('questionType', state.questionType)
      answer.set('grade', store.state.studySchedule.get('grade'))

      answer.set('correct', currentAnswerData.correct)
      answer.set('useSeconds', currentAnswerData.useSeconds)
      answer.set('remainTime', state.countDownProgressData.totalSeconds)
      answer.set('anyDay', store.state.studyScheduleJSON.currentDay)
      answer.set('todayString', DayJS().format('YYYY-MM-DD'))
      answer.set('today', new Date())

      if (currentAnswerData.correct) {
        answer.set('rightDayString', answer.get('todayString'))
        answer.set('rightDay', answer.get('today'))
      }
      answer.set('score', currentAnswerData.score)
      answer.set('userChoice', currentAnswerData.userChoice)
      answer.set('answerDetail', currentAnswerData.answerDetail)
      answer.set('voiceUrl', currentAnswerData.voiceUrl)
      answer.set('fluency', currentAnswerData.fluency)
      answer.set('pronunciation', currentAnswerData.pronunciation)
      answer.set('integrity', currentAnswerData.integrity)
      answer.set('voiceSpeed', currentAnswerData.voiceSpeed)
      answer.set('evalType', currentAnswerData.evalType)
      answer.set('usertext', currentAnswerData.usertext)
      answer.set('sample', currentAnswerData.sample)
      answer.set('evaluationSeconds', currentAnswerData.evaluationSeconds)
      console.log(answer)
      await answer.save()
    }

    const hasEverySetAnswerStatistics = async () => {
      const hasEverySetAnswerStatistics =
        (await new AV.Query('GYJDEverySetAnswerStatistics')
          .equalTo('student', store.state.student)
          .equalTo('questionType', state.questionType)
          .equalTo('grade', store.state.studySchedule.get('grade'))
          .equalTo('anyDay', store.state.studyScheduleJSON.currentDay)
          .count()) > 0
      if (!hasEverySetAnswerStatistics) {
        state.everySetAnswerStatistics = await saveEverySetAnswerStatistics()
      }
    }

    const saveEverySetAnswerStatistics = async () => {
      const everySetAnswerStatistics = new AV.Object(
        'GYJDEverySetAnswerStatistics'
      )
      everySetAnswerStatistics.set('channel', 'web')
      everySetAnswerStatistics.set('user', AV.User.current())
      everySetAnswerStatistics.set('student', store.state.student)
      everySetAnswerStatistics.set(
        'grade',
        store.state.studySchedule.get('grade')
      )
      everySetAnswerStatistics.set('questionType', state.questionType)
      everySetAnswerStatistics.set('useSeconds', state.useSeconds)
      everySetAnswerStatistics.set('totalUseSeconds', state.totalUseSeconds)
      everySetAnswerStatistics.set('correctRate', state.correctRate)
      everySetAnswerStatistics.set(
        'isAllCorrect',
        state.correctCount === state.allAnswer.length
      )
      everySetAnswerStatistics.set('validated', true)
      everySetAnswerStatistics.set(
        'anyDay',
        store.state.studyScheduleJSON.currentDay
      )
      everySetAnswerStatistics.set('wrongCount', state.wrongCount)
      everySetAnswerStatistics.set('correctCount', state.correctCount)
      return await everySetAnswerStatistics.save()
    }
    const onCountDownProgress = async (data) => {
      state.countDownProgressData = data
      if (data.totalSeconds <= 1) {
        toBack()
      }
    }
    const toBack = () => {
      console.log('toBack')
      emit('toBack', '')
    }
    return {
      toBack,
      getQuestionsByQuestionTypeAndGradeAndIndexInOneDay,
      toNextQuestion,
      setAnswerData,
      onCountDownProgress,
      ...toRefs(state)
    }
  }
})
</script>
