<template>
    <div @click="toReplay" class=" cursor-pointer">
        <VueLottiePlayer v-if="showLottie"  name="voiceAnim"  loop  width="80px" height="80px" :animationData="require('@/assets/lotties/voice.json')" renderer="canvas" @animControl="anim => (voiceAnim = anim)"/>
    </div>
</template>
<script>
import { Howl, Howler } from 'howler'
import { reactive, toRefs, defineComponent, onMounted, ref, watch, onBeforeUnmount } from 'vue'
import VueLottiePlayer from 'vue-lottie-player'
import { useStore } from 'vuex'
export default defineComponent({
  components: {
    VueLottiePlayer
  },
  name: 'HL-voice',
  props: {
    allUrls: Array,
    stop: Boolean,
    order: {
      type: Number,
      default: -1
    },
    times: {
      type: Number,
      default: 1
    },
    autoPlay: {
      type: Boolean,
      default: true
    },
    showLottie: {
      type: Boolean,
      default: false

    }
  },
  emits: ['voicePlayEnd', 'onPlay'],
  setup (props, { emit }) {
    const store = useStore()
    const state = reactive({
      voiceAnim: ref(null),
      isPlaying: false,
      currentVoiceIndex: 0,
      reallyAllUrls: [],
      emptyMp3Url: 'https://file.nj.hlingsoft.com/foSBM8GeNDzfC36DwdHVHxywXMsdXAKX/%E6%96%B0%E5%BD%95%E9%9F%B3%205.mp3',
      // qingjingwendaOrderMp3Urls: ['https://file.nj.hlingsoft.com/VEtatjlezdvg3i5piFcfQbejcYLgSJtM/one.mp3',
      //   'https://file.nj.hlingsoft.com/SmLOJHX1N4AWEhOG2QPwFndXhD6MLTpF/two.mp3'],
      qingjingwendaOrderMp3Urls: ['https://file.nj.hlingsoft.com/o33jG2Uj0rg1N6jzOsifY1Ms0Lvkf0nd/Q1.mp3',
        'https://file.nj.hlingsoft.com/dUEIAnJjQUckswz35c2IEBKbb0Kj629k/Q2.mp3'],
      // orderMp3Urls: ['https://file.nj.hlingsoft.com/gmWx5u8TAiBPMPt2TObDjAbPY7jU9xzg/01.mp3',
      //   'https://file.nj.hlingsoft.com/EzxP2N6yWFgFGX0T4iKYHdEefQuqvldn/02.mp3',
      //   'https://file.nj.hlingsoft.com/DYhOSg60f791JLh8Dy1X2NGTqoJauyfT/03.mp3',
      //   'https://file.nj.hlingsoft.com/8au1EFTVR16POQW7l1UStQLg0KUUI9Iw/04.mp3',
      //   'https://file.nj.hlingsoft.com/gPNHNAAXQOVQ8Bg2sl05YOVpeSnDH3i3/05.mp3',
      //   'https://file.nj.hlingsoft.com/2bmEvBb15YgGyXbTL3IKUEycrJycdL2l/06.mp3',
      //   'https://file.nj.hlingsoft.com/BHrV7W2mKVuo7XBtjFVenfGENzvzgc59/07.mp3',
      //   'https://file.nj.hlingsoft.com/14rdcCfaafJElaaWUQX9Ut98UGpnajwO/08.mp3',
      //   'https://file.nj.hlingsoft.com/REM4YWFa7So9ULfBjdd6WEUm4mE4Ue5w/09.mp3',
      //   'https://file.nj.hlingsoft.com/ewFQYsTKjNivyVj1quX6FugN9eIcdMWM/10.mp3']
      orderMp3Urls: ['https://file.nj.hlingsoft.com/Sye2kjYeWx68DvKGsCH3e2HuzlKnLgGO/01.mp3',
        'https://file.nj.hlingsoft.com/mnIJ7xK4a94z74Duzj73dLOtBvv9kE3m/02.mp3',
        'https://file.nj.hlingsoft.com/DHlQ61Jfld9RK8tGFODkcdDpzY64iqeq/03.mp3',
        'https://file.nj.hlingsoft.com/FueK7HiLCcNHT8PK9CU6vbI9R1y8ppyH/04.mp3',
        'https://file.nj.hlingsoft.com/oLRI4wLBLssfGb0Och39YS11Kyryffmr/05.mp3',
        'https://file.nj.hlingsoft.com/OjqiDetOWuayS7j5zpx7eGWBbDTLbwpE/06.mp3',
        'https://file.nj.hlingsoft.com/aBF4PyeOS78vTK8zck85DTYrIRPNPh6a/07.mp3',
        'https://file.nj.hlingsoft.com/WFx5qPdpdynmfl0iQrh6kBVEkCWj9aKc/08.mp3',
        'https://file.nj.hlingsoft.com/BwTx4cOaJTe0p7fSWSm1A8RPzUFGghJ3/09.mp3',
        'https://file.nj.hlingsoft.com/mjv1gtgPiTrVllxDLQYvlrkBxtEz2wqu/10.mp3']

    })
    watch(() => props.stop, () => {
      if (props.stop) {
        if (store.state.voiceAnim) {
          store.state.voiceAnim.stop()
          store.state.voiceAnim = null
        }
        state.sounds.map(sound => {
          sound.stop()
        })
      }
    },
    {
      deep: true // 深度监听的参数
    })

    state.reallyAllUrls = []
    // console.log(' props.allUrl/s', props.allUrls)
    for (let i = 0; i < props.times; i++) {
      props.allUrls.map(url => {
        state.reallyAllUrls.push(url)
      })

      if (i < props.times - 1) {
        if (props.order !== -1) {
          state.reallyAllUrls.push(state.emptyMp3Url)
        } else {
          for (let j = 0; j < 3; j++) {
            state.reallyAllUrls.push(state.emptyMp3Url)
          }
        }
      }
    }

    if (props.order > -1 && props.order <= state.orderMp3Urls.length - 1) {
      state.reallyAllUrls.unshift(state.orderMp3Urls[props.order])
    }
    console.log('props.order', props.order)
    if (props.order === 22) {
      state.reallyAllUrls.unshift(state.qingjingwendaOrderMp3Urls[0])
    }
    if (props.order === 23) {
      state.reallyAllUrls.unshift(state.qingjingwendaOrderMp3Urls[1])
    }
    console.log(state.reallyAllUrls)
    //  volume: localStorage.getItem('volume') ? Number(localStorage.getItem('volume')) : 1,
    onMounted(async () => {
      let playVoiceIndex = 0
      const sounds = []
      for (let i = 0; i < state.reallyAllUrls.length; i++) {
        sounds.push(new Howl({
          src: state.reallyAllUrls[i],
          autoplay: false,
          loop: false,
          onend: function () {
            playVoiceIndex += 1
            if (playVoiceIndex >= state.reallyAllUrls.length) {
              state.isPlaying = false
              if (store.state.voiceAnim) {
                store.state.voiceAnim.stop()
                store.state.voiceAnim = null
              }
              emit('voicePlayEnd')
            } else {
              sounds[playVoiceIndex].play()
            }
          }

        }))
      }

      if (store.state.sound) {
        store.state.sound.stop()
        store.state.sound = null
      }
      if (store.state.voiceAnim) {
        store.state.voiceAnim.stop()
        store.state.voiceAnim = null
      }
      state.sounds = sounds
      state.sound = sounds[playVoiceIndex]
      store.state.sound = playVoiceIndex
      store.state.voiceAnim = state.voiceAnim
      state.sound.play()
    })
    const toReplay = async () => {
      if (store.state.voiceAnim) {
        store.state.voiceAnim.stop()
        store.state.voiceAnim = null
      }
      state.sound.stop()

      state.voiceAnim.play()
      state.sound.play()
    }
    onBeforeUnmount(async () => {
      console.log('onBeforeUnmount')

      if (store.state.voiceAnim) {
        store.state.voiceAnim.stop()
        store.state.voiceAnim = ref(null)
      }
      store.state.sound && store.state.sound.stop()
      store.state.sound = ref(null)
      state.sounds.map(sound => {
        sound.stop()
        sound = ref(null)
      })
    })
    return {
      toReplay,
      ...toRefs(state)
    }
  }
})
</script>
