<template>

  <div class="  bg-white h-full flex flex-row w-full">

    <div
      :class="[allGroupJSON && allGroupJSON.length > 0 ? ' bg-white' : ' bg-gray-50  items-center justify-center  p-10', 'flex flex-row  w-full  h-full max-h-full']">
      <!--侧边栏 -->
      <nav v-if="allGroupJSON && allGroupJSON.length > 0"
        class="px-5 space-y-7 h-full  w-1/6 pt-10 flex flex-col  items-center overflow-y-auto pb-20 mb-20   scrollbar scrollbar-thin scrollbar-thumb-gyjdGreen-500 "
        aria-label="Sidebar">
        <button @click="toShowCreateGroup"
          class="w-40  btn bg-gyjdGreen-500 border-gyjdGreen-500 hover:bg-gyjdGreen-600 hover:border-gyjdGreen-600">创建新班级</button>
        <div class="bg-gray-50 h-1 w-full"></div>
        <template v-for="(item, index) in allGroupJSON" :key="item.objectId">

          <div @click="changeGroup(index)" href="#"
            :class="[index == groupIndex ? 'bg-gray-100 text-gyjdGreen-500' : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group w-full flex flex-col  py-4   text-sm font-medium rounded-xl cursor-pointer ']">
            <div class="flex  items-baseline flex-row justify-center"> <span class="text-xl">{{ item.gradeIndex
                }}</span>年级/<span class="text-xl">{{ item.classIndex }}</span>班</div>

            <div class="flex  items-baseline flex-row justify-center mt-4">{{ item.numberOfMembers }}人</div>
          </div>

        </template>
      </nav>
      <div v-if="!allGroupJSON || allGroupJSON.length == 0"
        :class="[allGroupJSON && allGroupJSON.length > 0 ? 'w-3/4' : 'w-3/4 bg-white  rounded-3xl p-10 flex flex-col items-center  justify-center', ' h-full']">
        <div>你还没有班级</div>
        <button @click="toShowCreateGroup"
          class="w-40 my-10 btn bg-gyjdGreen-500 border-gyjdGreen-500 hover:bg-gyjdGreen-600 hover:border-gyjdGreen-600">创建班级</button>
      </div>
      <!-- 学生区域-->
      <div class="w-5/6 p-10">
        <div class=" flex flex-row items-center ">

          <button @click="uploadStudentExcel" :disabled="requesting"
            class="btn btn-outline btn-warning cursor-pointer ">批量添加学生</button>
          <div class="ml-10 text-gray-500 text-xs">(TIPS:1、学生账号为手机号码，初始密码为123456。 2、导入的学生须在小程序端登录且绑定手机号码)</div>
          <a href="https://file.nj.hlingsoft.com/r5CAAi9nPHX8kwxHeeY2hbFdhqtGzAoz/%E7%BA%B2%E8%A6%81%E8%A7%A3%E8%AF%BB%E5%AD%A6%E7%94%9F%E6%95%B0%E6%8D%AE%E5%AF%BC%E5%85%A5%E6%A0%87%E5%87%86%E6%A8%A1%E6%9D%BF.xls
" class="ml-20"> <button class="btn link btn-link">下载标准Excel</button></a>
          <button @click="downloadAllStudent" v-if="allStudentJSON && allStudentJSON.length > 0"
            class="ml-10 btn btn-outline btn-primary cursor-pointer ">下载当前表格数据</button>
        </div>
        <div class="bg-gray-50 h-1 w-full mt-6"></div>
        <a-table v-if="allStudentJSON"
          class="mt-10 ant-table-striped  scrollbar scrollbar-thumb-gray-900 scrollbar-track-gray-100" bordered
          :data-source="allStudentJSON" rowKey="objectId" :scroll="{ y: 300 }" :rowClassName="(record, index) => (index % 2 === 1 ? 'bg-gray-50' : 'bg-white')
            ">
          <a-table-column key="candidateNumber" title="学生编号" data-index="candidateNumber" />
          <a-table-column key="avatar" title="头像">
            <template #default="{ record }">
              <div class="avatar">
                <div class="w-8 h-8 rounded">
                  <img :src="record.avatar">
                </div>
              </div>
            </template>
          </a-table-column>
          <a-table-column key="name" title="学生姓名" data-index="name" />
          <a-table-column key="gender" title="性别">
            <template #default="{ record }">
              <div>{{ record.gender == 0 ? '男' : '女' }}</div>
            </template>
          </a-table-column>
          <a-table-column key="mobilePhone" title="手机号码" data-index="mobilePhone" />

          <a-table-column key="action" title="操作">
            <template #default="{ record }">

              <span>
                <!-- <router-link  target="_blank" :to="{path:'StudentPaper',params:{studentId:record.object}}">查看成绩</router-link> -->
                <a @click="toStudentDetail(record)" class="text-gyjdGreen-500">查看成绩</a>
                <a-divider type="vertical" />
                <a @click="toDelete(record)" class="text-red-500">删除学生</a>

              </span>
            </template>
          </a-table-column>

          <template #footer>总共:<span class="text-indigo-600 px-2">{{ allStudentJSON.length }}</span>条记录</template>
        </a-table>
      </div>

    </div>
  </div>
  <CreateGroup :teacher="teacher" v-if="showCreateGroup" @done="createGroupDone" />
  <HLLoading :content="'正在导入,请稍等'" v-if="requesting" />
</template>
<script>
import { reactive, toRefs, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import AV from 'leancloud-storage'
import _ from 'lodash'
import { saveAs } from 'file-saver'
import Util from '@/utils/util.js'
import xlsx from 'xlsx'
import { CalendarIcon, ChartBarIcon, FilmIcon, BookmarkIcon, CollectionIcon, AcademicCapIcon } from '@heroicons/vue/outline'
import router from '@/router'
import CreateGroup from '@/views/mine/components/createGroup.vue'
import HLDialog from '@/components/dialog.js'
import HLLoading from '@/components/Loading.vue'
import HLConfirm from '@/components/confirm.js'
const generateCode = async (min) => {
  var str = ''
  var range = min
  var arr = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9'
  ]
  for (var i = 0; i < range; i++) {
    const pos = Math.round(Math.random() * (arr.length - 1))
    str += arr[pos]
  }
  return str
}

export default {
  components: {
    HLLoading,
    CreateGroup
  },
  setup () {
    const store = useStore()
    const route = useRoute()
    console.log(route.params.id)
    if (!route.params.id && !store.state.student) {
      router.push({ name: 'Home' })
    }
    const state = reactive({
      groupIndex: ref(null),
      allGroupJSON: ref(null),
      teacher: ref(null),
      showCreateGroup: ref(false),
      allGroup: ref(null),
      allStudent: ref(null),
      allStudentJSON: ref(null),
      requesting: ref(false)
    })

    watch(
      () => state.groupIndex,
      async () => {
        state.allStudent = await new AV.Query('GYJDStudent').exists('password').include('user').equalTo('group', state.allGroup[state.groupIndex]).find()
        state.allStudentJSON = Util._jsonify(state.allStudent)
      }
    )
    onMounted(async () => {
      if (route.params.id) {
        state.teacher = await new AV.Query('GYJDTeacher').include('school').get(route.params.id)
        state.teacherJSON = Util._jsonify(state.teacher)
        state.allGroup = await new AV.Query('GYJDGroup').equalTo('validated', true).equalTo('teacher', state.teacher).descending('updatedAt').find()
        state.allGroupJSON = Util._jsonify(state.allGroup)
        if (state.allGroupJSON.length > 0) {
          state.groupIndex = 0
        }
      }
    })

    const toStudentDetail = async (record) => {
      const { href } = router.resolve({ name: 'StudentPaper', query: { studentId: record.objectId } })
      window.open(href, '_blank')
    }
    const changeGroup = async (index) => {
      state.groupIndex = index
    }
    const createGroupDone = async (group) => {
      state.showCreateGroup = false
      if (group) {
        state.allGroup.unshift(group)
        state.allGroupJSON = Util._jsonify(state.allGroup)
        state.allStudent = await new AV.Query('GYJDStudent').exists('password').include('user').equalTo('group', state.allGroup[state.groupIndex]).find()
        state.allStudentJSON = Util._jsonify(state.allStudent)
      }
    }
    const toShowCreateGroup = () => {
      state.showCreateGroup = true
    }
    const readFile = async (file) => {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.readAsBinaryString(file)
        reader.onload = ev => {
          resolve(ev.target.result)
        }
      })
    }
    const uploadStudentExcel = async () => {
      const input = document.createElement('input')
      input.setAttribute('name', new Date().getTime())
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel')
      input.click()

      input.onchange = async () => {
        state.requesting = true
        const localFile = input.files[0]
        const dataBinary = await readFile(localFile)
        const workBook = xlsx.read(dataBinary, { type: 'binary', cellDates: true })
        const workSheet = workBook.Sheets[workBook.SheetNames[0]]
        const data = xlsx.utils.sheet_to_json(workSheet)

        if (data.length > 0 && (!data[0].学生姓名 || !data[0].学生性别 || !data[0].学生手机号码)) {
          HLDialog({
            content: '导入错误。格式不正确。'
          })
          state.requesting = false
          return
        }
        let successCount = 0
        for (let i = 0; i < data.length; i++) {
          const studentName = String(data[i].学生姓名)
          const studentGender = String(data[i].学生性别)
          const studentPhone = String(data[i].学生手机号码)
          if (!studentPhone || !studentName) {
            data[i].备注 = '数据不能为空'
            continue
          }
          //   studentPhone = studentPhone.trim()
          // console.log('studentPhone', studentPhone, studentPhone.length)
          const student = await new AV.Query('GYJDStudent').equalTo('mobilePhone', studentPhone).first()

          if (student) {
            if (student.get('candidateNumber') && student.get('candidateNumber').length === 12) {
              // 已经绑定过了，不能重复绑定
              data[i].备注 = '重复录入'
              data[i].编号 = student.get('candidateNumber')
              continue
            }
            student.set('name', studentName)
            student.set('gender', studentGender === '男' ? 0 : 1)
            student.set('candidateNumber', '2022' + await generateCode(8))
            student.set('password', '123456')
            student.set('school', state.teacher.get('school'))
            student.set('group', state.allGroup[state.groupIndex])
            student.set('teacher', state.teacher)
            try {
              await student.save()
              successCount++
              data[i].备注 = '录入成功'
              data[i].编号 = student.get('candidateNumber')
            } catch {
              continue
            }
          } else {
            data[i].备注 = '未绑定过图书码。请使用该手机号码对应的微信扫描图书二维码，绑定用户。'
          }
        }
        _.delay(function () {
          state.requesting = false
          state.allGroup[state.groupIndex].set('numberOfMembers', state.allGroup[state.groupIndex].get('numberOfMembers') + successCount)
          state.allGroup[state.groupIndex].save()
          state.allGroupJSON = Util._jsonify(state.allGroup)
        }, 2000, '')

        if (data.length > 0) {
          state.allStudent = await new AV.Query('GYJDStudent').exists('password').include('user').equalTo('group', state.allGroup[state.groupIndex]).find()
          state.allStudentJSON = Util._jsonify(state.allStudent)
          const header = ['学生姓名', '学生性别', '学生手机号码', '编号', '备注']
          const worksheet = xlsx.utils.json_to_sheet(data, { header })
          const wb = xlsx.utils.book_new()
          xlsx.utils.book_append_sheet(wb, worksheet, state.allGroupJSON[state.groupIndex].gradeIndex + '年级-' + state.allGroupJSON[state.groupIndex].classIndex + '班')

          const workbookBlob = _workbook2blob(wb)
          saveAs(workbookBlob, '指引解读' + state.allGroupJSON[state.groupIndex].gradeIndex + '年级-' + state.allGroupJSON[state.groupIndex].classIndex + '班' + '导入情况.xls')
        }
      }
    }

    const downloadAllStudent = async () => {
      const data = []
      for (let i = 0; i < state.allStudentJSON.length; i++) {
        data.push({
          学生姓名: state.allStudentJSON[i].name,
          学生性别: state.allStudentJSON[i].gender === 0 ? '男' : '女',
          学生手机号码: state.allStudentJSON[i].mobilePhone,
          编号: state.allStudentJSON[i].candidateNumber

        })
      }
      const header = ['学生姓名', '学生性别', '学生手机号码', '编号']
      const worksheet = xlsx.utils.json_to_sheet(data, { header })
      const wb = xlsx.utils.book_new()
      xlsx.utils.book_append_sheet(wb, worksheet, state.allGroupJSON[state.groupIndex].gradeIndex + '年级-' + state.allGroupJSON[state.groupIndex].classIndex + '班')
      const workbookBlob = _workbook2blob(wb)
      saveAs(workbookBlob, '指引解读' + state.allGroupJSON[state.groupIndex].gradeIndex + '年级-' + state.allGroupJSON[state.groupIndex].classIndex + '班' + '.xls')
    }
    // 将workbook装化成blob对象
    const _workbook2blob = (workbook) => {
      // 生成excel的配置项
      var wopts = {
        // 要生成的文件类型
        bookType: 'xls',
        bookSST: false,
        type: 'binary'
      }
      var wbout = xlsx.write(workbook, wopts)
      // 将字符串转ArrayBuffer
      function s2ab (s) {
        var buf = new ArrayBuffer(s.length)
        var view = new Uint8Array(buf)
        for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
        return buf
      }
      var blob = new Blob([s2ab(wbout)], {
        type: 'application/octet-stream'
      })
      return blob
    }
    const toDelete = async (record) => {
      HLConfirm({
        content: '确定删除学生吗?',
        close: () => {

        },
        doneAndNext: async () => {
          const findStudent = state.allStudent.find(s => {
            return s.id === record.objectId
          })

          findStudent.unset('group')
          findStudent.unset('teacher')
          findStudent.unset('school')
          findStudent.unset('candidateNumber')
          await findStudent.save()
          state.allGroup[state.groupIndex].set('numberOfMembers', state.allGroup[state.groupIndex].get('numberOfMembers') - 1)
          state.allGroup[state.groupIndex].save()
          state.allGroupJSON = Util._jsonify(state.allGroup)
          _.remove(state.allStudent, function (a) {
            return a.id === record.objectId
          })
          state.allStudentJSON = Util._jsonify(state.allStudent)
          HLDialog({
            content: '删除成功'
          })
        }
      })
    }
    return {
      toDelete,
      uploadStudentExcel,
      createGroupDone,
      toStudentDetail,
      changeGroup,
      toShowCreateGroup,
      downloadAllStudent,
      ...toRefs(state)
    }
  }
}
</script>
