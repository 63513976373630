<template>
  <div class="font-HLFont ">
    <input type="checkbox" id="hl-loading-modal" class="modal-toggle" checked>
    <div class="modal w-full h-full">
      <div class="modal-box items-center flex flex-col justify-center w-full ">
         <VueLottiePlayer
          name="loadingAnim"
          loop
          width="80px"
          height="80px"
          :animationData="require('@/assets/lotties/loading.json')"
          renderer="canvas"
          @animControl="(anim) => (loadingAnim = anim)"
        />
          <button class="btn loading mt-10">{{content}}</button>
      </div>
    </div>
  </div>
</template>

<script  >
import { defineComponent, reactive, toRefs, onBeforeUnmount, ref } from 'vue'
import VueLottiePlayer from 'vue-lottie-player'
export default defineComponent({
  components: {
    VueLottiePlayer
  },
  name: 'hl-loading',
  props: ['content'],
  setup () {
    const state = reactive({
      loadingAnim: ref(null)

    })
    onBeforeUnmount(async () => {
      state.loadingAnim && state.loadingAnim.stop()
      state.loadingAnim = ref(null)
    })
    return {
      ...toRefs(state)
    }
  }
})
</script>
