<template>

  <div v-if="!selectedQuestionTypeId" class="p-10 w-full h-full flex flex-col justify-center items-center">
    <div class="flex flex-row text-lg text-indigo-500 border-b-2  align-baseline cursor-pointer" @click="toBack">
      <ArrowCircleLeftIcon
        class="h-8 w-8 text-indigo-600"
      />

      <div class="text-black  italic ml-4 ">{{unitName}}</div>

    </div>

    <div class="mt-10 w-full h-full flex flex-col justify-center items-center ">

      <button
        @click="toDoAnswer(questionType,index)"
        v-for="(questionType,index) in allQuestionTypeStatus"
        :key="questionType.questionTypeId"
        class="btn btn-outline  my-2 w-1/2 flex flex-row justify-between"
      ><img
          :src="questionType.showImageUrl"
          class="w-4 h-4"
        />
        <div class="">{{questionType.showName}}</div>
      </button>
    </div>

  </div>
  <div v-else  class="p-10  w-full h-full flex flex-col justify-center items-center">
        <HLDoAnswer  :questionTypeId="selectedQuestionTypeId"  :gradeId="selectedGradeId"  @toBack="toBack"/>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import HLDoAnswer from '@/views/study/doAnswer.vue'
import {

  ArrowCircleLeftIcon
} from '@heroicons/vue/outline'
import AV from 'leancloud-storage'
import _ from 'lodash'
import Util from '@/utils/util.js'
export default defineComponent({
  components: {
    ArrowCircleLeftIcon,
    HLDoAnswer
  },
  props: ['gradeId', 'unitName'],
  emits: ['toBack'],
  setup (props, { emit }) {
    console.log(props.unitName)
    const store = useStore()
    const state = reactive({
      allQuestionTypeStatus: [],
      initDone: false,

      selectedQuestionTypeId: ref(null),
      selectedGradeId: props.gradeId
    })
    onMounted(async () => {
      await onLoad()
      console.log(state)
    })
    const onLoad = async () => {
      const allQuestionTypeStatus = []
      const allQuestionTypeJSON = _.orderBy(store.state.allQuestionTypeJSON, ['series'], 'asc')
      for (let i = 0; i < allQuestionTypeJSON.length; i++) {
        allQuestionTypeStatus.push({
          questionTypeId: allQuestionTypeJSON[i].objectId,
          showName: allQuestionTypeJSON[i].showName,
          showImageUrl: allQuestionTypeJSON[i].showImageUrl,
          showImageActiveUrl: allQuestionTypeJSON[i].showImageActiveUrl,
          ifCanContinue: true

        })
      }
      state.allQuestionTypeStatus = allQuestionTypeStatus
      state.initDone = true
    }
    const toBack = () => {
      console.log(1111)
      if (state.selectedQuestionTypeId) {
        state.selectedQuestionTypeId = ref(null)
      } else {
        emit('toBack')
      }
    }
    const toDoAnswer = (questionType) => {
      state.selectedQuestionTypeId = questionType.questionTypeId
    //   console.log(state.selectedQuestionTypeId)
    }
    return {
      onLoad,
      toBack,
      toDoAnswer,
      ...toRefs(state)
    }
  }
})
</script>
