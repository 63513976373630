<template>
    <div style="font-size:14px; " class="flex flex-col items-center w-full">
  <!-- 倒计时区域-->
  <div style="height:40px;background: #FFE7C1;border-radius: 4px;margin-bottom:10px;" class="w-1/2 mt-4 flex flex-row  justify-center items-center">
   <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
    <div style="font-weight: 900;color: black;font-size: 14px;letter-spacing: 1px;margin-left:18px;">{{systemOperationTip}}
      <!-- <HLVoiceGroup v-if="systemOperationType==0" @voicePlayEnd="questionItemVoiceEnd" :showLottie="false" :autoPlay="false" :allUrls="allUrls" :times="2" :order="index">
      </HLVoiceGroup> -->
      <!-- 播放滴一声，进入下一题-->
      <HLVoiceGroup v-if="systemOperationType==3"  :times="1"  @voicePlayEnd="toNextQuestion" :showLottie="false" :autoPlay="false" :allUrls="['https://file.nj.hlingsoft.com/JMad9VoFnKXQiSloiTOAGXMGVy2538tk/beep.mp3']">
      </HLVoiceGroup>

    </div>
    <div v-if="systemOperationSeconds>0" style="font-weight: 900;font-size: 14px;letter-spacing: 1px;margin-left:18px;">
      {{systemOperationSeconds}}</div>
  </div>

  <div class="flex flex-col  justify-center  pb-40   w-3/4 pl-5" >
    <div class="flex flex-row w-full items-center" style="margin-bottom:10px;margin:27px auto 0 auto">
    <div style="width:7px;height:20px;background:#00D48E;border-radius:3px;"></div>
    <div style="margin-left:10px;font-size:16px;font-weight:700;color:#181818">
      <div> 要点：</div>
      </div>
  </div>

   <div class="flex flex-col" style="text-indent:30px; margin:20px;line-height: 25px;color:#11152B;">

      <div   style="font-size:14px;text-indent: 30px;margin-top:3px;" v-for="(item) in question.questionData[0]"  :key="item.sn">

               {{item.sn}}.  {{item.intro}}

       </div>

     </div>
     <div class="w-full flex flex-col items-center justify-center -mt-20">
            <HLRecord
            v-if="showRecordComponent"
            :question="question"
            :maxSeconds="90"
            @onEnd="onRecordEnd"
            @beingEvaluation="beingEvaluation"
          />

     </div>

  </div>

</div>
</template>
<script>
import { reactive, toRefs, defineComponent, ref } from 'vue'

import HLRecord from '@/views/components/common/record.vue'
import HLEvaluationResult from '@/views/components/common/evaluationResult.vue'

import HLVoiceGroup from '@/views/components/common/voiceGroup.vue'
import _ from 'lodash'
export default defineComponent({
  components: {
    HLVoiceGroup,

    HLRecord

  },
  name: 'HL-huatijianshu',
  props: ['question'],
  emits: ['setAnswerData'],
  setup (props, { emit }) {
    const state = reactive({
      question: props.question,
      seconds: 0,
      myVoiceText: '我的录音',
      correct: false,
      requesting: false,
      choiceIndex: -1,
      showRecordComponent: false,
      currentShowVoiceButtonIndex: -1,
      resultData: ref(null),
      timerInterval: setInterval(function () {
        state.seconds += 1
      }, 1000),
      currentQuestionIndex: 0,
      systemOperationTip: '阅读题目倒计时',
      systemOperationType: 1, // 0代表播放音频、1代表阅读题目、2代表回答倒计时、3代表播放滴，需要进入下一题

      systemOperationSeconds: 120,
      systemOperationTimer: setInterval(function () {
        state.systemOperationSeconds--
        if (state.systemOperationSeconds === 0) {
          // 回答倒计时
          // 答题倒计时结束了
          state.systemOperationTimer && clearInterval(state.systemOperationTimer)
          // 进行录音
          toRecording()
        }
      }, 1000)

    })
    const toRecording = async () => {
      state.showRecording = true
      state.showRecordComponent = true
      state.systemOperationSeconds = 90
      state.systemOperationType = 5
      state.systemOperationTip = '正在答题...'
      state.systemOperationTimer = setInterval(function () {
        state.systemOperationSeconds--
        if (state.systemOperationSeconds === 0) {
          state.systemOperationTimer && clearInterval(state.systemOperationTimer)
        }
      }, 1000)
    }

    const onRecordEnd = async (data) => {
    //   console.log('onRecordEnd', data)
      state.showRecordComponent = false
      state.resultData = data
      if (!state.requesting) {
        state.requesting = true
        const evaluationData = state.resultData.evaluationData.EngineResult
        emit('setAnswerData', {
          useSeconds: state.seconds,
          evalType: evaluationData.EvalType,
          answerDetail: evaluationData,
          voiceUrl: state.resultData.userVoiceUrl,
          evaluationSeconds: state.resultData.evaluationSeconds,
          score: evaluationData.score,
          correct: evaluationData.score >= 60,
          voiceSpeed: evaluationData.voiceSpeed,
          fluency: Math.ceil(evaluationData.lines[0].fluency),
          sample: evaluationData.lines[0].sample,
          usertext: evaluationData.lines[0].usertext,
          integrity: Math.ceil(evaluationData.lines[0].integrity),
          pronunciation: Math.ceil(evaluationData.lines[0].pronunciation)
        })
      }

      state.systemOperationSeconds = -1
      state.systemOperationTip = '你已答完本套试卷。'
      state.systemOperationType = 3
    }
    const beingEvaluation = async () => {
      state.systemOperationTimer && clearInterval(state.systemOperationTimer)
      state.systemOperationSeconds = -1
      state.systemOperationTip = '系统正在打分...'
    }

    const toNextQuestion = async () => {
      _.delay(function () {
        emit('toNextQuestion')
      }, 500, '')
    }
    return {
      toNextQuestion,

      onRecordEnd,
      beingEvaluation,
      ...toRefs(state)
    }
  }
})

</script>
