<template>
  <div class="  min-h-screen bg-gray-100 pb-20 h-full flex flex-row justify-center items-center ">
    <div
      class="w-11/12 h-5/6 flex flex-row rounded-2xl"
      v-if="studentJSON"
      style=" background: #FFFFFF;border: 1px solid #F8F8F8;box-shadow: 0px 3px 39px rgba(195, 195, 195, 0.16);"
    >

      <div class="card bordered p-10 w-full items-center justify-center flex-col flex">
        <div class="avatar ">
          <div class="rounded-btn w-16 h-16">
            <img :src="studentJSON.avatar" />
          </div>
        </div>
        <div
          v-if="!studentJSON.candidateNumber"
          class="my-3  mt-10"
        >

          {{studentJSON.name?studentJSON.name:studentJSON.nickname}}

        </div>
        <div
          v-if="studentJSON.candidateNumber"
          class="my-3  w-1/4 mt-10 "
        >

          <span class="mr-10 w-20">姓名:</span>{{studentJSON.name?studentJSON.name:studentJSON.nickname}}

        </div>
        <div
          v-if="studentJSON.candidateNumber"
          class="my-3   w-1/4 text-left"
        >

          <span class="mr-10 w-20">编号:</span>{{studentJSON.candidateNumber}}

        </div>
        <div
          v-if="studentJSON.candidateNumber"
          class="my-3 w-1/4  text-left"
        >

          <span class="mr-10 w-20">学校:</span>{{studentJSON.school.name}}

        </div>
        <div
          v-if="studentJSON.candidateNumber"
          class="my-3 w-1/4  "
        >

          <span class="mr-10 w-20 ">班级:</span>{{studentJSON.group.gradeIndex}}年级/{{studentJSON.group.classIndex}}班

        </div>
        <div
          v-if="studentJSON.candidateNumber"
          class="my-3  w-1/4  text-left"
        >

          <span class="mr-10 w-20">教师:</span>{{studentJSON.teacher.name}}

        </div>
        <div class="flex flex-row">

          <!-- <button
          @click="showTeacherPage"
            class="btn  bg-gyjdGreen-500 border-gyjdGreen-500 hover:bg-gyjdGreen-600 hover:border-gyjdGreen-600 w-40 mt-10 mx-4"
            v-if="teacher"
          >教师控制台</button> -->
          <button
            @click="toLogOut"
            class="btn btn-warning w-40 mt-10  mx-4"
          >退出登录</button>
          <!-- <button
            v-if="studentJSON.candidateNumber && studentJSON.password"
            @click="toChangePassword"
            class="btn btn-error w-40 mt-10  mx-4"
          >修改密码</button> -->
        </div>

      </div>
    </div>
  </div>
  <HLChangePassword  v-if="showChangePassword" :student="student" @changePasswordDone="changePasswordDone"/>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import AV from 'leancloud-storage'
import DayJS from 'dayjs'
import Util from '@/utils/util.js'
import Confirm from '@/components/confirm.js'
import router from '@/router'
import HLChangePassword from '@/views/mine/components/changePassword.vue'
export default {
  components: {
    HLChangePassword
  },
  setup () {
    const store = useStore()

    const state = reactive({
      studentJSON: ref(null),
      teacher: ref(null),
      student: ref(null),
      showChangePassword: ref(false)
    })
    onMounted(async () => {
      store.state.student = await new AV.Query('GYJDStudent')
        .equalTo('user', AV.User.current())
        .include('group')
        .include('school')
        .include('teacher')
        .include('user')
        .equalTo('default', true)
        .first()
      store.state.studentJSON = Util._jsonify(store.state.student)
      state.studentJSON = store.state.studentJSON
      state.student = store.state.student
      console.log(state.studentJSON)

      const teacher = await new AV.Query('GYJDTeacher').include('school').equalTo('mobilePhone', state.studentJSON.mobilePhone).equalTo('validated', true).first()
      state.teacher = teacher
    })
    const toAction = async (action) => {
      router.push({ name: action.routeName })
    }
    const toLogOut = () => {
      Confirm({
        content: '确定登出吗?',
        doneAndNext: async () => {
          AV.User.logOut()
          localStorage.clear()

          location.reload()
        }
      })
    }
    const toChangePassword = () => {
      state.showChangePassword = true
    }
    const changePasswordDone = () => {
      state.showChangePassword = false
    }
    const showTeacherPage = () => {
      router.push({ name: 'Teacher', params: { id: state.teacher.id } })
    }
    return {
      toChangePassword,
      changePasswordDone,
      toAction,
      toLogOut,
      showTeacherPage,
      ...toRefs(state)
    }
  }
}
</script>
<style scoped>
/* a:hover {
  color: rgba(99, 102, 241, var(--tw-text-opacity));
} */
</style>
