<template>
<HLLearningDataDetail v-if="student" :student="student" :gradeId="gradeId"/>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import AV, { Query } from 'leancloud-storage'
import DayJS from 'dayjs'
import Util from '@/utils/util.js'
import HLLearningDataDetail from '@/views/learningData/components/detail.vue'
import router from '@/router'

export default {
  components: { HLLearningDataDetail },

  setup (props, { emit }) {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    if (!route.params.gradeId && !route.params.studentId) {
      router.push({ name: 'Student' })
    }
    const state = reactive({
      allTestPaper: [],
      allGradeDoneCount: [],
      student: ref(null),
      gradeId: route.params.gradeId
    })

    onMounted(async () => {
      if (route.params.studentId) {
        const student = await new AV.Query('GYJDStudent')
          .include('group')
          .include('school')
          .include('teacher')
          .include('user')
          .get(route.params.studentId)

        state.student = student
      }
    })

    return {

      ...toRefs(state)
    }
  }
}
</script>
