<template>
  <div class="flex flex-row p-10 ">
    <!-- 题干-->
    <div class="w-1/5 flex flex-col justify-center items-center">
      <HLVoice
        :url="question.questionData[0][0].voice.url"
        :stop="requesting"
      />
      <img
       :src="question.questionData[0][0].image.url+'?imageView2/2/500' "
        class="mt-10 "
      >
    </div>

    <div class="w-3/5 flex flex-col  justify-center items-center ">

      <div
        class="flex-row flex justify-center items-center w-full "
        v-for="(choice,index) in question.questionData[1]"
        :key="choice"
      >
        <button
          class="btn btn-outline min-w-max w-80 mt-10 btn-primary  "
          @click="toChoiceWord(choice,index)"
          :disabled="requesting && choiceIndex!=index"
        ><span class="mr-3">{{index==0?'A':index==1?'B':index==2?'C':'D'}}.</span><span class="lowercase ">{{choice.intro}}</span></button>
      </div>
    </div>

    <div class="w-1/5 flex  justify-center  items-center  ">
      <HLLottiePlayer

        v-if="requesting && correct"
        name="resultAnimation"
        loop
        :animationData="require('@/assets/lotties/great.json')"
      />
      <HLLottiePlayer

        v-if="requesting && !correct"
        name="resultAnimation"
        loop
        :animationData="require('@/assets/lotties/error.json')"
      />

    </div>
  </div>

</template>
<script>
import { reactive, toRefs, defineComponent } from 'vue'
import HLVoice from '@/views/components/common/voice.vue'
import VueLottiePlayer from 'vue-lottie-player'
export default defineComponent({
  components: {
    HLVoice,
    HLLottiePlayer: VueLottiePlayer
  },
  name: 'HL-tinglixuanwenben',
  props: ['question'],
  emits: ['setAnswerData'],
  setup (props, { emit }) {
    const state = reactive({
      question: props.question,
      seconds: 1,
      correct: false,
      requesting: false,
      choiceIndex: -1,
      timerInterval: setInterval(function () {
        state.seconds += 1
      }, 1000)

    })
    const toChoiceWord = async (choice, index) => {
      if (!state.requesting) {
        const resultData = {}
        state.choiceIndex = index
        resultData.correct = choice.sn === state.question.answerSelection[0].sn
        resultData.userChoice = choice
        resultData.score = resultData.correct ? 100 : 0
        resultData.useSeconds = state.seconds
        state.timerInterval && clearInterval(state.timerInterval)

        state.requesting = true
        state.correct = resultData.correct
        emit('setAnswerData', resultData)
      }
    }
    return {
      toChoiceWord,
      ...toRefs(state)
    }
  }
})

</script>
