<template>
  <div
    class="  min-h-screen bg-gray-100 pb-20 h-full flex flex-row justify-center items-center "
  >
    <div
      class="w-11/12 h-5/6 flex flex-row"
      style=" background: #FFFFFF;border: 1px solid #F8F8F8;box-shadow: 0px 3px 39px rgba(195, 195, 195, 0.16);"
    >
      <div class="flex-1 justify-center items-center flex flex-col">
        <div>暂未提供</div>
      </div>
      <div class="flex-1  justify-center items-center flex"></div>
    </div>
  </div>
</template>
