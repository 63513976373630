<template>
  <div
    style="background:#F4F6F1"
    class="w-full min-h-screen  py-4  flex flex-row justify-center items-center "
  >

    <div
      v-if="(allStatistics && allStatistics.length > 0) || data"
      class="w-11/12 min-h-screen mx-auto flex flex-col bg-white   rounded-2xl"
    >
      <div class="w-full h-20 flex flex-col items-center justify-center">
        <div
          class="w-full flex flex-row items-center justify-between  p-3 pl-10 pr-10 cursor-pointer"
        >
          <div @click="toBack" class="flex flex-row items-center flex-1">
            <ArrowCircleLeftIcon
              class="text-gyjdGreen-500 h-8 w-8"
              aria-hidden="true"
            />
            <div class="ml-2">返回</div>
          </div>

          <button
            v-if="data"
            class="bg-gyjdGreen-500 text-white rounded-3xl w-32 h-8"
          >
            总得分：{{
              data[0].score +
                data[1].score +
                data[2].score +
                data[3].score +
                data[4].score
            }}
          </button>
          <div class="flex flex-row items-center justify-end mr-6 flex-1"><Listbox
            v-if="currentSelectedGradeStatistics"
            class="w-60 "
            v-model="currentSelectedGradeStatistics"
          >
            <div class="relative mt-1">
              <ListboxButton
                class=" relative w-full  pl-3  pr-10 text-left bg-white rounded-lg  focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm"
              >
                <span class="block truncate"
                  >{{ currentSelectedGradeStatistics.grade.name }}（第{{
                    allStatistics.length - currentSelectedGradeStatisticsIndex
                  }}次）</span
                >
                <span
                  class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                >
                  <SelectorIcon
                    class="w-5 h-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </ListboxButton>

              <transition
                leave-active-class="transition duration-100 ease-in"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <ListboxOptions
                  class="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                  <ListboxOption
                    v-slot="{ active }"
                    v-for="(gradeStatistics, index) in allStatistics"
                    :key="gradeStatistics.id"
                    :value="gradeStatistics"
                    as="template"
                  >
                    <li
                      :class="[
                        active
                          ? 'text-amber-900 bg-amber-100'
                          : 'text-gray-900',
                        'cursor-default select-none relative py-2 pl-10 pr-4',
                      ]"
                    >
                      <span
                        :class="[
                          currentSelectedGradeStatistics.id ==
                          gradeStatistics.id
                            ? ' font-extrabold'
                            : 'font-normal',
                          'block truncate',
                        ]"
                        >{{ gradeStatistics.grade.name }}（第{{
                          allStatistics.length - index
                        }}次）</span
                      >

                      <span
                        v-if="
                          currentSelectedGradeStatistics.id ==
                            gradeStatistics.id
                        "
                        class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
                      >
                        <CheckIcon class="w-5 h-5" aria-hidden="true" />
                      </span>
                    </li>
                  </ListboxOption>
                </ListboxOptions>
              </transition>
            </div>
          </Listbox>
          <button @click="toExport" :disabled="exporting" class="btn btn-outline  ml-5    btn-circle mt-3 ">导出</button>
          </div>

        </div>
        <div class="my-2 bg-gray-50 w-full h-1"></div>
      </div>
  <div class="bg-gray-50 w-full h-1"></div>
      <div id="content"
        class="w-full pl-10 pr-10 flex flex-col items-center justify-center bg-white "
      >

      <div class="m-4 text-base"><span>{{student.name?student.name:student.nickname}}</span><span class="ml-2">{{currentSelectedGradeStatistics.grade.name}}</span> </div>
        <div class="w-2/3" v-if="data">
          <div
            style="background:#FCFAFD"
            class="flex justify-between items-center rounded-lg px-4 mt-4 py-3"
          >
            <div class="text-black">听力测试（计20分）</div>
            <button class="bg-gyjdGreen-500 text-white rounded-3xl w-32 h-8">
              得分：{{ data[0].score + data[1].score }}
            </button>
          </div>
          <div class="flex justify-between mt-14">
            <div>第一部分 听对话和回答问题（计10分）</div>
            <div>得分：{{ data[0].score }}</div>
          </div>
          <table
            class="table-fixed text-center border-collapse border border-gray-400 mt-4"
          >
            <thead>
              <tr class="text-gray-400">
                <th class="w-1/6 border border-gray-300 py-2"></th>
                <th class="w-1/12 border border-gray-300 py-2">1</th>
                <th class="w-1/12 border border-gray-300 py-2">2</th>
                <th class="w-1/12 border border-gray-300 py-2">3</th>
                <th class="w-1/12 border border-gray-300 py-2">4</th>
                <th class="w-1/12 border border-gray-300 py-2">5</th>
                <th class="w-1/12 border border-gray-300 py-2">6</th>
                <th class="w-1/12 border border-gray-300 py-2">7</th>
                <th class="w-1/12 border border-gray-300 py-2">8</th>
                <th class="w-1/12 border border-gray-300 py-2">9</th>
                <th class="w-1/12 border border-gray-300 py-2">10</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border border-gray-300 px-5 ">你的答案</td>

                <td
                  v-for="index in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]"
                  :key="index"
                  :class="[
                    data[0].data[index].answer
                      ? data[0].data[index].answer.correct
                        ? 'text-gyjdGreen-500'
                        : 'text-red-500'
                      : '',
                    'border border-gray-300 ',
                  ]"
                >
                  {{
                    data[0].data[index].answer
                      ? data[0].data[index].answer.userChoice.sn == 1
                        ? "A"
                        : data[0].data[index].answer.userChoice.sn == 2
                        ? "B"
                        : "C"
                      : ""
                  }}
                </td>
              </tr>
              <tr>
                <td class="border border-gray-300 px-5 ">正确答案</td>
                <td
                  v-for="index in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]"
                  :key="index"
                  class="border border-gray-300 text-gyjdGreen-500"
                >
                  {{
                    data[0].data[index].answerSelection[0].sn == 1
                      ? "A"
                      : data[0].data[index].answerSelection[0].sn == 2
                      ? "B"
                      : "C"
                  }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="flex justify-between mt-14">
            <div>第二部分 听对话和短文答题（计10分）</div>
            <div>得分：{{ data[1].score }}</div>
          </div>
          <table
            class="table-fixed text-center border-collapse border border-gray-400 mt-4"
          >
            <thead>
              <tr class="text-gray-400 ">
                <th class="w-1/6 border border-gray-300 py-2"></th>
                <th class="w-1/12 border border-gray-300 py-2">1</th>
                <th class="w-1/12 border border-gray-300 py-2">2</th>
                <th class="w-1/12 border border-gray-300 py-2">3</th>
                <th class="w-1/12 border border-gray-300 py-2">4</th>
                <th class="w-1/12 border border-gray-300 py-2">5</th>
                <th class="w-1/12 border border-gray-300 py-2">6</th>
                <th class="w-1/12 border border-gray-300 py-2">7</th>
                <th class="w-1/12 border border-gray-300 py-2">8</th>
                <th class="w-1/12 border border-gray-300 py-2">9</th>
                <th class="w-1/12 border border-gray-300 py-2">10</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border border-gray-300 px-5 ">你的答案</td>
                <td
                  v-for="index in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]"
                  :key="index"
                  :class="[
                    data[1].data[index].answer
                      ? data[1].data[index].answer.correct
                        ? 'text-gyjdGreen-500'
                        : 'text-red-500'
                      : '',
                    'border border-gray-300 ',
                  ]"
                >
                  {{
                    data[1].data[index].answer
                      ? data[1].data[index].answer.userChoice.sn == 1
                        ? "A"
                        : data[1].data[index].answer.userChoice.sn == 2
                        ? "B"
                        : "C"
                      : ""
                  }}
                </td>
              </tr>
              <tr>
                <td class="border border-gray-300 px-5 ">正确答案</td>
                <td
                  v-for="index in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]"
                  :key="index"
                  class="border border-gray-300 text-gyjdGreen-500"
                >
                  {{
                    data[1].data[index].answerSelection[0].sn == 1
                      ? "A"
                      : data[1].data[index].answerSelection[0].sn == 2
                      ? "B"
                      : "C"
                  }}
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="flex justify-between items-center bg-gray-50 mt-14 py-2 px-4 rounded-lg"
          >
            <div class="text-black">口语测试（计10分）</div>
            <button class="bg-gyjdGreen-500 text-white rounded-3xl w-32 h-8">
              得分：{{ data[2].score + data[3].score + data[4].score }}
            </button>
          </div>
        </div>

        <div v-if="data" class="w-2/3 p-2">
          <div class="flex justify-between mt-1">
            <div>第三部分 朗读短文（计3分）</div>
            <div>得分：{{ data[2].score }}</div>
          </div>
          <ul
            class="h-16 flex justify-between items-center text-center   px-4 rounded-lg mt-6"
          >
            <li>
              <div class="text-orange-300 text-2xl">
                {{ data[2].data[0].answer ? data[2].data[0].answer.score : 0 }}
              </div>
              <div class="text-gray-300">得分</div>
            </li>
            <li>
              <div class="text-blue-500">
                {{
                  data[2].data[0].answer
                    ? data[2].data[0].answer.pronunciation
                    : 0
                }}
              </div>
              <div class="text-gray-300">发音</div>
            </li>
            <li>
              <div class="text-purple-500">
                {{
                  data[2].data[0].answer ? data[2].data[0].answer.fluency : 0
                }}
              </div>
              <div class="text-gray-300">流畅度</div>
            </li>
            <li>
              <div class="text-gyjdGreen-500">
                {{
                  data[2].data[0].answer ? data[2].data[0].answer.integrity : 0
                }}
              </div>
              <div class="text-gray-300">完整度</div>
            </li>
          </ul>
          <!-- <div
            id="radarChart"
            style="height: 300px; width: 100% padding:10px"
          ></div> -->
          <div class="flex justify-between mt-14">
            <div>第四部分 情景问答（计2分）</div>
            <div>得分：{{ data[3].score }}</div>
          </div>
          <ul
            v-for="(item, index) in data[3].data"
            :key="index"
            class="h-16 flex justify-between items-center text-center   px-4 rounded-lg mt-6"
          >
          <div>第{{index+1}}小题</div>
            <li>
              <div class="text-orange-300 text-2xl">
                {{ item.answer ? item.answer.score : 0 }}
              </div>
              <div class="text-gray-300">得分</div>
            </li>
            <li>
              <div class="text-blue-500">
                {{ item.answer ? item.answer.pronunciation : 0 }}
              </div>
              <div class="text-gray-300">发音</div>
            </li>
            <li>
              <div class="text-purple-500">
                {{ item.answer ? item.answer.fluency : 0 }}
              </div>
              <div class="text-gray-300">流畅度</div>
            </li>
            <li>
              <div class="text-gyjdGreen-500">
                {{ item.answer ? item.answer.integrity : 0 }}
              </div>
              <div class="text-gray-300">完整度</div>
            </li>
          </ul>

          <div class="flex justify-between mt-14">
            <div>第五部分 话题阐述（计5分）</div>
            <div>得分：{{ data[4].score }}</div>
          </div>
          <ul
            class="h-16 flex justify-between items-center text-center   px-4 rounded-lg mt-6"
          >
            <li>
              <div class="text-orange-300 text-2xl">
                {{ data[4].data[0].answer ? data[4].data[0].answer.score : 0 }}
              </div>
              <div class="text-gray-300">得分</div>
            </li>
            <li>
              <div class="text-blue-500">
                {{
                  data[4].data[0].answer
                    ? data[4].data[0].answer.pronunciation
                    : 0
                }}
              </div>
              <div class="text-gray-300">发音</div>
            </li>
            <li>
              <div class="text-purple-500">
                {{
                  data[4].data[0].answer ? data[4].data[0].answer.fluency : 0
                }}
              </div>
              <div class="text-gray-300">流畅度</div>
            </li>
            <li>
              <div class="text-gyjdGreen-500">
                {{
                  data[4].data[0].answer ? data[4].data[0].answer.integrity : 0
                }}
              </div>
              <div class="text-gray-300">完整度</div>
            </li>
          </ul>

          <!-- <div
            id="radarChart2"
            style="height: 300px; width: 100% padding:10px"
          ></div> -->

          <div
            class="flex justify-between items-center bg-gray-50 mt-14 py-2 px-4 rounded-lg"
          >
            <div class="text-black">题目类型分值总得分</div>
            <button class="bg-gyjdGreen-500 text-white rounded-3xl w-32 h-8">
              总得分：{{
                data[0].score +
                  data[1].score +
                  data[2].score +
                  data[3].score +
                  data[4].score
              }}
            </button>
          </div>
          <div
            id="categoryChart"
            ref="categoryChart"
           class="font-HLFont "
            style="height: 600px; width:100%; padding:10px"
          ></div>
        </div>
        <div class="my-2 bg-gray-50 w-full h-1"></div>

      </div>
    </div>
    <div
      v-else
      class="  justify-center items-center w-11/12 min-h-screen mx-auto flex flex-col bg-white   rounded-2xl"
    >
      <div v-if="allStatistics && allStatistics.length == 0">暂无数据</div>
      <button
        v-if="allStatistics && allStatistics.length == 0"
        @click="toBack"
        class="mt-5 btn btn-warning w-40"
      >
        返回
      </button>
      <button v-if="!allStatistics" class="btn loading">正在加载数据...</button>
    </div>
  </div>
</template>
<script>
import {

  reactive,
  toRefs,
  ref,

  onMounted,
  watchEffect,
  onBeforeUnmount,
  defineComponent
} from 'vue'
import AV from 'leancloud-storage'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import * as echarts from 'echarts'
import _ from 'lodash'
import DayJS from 'dayjs'
import HLDialog from '@/components/dialog.js'
import { saveAs } from 'file-saver'
import Student from '@/entity/student.ts'
import Question from '@/entity/question.ts'
import QuestionType from '@/entity/questionType.ts'
import Grade from '@/entity/grade'
import Answer from '@/entity/answer.ts'
import QuestionTypeInGrade from '@/entity/questionTypeInGrade.ts'
import EverySetAnswerStatistics from '@/entity/everySetAnswerStatistics.ts'
import EveryGradeStatistics from '@/entity/everyGradeStatistics.ts'
import {
  ClockIcon,
  ArrowCircleLeftIcon,
  ArrowCircleRightIcon,
  ReplyIcon,
  CheckIcon,
  SelectorIcon,
  VolumeUpIcon
} from '@heroicons/vue/solid'
import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption
} from '@headlessui/vue'

import * as htmlToImage from 'html-to-image'
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image'
export default defineComponent({
  name: 'HL-learning-data-detail',
  props: ['student', 'gradeId'],
  emits: ['back'],
  components: {
    Listbox,

    ListboxButton,
    ListboxOptions,
    ListboxOption,

    ArrowCircleLeftIcon,

    CheckIcon,
    SelectorIcon

  },
  setup (props, { emit }) {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    // if (!route.params.gradeId && !store.state.temporarilyAnswerData) {
    //   router.push({ name: 'LearningData' })
    // }

    const state = reactive({
      exporting: ref(false),
      gradeId: props.gradeId,
      allStatistics: ref(null),
      currentSelectedGradeStatisticsIndex: 0,
      currentSelectedGradeStatistics: ref(null),
      answers: ref(null),
      data: ref(null),
      radarValue: [0, 0, 0], // 雷达图数据
      radarValue2: [0, 0, 0], // 雷达图2数据
      barValue: [0, 0, 0, 0, 0] // 柱形图数据
    })
    // 初始化表格数据
    const _echartInit = () => {
      // if (!state.myChart) { state.myChart = echarts.init(document.getElementById('radarChart')) }
      // if (!state.myChart2) { state.myChart2 = echarts.init(document.getElementById('radarChart2')) }
      if (!state.myCharts) { state.myCharts = echarts.init(document.getElementById('categoryChart')) }
      // var radarOption = {
      //   radar: {
      //     shape: 'circle',
      //     indicator: [
      //       { name: '流畅度', max: 100 },
      //       { name: '发音', max: 100 },
      //       { name: '完整度', max: 100 }
      //     ],
      //     name: {
      //       textStyle: { color: '#53B862' }
      //     }
      //   },
      //   series: [
      //     {
      //       type: 'radar',
      //       // symbol: "none", //是否显示数字
      //       symbolSize: 1,
      //       data: [

      //         {
      //           value: state.radarValue,
      //           name: '真实得分',
      //           areaStyle: {
      //             color: '#53B862'
      //           }
      //         }
      //       ],
      //       label: {
      //         normal: {
      //           show: true,
      //           textStyle: {
      //             color: '#247bd7', // 坐标轴刻度文字的样式
      //             fontSize: 18
      //           },
      //           formatter: function (params) {
      //             return params.value
      //           }
      //         }
      //       }
      //     }
      //   ]
      // }
      // var radarOption2 = {
      //   radar: {
      //     shape: 'circle',
      //     indicator: [
      //       { name: '流畅度', max: 100 },
      //       { name: '发音', max: 100 },
      //       { name: '完整度', max: 100 }
      //     ],
      //     name: {
      //       textStyle: { color: '#53B862' }
      //     }
      //   },
      //   series: [
      //     {
      //       type: 'radar',
      //       // symbol: "none", //是否显示数字
      //       symbolSize: 1,
      //       data: [

      //         {
      //           value: state.radarValue2,
      //           name: '真实得分',
      //           areaStyle: {
      //             color: '#53B862'
      //           }
      //         }
      //       ],
      //       label: {
      //         normal: {
      //           show: true,
      //           textStyle: {
      //             color: '#247bd7', // 坐标轴刻度文字的样式
      //             fontSize: 18
      //           },
      //           formatter: function (params) {
      //             return params.value
      //           }
      //         }
      //       }
      //     }
      //   ]
      // }
      var barOption = {
        legend: {},
        tooltip: {},
        dataset: {
          source: [
            ['分数类型', '总分', '得分'],
            ['第一部分', 10, state.barValue[0]],
            ['第二部分', 10, state.barValue[1]],
            ['第三部分', 5, state.barValue[2]],
            ['第四部分', 3, state.barValue[3]],
            ['第五部分', 2, state.barValue[4]]
          ]
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            textStyle: {
              fontFamily: 'HL-Font'
            }
          }

        },
        yAxis: {
          axisLine: {
            show: true
          },
          axisLabel: {
            textStyle: {
              fontFamily: 'HL-Font'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: [
          {
            type: 'bar',
            color: '#53B862',
            itemStyle: {
              normal: {
                barBorderRadius: 10
              }
            },
            label: {
              normal: {
                show: true,
                position: 'top',
                textStyle: {
                  color: 'green',
                  fontFamily: 'HL-Font'
                }
              }
            }
          },
          {
            type: 'bar',
            color: '#E3C993',
            itemStyle: {
              normal: {
                barBorderRadius: 10
              }
            },
            label: {
              normal: {
                show: true,
                position: 'top',
                textStyle: {
                  fontFamily: 'HL-Font'
                }
              }
            }
          }
        ]
      }
      // 使用刚指定的配置项和数据显示图表。
      // state.myChart.setOption(radarOption)
      // state.myChart2.setOption(radarOption2)
      state.myCharts.setOption(barOption)
    }

    watchEffect(async () => {
      if (state.currentSelectedGradeStatistics) {
        state.currentSelectedGradeStatisticsIndex = state.allStatistics.findIndex(
          (statistic) => {
            return statistic.id === state.currentSelectedGradeStatistics.id
          }
        )
        const answers = await new AV.Query(Answer)
          .equalTo(
            'submitUnionCode',
            state.currentSelectedGradeStatistics.submitUnionCode
          )
          .include('question')
          .equalTo('grade', state.grade)
          .equalTo('student', props.student)
          .find()
        const questions = (
          await new AV.Query(Question)
            .equalTo('grade', state.grade)
            .include('questionType')
            .ascending('indexInOneDay')
            .find()
        ).filter((q) => {
          return q.indexInOneDay % 1 === 0
        })
        console.log(answers)
        const allQuestionTypeResult = await new AV.Query(
          EverySetAnswerStatistics
        )
          .equalTo(
            'submitUnionCode',
            state.currentSelectedGradeStatistics.submitUnionCode
          )
          .equalTo('grade', state.grade)
          .include('questionType')
          .equalTo('student', props.student)
          .find()
        questions.map((q) => {
          q.answer = answers.find((a) => {
            return q.id === a.question.id
          })
        })

        // questions,按照questionType分组

        const grouping = _.groupBy(questions, function (a) {
          return [a.questionType.series]
        })
        const questionAndAnswers = []
        _.keys(grouping).map((key) => {
          const questionTypeResult = allQuestionTypeResult.find((qtr) => {
            // console.log(qtr.questionType.series, key, qtr.series === key)
            return qtr.questionType.series === Number(key)
          })
          questionAndAnswers.push({
            score: questionTypeResult
              ? questionTypeResult.totalAnswerScoreInGrade
              : 0,
            data: grouping[key]
          })
        })
        // console.log(_.toArray(grouping))
        state.data = questionAndAnswers
        console.log(state.data)
        const radarValue = state.data[2].data[0].answer
        if (radarValue) {
          state.radarValue = [] // 先清空数组
          state.radarValue.push(radarValue.fluency)
          state.radarValue.push(radarValue.pronunciation)
          state.radarValue.push(radarValue.integrity)
        } else {
          state.radarValue = [0, 0, 0]
        }
        console.log('雷达图1的数据', state.radarValue)
        const radarValue2 = state.data[4].data[0].answer
        if (radarValue2) {
          state.radarValue2 = [] // 先清空数组
          state.radarValue2.push(radarValue2.fluency)
          state.radarValue2.push(radarValue2.pronunciation)
          state.radarValue2.push(radarValue2.integrity)
        } else {
          state.radarValue2 = [0, 0, 0]
        }
        console.log('雷达图2的数据', state.radarValue2)

        if (state.data) {
          state.barValue = [] // 先清空数组
          for (let i = 0; i < state.data.length; i++) {
            state.barValue.push(state.data[i].score)
          }
        } else {
          state.barValue = [0, 0, 0, 0, 0]
        }
        console.log('柱形图的数据', state.barValue)
      }
      if (state.data) {
        _.delay(function () {
          _echartInit()
        }, 1000, '')
      }
    })
    onMounted(async () => {
      console.log(state.gradeId)
      if (state.gradeId) {
        state.grade = await new AV.Query(Grade).get(state.gradeId)
        state.allStatistics = await new AV.Query(EveryGradeStatistics)
          .equalTo('channel', 'web')
          .equalTo('grade', state.grade)
          .equalTo('student', props.student)
          .include('grade')
          .descending('createdAt')
          .find()

        if (state.allStatistics.length > 0) {
          state.currentSelectedGradeStatistics =
            state.allStatistics[state.currentSelectedGradeStatisticsIndex]
        }
      } else {
        state.data = store.state.temporarilyAnswerData
      }
      console.log(state.data)

      // 挂载表格数据
      if (state.data) {
        _.delay(function () {
          _echartInit()
        }, 2000, '')
      }
    })
    onBeforeUnmount(async () => {
      store.state.temporarilyAnswerData = []
    })

    const toBack = () => {
      router.go(-1)
      // if(props.studentId)
      // router.push({ name: 'LearningData' })
    }
    const toExport = () => {
      state.exporting = ref(true)
      const node = document.getElementById('content')
      const studentName = props.student.get('name') ? props.student.get('name') : props.student.get('nickname')
      const candidateNumber = props.student.get('candidateNumber') ? props.student.get('candidateNumber') : ''
      htmlToImage.toPng(node)
        .then(function (dataUrl) {
          // console.log(dataUrl)
          state.exporting = ref(false)
          saveAs(dataUrl, studentName + candidateNumber + '-' + state.currentSelectedGradeStatistics.grade.name + '.png')
        })
        .catch(function (error) {
          state.exporting = ref(false)
          HLDialog({
            content: '导出失败'
          })
          console.error('oops, something went wrong!', error)
        })
    }
    return {
      toBack,
      toExport,
      ...toRefs(state)
    }
  }
})
</script>
