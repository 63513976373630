
import { defineComponent, reactive, toRefs, onMounted } from 'vue'
import { useStore } from 'vuex'
import AV from 'leancloud-storage'
import QRCodeVue3 from 'qrcode-vue3'
import { useRouter } from 'vue-router'
import Util from '@/utils/util.js'
export default defineComponent({
  name: 'Login',
  props: {},
  components: {
    QRCodeVue3
  },
  setup () {
    const router = useRouter()
    const store = useStore()
    const state = reactive({
      requesting: false,
      qrUrl: '',
      currentStudent: {},
      intervalMaxCount: 15
    })
    onMounted(async () => {
      if (AV.User.current()) {
        AV.User.logOut()
      }
      await refresh()
    })

    const refresh = async () => {
      state.intervalMaxCount = 15
      // 上线时的正确扫码方式
      const createNewQR = new AV.Object('GYJDWebLoginQR')
      await createNewQR.save()
      // 开发时的固定登录方式
      // const createNewQR = await new AV.Query("GYJDWebLoginQR").get(
      //   "61b2038069aa4e42c2690242"
      // );

      state.qrUrl =
        'https://www.yygyjd.com?id=' + createNewQR.get('objectId')
      const interval = setInterval(async function () {
        state.intervalMaxCount--
        if (state.intervalMaxCount > 0) {
          await createNewQR.fetch({
            keys: 'sessionToken,student',
            include: 'student'
          })
          // console.log(createNewQR, state.currentStudent)
          if (Util._jsonify(createNewQR).student) {
            state.currentStudent = Util._jsonify(createNewQR).student
          }
          if (Util._jsonify(createNewQR).sessionToken) {
            interval && clearInterval(interval)
            try {
              await AV.User.become(Util._jsonify(createNewQR).sessionToken)
              store.state.student = await new AV.Query('GYJDStudent')
                .equalTo('user', AV.User.current())
                .equalTo('default', true)
                .first()
              store.state.studentJSON = Util._jsonify(store.state.student)
              const timeout = setTimeout(async function () {
                timeout && clearTimeout(timeout)
                // router.go(0)
                const webLogin = new AV.Object('GYJDWebLogin')
                webLogin.set('student', store.state.student)
                webLogin.set('user', AV.User.current())
                await webLogin.save()

                router.push({ name: 'Home' })
                // location.reload()
              }, 2000)
            } catch (e) {
              //   console.log(error)
              //   // session token 无效
            }
          }
        } else {
          interval && clearInterval(interval)
        }
      }, 2000)
    }
    // 模拟延时
    // const delay = async (ms = 200) => {
    //   await new Promise((r) => setTimeout(r, ms))
    // }

    return {
      refresh,
      ...toRefs(state)
    }
  }
})
