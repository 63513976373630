<template>
<HLLearningData v-if="student" :student="student" :other="false"/>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import AV, { Query } from 'leancloud-storage'
import DayJS from 'dayjs'
import Util from '@/utils/util.js'
import HLLearningData from '@/views/learningData/components/index.vue'
import router from '@/router'

export default {
  components: { HLLearningData },

  setup (props, { emit }) {
    const store = useStore()
    const state = reactive({
      allTestPaper: [],
      allGradeDoneCount: [],
      student: ref(null)
    })

    onMounted(async () => {
      if (!store.state.student) {
        const student = await new AV.Query('GYJDStudent')
          .equalTo('user', AV.User.current())
          .include('group')
          .include('school')
          .include('teacher')
          .include('user')
          .equalTo('default', true)
          .first()

        state.student = student
      } else {
        state.student = store.state.student
      }
    })

    return {

      ...toRefs(state)
    }
  }
}
</script>
