import AV from 'leancloud-storage'

class EverySetAnswerStatistics extends AV.Object {
  get submitUnionCode () {
    return this.get('submitUnionCode')
  }

  set submitUnionCode (value) {
    this.set('submitUnionCode', value)
  }

  get channel () {
    return this.get('channel')
  }

  set channel (value) {
    this.set('channel', value)
  }

  get user () {
    return this.get('user')
  }

  set user (value) {
    this.set('user', value)
  }

  get student () {
    return this.get('student')
  }

  set student (value) {
    this.set('student', value)
  }

  // 这一套题属于哪个等级,只针对他的上一级
  get grade () {
    return this.get('grade')
  }

  set grade (value) {
    this.set('grade', value)
  }

  // 这一套题小题的题目类型
  get questionType () {
    return this.get('questionType')
  }

  set questionType (value) {
    this.set('questionType', value)
  }

  // 这一套题答题总用时
  get useSeconds () {
    return this.get('useSeconds')
  }

  set useSeconds (value) {
    this.set('useSeconds', value)
  }

  get totalUseSeconds () {
    return this.get('totalUseSeconds')
  }

  set totalUseSeconds (value) {
    this.set('totalUseSeconds', value)
  }

  // 这一套题的首轮正确率
  get correctRate () {
    return this.get('correctRate')
  }

  set correctRate (value) {
    this.set('correctRate', value)
  }

  // 这一套题，已经没有错题了。
  get isAllCorrect () {
    return this.get('isAllCorrect')
  }

  set isAllCorrect (value) {
    this.set('isAllCorrect', value)
  }

  // 这一套题，已经没有错题了。
  get correctCount () {
    return this.get('correctCount')
  }

  set correctCount (value) {
    this.set('correctCount', value)
  }

  // 这一套题，已经没有错题了。
  get wrongCount () {
    return this.get('wrongCount')
  }

  set wrongCount (value) {
    this.set('wrongCount', value)
  }

  get totalAnswerLength () {
    return this.get('totalAnswerLength')
  }

  set totalAnswerLength (value) {
    this.set('totalAnswerLength', value)
  }

  get totalAnswerScoreInGrade () {
    return this.get('totalAnswerScoreInGrade')
  }

  set totalAnswerScoreInGrade (value) {
    this.set('totalAnswerScoreInGrade', value)
  }

  // 属于哪一天
  get anyDay () {
    return this.get('anyDay')
  }

  set anyDay (value) {
    this.set('anyDay', value)
  }

  get validated () {
    return this.get('validated')
  }

  set validated (value) {
    this.set('validated', value)
  }
}
AV.Object.register(EverySetAnswerStatistics, 'GYJDEverySetAnswerStatistics')
export default EverySetAnswerStatistics
