import AV from "leancloud-storage";
/**
 * 答题者
 */
class Student extends AV.Object {

 
  get age() {
    return this.get('age');
  }
 
  set age(value) {
    this.set('age', value);
  }
  
  get gender() {
    return this.get('gender');
  }
  
  set gender(value) {
    this.set('gender', value);
  }

 
  //真实姓名
  get name() {
    return this.get('name');
  }
  
  set name(value) {
    this.set('name', value);
  }
 
  get integral() {
    return this.get('integral');
  }
 
  set integral(value) {
    this.set('integral', value);
  }

  /**是否领取过免费的启蒙课堂*/
  get receiveFreeLessons() {
    return this.get('receiveFreeLessons');
  }
  /**是否领取过免费的启蒙课堂*/
  set receiveFreeLessons(value) {
    this.set('receiveFreeLessons', value);
  }


  get mobilePhone() {
    return this.get('mobilePhone');
  }

  set mobilePhone(value) {
    this.set('mobilePhone', value);
  }
/**邮箱 */
  get email() {
    return this.get('email');
  }
/**邮箱 */

  set email(value) {
    this.set('email', value);
  }

  //平台昵称，或者为微信昵称
  get nickname() {
    return this.get('nickname');
  }

  set nickname(value) {
    this.set('nickname', value);
  }


  //头像
  get avatar() {
    return this.get('avatar');
  }

  set avatar(value) {
    this.set('avatar', value);
  }


  get user() {
    return this.get('user');
  }

  set user(value) {
    this.set('user', value);
  }
  get validated() {
    return this.get('validated');
  }
  set validated(value) {
    this.set('validated', value);
  }
  get group() {
    return this.get('group');
  }
  set group(value) {
    this.set('group', value);
  }
  get default() {
    return this.get('default');
  }
  set default(value) {
    this.set('default', value);
  }


  get school() {
    return this.get('school');
  }
  set school(value) {
    this.set('school', value);
  }

  get teacher() {
    return this.get('teacher');
  }
  set teacher(value) {
    this.set('teacher', value);
  }

  get parentStudent() {
    return this.get('parentStudent');
  }
  set parentStudent(value) {
    this.set('parentStudent', value);
  }

  get parentUser() {
    return this.get('parentUser');
  }
  set parentUser(value) {
    this.set('parentUser', value);
  }

  get totalAnswerCount() {
    return this.get('totalAnswerCount');
  }
  set totalAnswerCount(value) {
    this.set('totalAnswerCount', value);
  }


  get totalAnswerSeconds() {
    return this.get('totalAnswerSeconds');
  }
  set totalAnswerSeconds(value) {
    this.set('totalAnswerSeconds', value);
  }
 
  get totalUseSeconds() { 
    return this.get('totalUseSeconds');
  }
 
  set totalUseSeconds(value) {
    this.set('totalUseSeconds', value);
  }


}
AV.Object.register(Student, 'GYJDStudent');
export default Student;




