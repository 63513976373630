import AV from "leancloud-storage";
/**
 * 题目表
 */
class Question extends AV.Object {
  /**
   * 标识
   */
  get license() {
    return this.get('license');
  }
  /**
   * 标识
   */
  set license(value) {
    this.set('license', value);
  }


  get templateType() {
    return this.get('templateType');
  }
  set templateType(value) {
    this.set('templateType', value);
  }
  /**
   * 题目主体
   */
  get questionData() {
    return this.get('questionData');
  }
  /**
   * 题目主体
   */
  set questionData(value) {
    this.set('questionData', value);
  }
  /**
   * 答案类型
   */
  get answerType() {
    return this.get('answerType');
  }
  /**
   * 答案类型
   */
  set answerType(value) {
    this.set('answerType', value);
  }

  /**
   * 天数
   */
  get anyDay() {
    return this.get('anyDay');
  }
  /**
   * 天数
   */
  set anyDay(value) {
    this.set('anyDay', value);
  }

  /**
   * 顺序
   */
  get indexInOneDay() {
    return this.get('indexInOneDay');
  }
  /**
   * 顺序
   */
  set indexInOneDay(value) {
    this.set('indexInOneDay', value);
  }

  /**
   * 正确答案
   */
  get answerSelection() {
    return this.get('answerSelection');
  }
  /**
   * 正确答案
   */
  set answerSelection(value) {
    this.set('answerSelection', value);
  }

  /**
   * 题目文本
   */
  get standardAnswerText() {
    return this.get('standardAnswerText');
  }
  /**
   * 题目文本
   */
  set standardAnswerText(value) {
    this.set('standardAnswerText', value);
  }

  /**
   * 题目文本
   */
  get keywords() {
    return this.get('keywords');
  }
  /**
   * 题目文本
   */
  set keywords(value) {
    this.set('keywords', value);
  }


  /**
   * 题目类型
   */
  get questionType() {
    return this.get('questionType');
  }
  /**
   * 题目类型
   */
  set questionType(value) {
    this.set('questionType', value);
  }

  /**等级 */
  get grade() {
    return this.get('grade');
  }
  /**
   * 等级
   */
  set grade(value) {
    this.set('grade', value);
  }
  /**等级 */
  get groupQuestion() {
    return this.get('groupQuestion');
  }
  /**
   * 等级
   */
  set groupQuestion(value) {
    this.set('groupQuestion', value);
  }
  


}
AV.Object.register(Question, 'GYJDQuestion');
export default  Question; 