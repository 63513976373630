<template>
  <section class="w-full h-screen bg-green-500 flex  justify-center items-center p-10">
    <div class="w-full h-full bg-white  rounded-3xl flex  flex-col p-10  ">

    <div class="w-full">
      <div class="border-b border-gray-200">
        <nav class="-mb-px flex" aria-label="Tabs">
          <a @click="changeTab(index)" v-for="(tab,index) in tabs" :key="tab.name" :href="tab.href" :class="[index==currentTabIndex ? 'border-purple-500 text-purple-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm']" :aria-current="currentTabIndex==index? 'page' : undefined">
            {{ tab.name }}
          </a>
        </nav>
      </div>
    </div>
    <div>
   <a-table v-if="allTeacherApplyJSON" class="mt-10 ant-table-striped  scrollbar scrollbar-thumb-gray-900 scrollbar-track-gray-100" bordered :data-source="allTeacherApplyJSON" rowKey="objectId" :scroll="{ y: 300 }" :rowClassName="
        (record, index) => (index % 2 === 1 ? 'bg-gray-50' : 'bg-white')
      ">
      <a-table-column class="w-20" key="objectId" title="编号" :ellipsis="true" , data-index="objectId" />

      <a-table-column key="nateacherNameme" title="教师姓名" data-index="teacherName" />
      <a-table-column key="selectedSchool" title="城市/区">
        <template #default="{ record }">
            <div>{{record.selectedSchool.city}}/{{record.selectedSchool.district}}</div>

        </template>
      </a-table-column>
      <a-table-column key="selectedSchool" title="学校">
        <template #default="{ record }">
            <div>{{record.selectedSchool.schoolName}}</div>

        </template>
      </a-table-column>

      <a-table-column key="mobilePhone" title="手机号码"  data-index="mobilePhone" />

      <a-table-column key="action" title="操作">
        <template #default="{ record }">
          <span v-if="record.status==0">

            <a @click="apply(true,record)" class="text-gyjdGreen-500">审核通过</a>
            <a-divider type="vertical" />
            <a @click="apply(false,record)"  class="text-red-500">审核不通过</a>
          </span>
            <span v-if="record.status==2">

            <a @click="apply(true,record)" class="text-gyjdGreen-500">审核通过</a>

          </span>
        </template>
      </a-table-column>

      <template #footer>总共:<span class="text-indigo-600 px-2">{{allTeacherApplyJSON.length}}</span>条记录</template>
    </a-table>
    </div>

  </div>

  </section>
</template>
<script>
import { defineComponent, reactive, toRefs, ref, watch, onMounted, watchEffect } from 'vue'
import AV from 'leancloud-storage'
import { useStore } from 'vuex'

import { useRouter } from 'vue-router'
import _ from 'lodash'
import Util from '@/utils/util.js'
import HLDialog from '@/components/dialog.js'
import router from '@/router'

export default defineComponent({
  components: {

  },
  setup () {
    const store = useStore()
    const state = reactive({

      currentTabIndex: ref(null),
      allTeacherApplyJSON: ref(null),
      tabs: [
        { name: '审核中' },
        { name: '审核完成' },
        { name: '审核拒绝' }
      ]
    })
    watch(
      () => state.currentTabIndex,
      async () => {
        state.allTeacherApply = await new AV.Query('GYJDTeacherApply').equalTo('status', state.currentTabIndex).include('selectedSchool').descending('createdAt').find()
        state.allTeacherApplyJSON = Util._jsonify(state.allTeacherApply)
      }
    )
    onMounted(async () => {
      const student = await new AV.Query('GYJDStudent')
        .equalTo('user', AV.User.current())
        .equalTo('default', true)
        .first()

      const admin = await new AV.Query('GYJDAdmin').equalTo('mobilePhone', student.get('mobilePhone')).first()
      console.log(admin)
      if (!admin) {
        router.push({ name: 'Home' })
      }
      state.currentTabIndex = 0
    //   state.allTeacherApplyInProcess = await new AV.Query('GYJDTeacherApply').equalTo('status', 0).descending('createdAt').find()
    //   state.allTeacherApplyInProcessJSON = Util._jsonify(state.allTeacherApplyInProcess)
    })
    const changeTab = async (index) => {
      state.currentTabIndex = index
    }
    const apply = async (flag, record) => {
      const teacherApply = state.allTeacherApply.find(temp => {
        return record.objectId === temp.id
      })
      if (!flag) {
        // 不通过

        teacherApply.set('status', 2)
        await teacherApply.save()
      } else {
        // 创建这个教师信息
        let gyjdSchool = await new AV.Query('GYJDSchool').equalTo('city', record.selectedSchool.city).equalTo('district', record.selectedSchool.district).equalTo('name', record.selectedSchool.schoolName).first()
        if (!gyjdSchool) {
          gyjdSchool = new AV.Object('GYJDSchool')
          gyjdSchool.set('city', record.selectedSchool.city)
          gyjdSchool.set('province', record.selectedSchool.province)
          gyjdSchool.set('district', record.selectedSchool.district)
          gyjdSchool.set('schoolAddress', record.selectedSchool.schoolAddress)
          gyjdSchool.set('schoolLatitudeAndLongitude', record.selectedSchool.schoolLatitudeAndLongitude)
          gyjdSchool.set('name', record.selectedSchool.schoolName)
          gyjdSchool.set('classification', record.selectedSchool.schoolType)
          await gyjdSchool.save()
        }

        const teacher = new AV.Object('GYJDTeacher')
        teacher.set('mobilePhone', record.mobilePhone)
        teacher.set('name', record.teacherName)
        teacher.set('school', gyjdSchool)
        teacher.set('validated', true)
        await teacher.save()
        _.remove(state.allTeacherApply, function (a) {
          return a.id === record.id
        })
        state.allTeacherApplyJSON = Util._jsonify(state.allTeacherApply)
        teacherApply.set('status', 1)
        await teacherApply.save()
        AV.Cloud.run('sendSMSForTeacherApply', {
          name: record.teacherName,
          phone: record.mobilePhone
        })
        HLDialog({
          content: '添加教师成功'
        })
      }
    }
    return {
      changeTab,
      apply,
      ...toRefs(state)

    }
  }
})
</script>
<style scoped>

</style>
