<template>
<HLLearningDataDetail v-if="student && gradeId" :student="student" :gradeId="gradeId"/>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import AV, { Query } from 'leancloud-storage'
import DayJS from 'dayjs'
import Util from '@/utils/util.js'
import HLLearningDataDetail from '@/views/learningData/components/detail.vue'
import router from '@/router'

export default {
  components: { HLLearningDataDetail },

  setup (props, { emit }) {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    if (!route.params.gradeId && !store.state.temporarilyAnswerData) {
      router.push({ name: 'LearningData' })
    }

    const state = reactive({
      allTestPaper: [],
      allGradeDoneCount: [],
      student: ref(null),
      gradeId: ref(null)
    })

    if (route.params.gradeId) {
      state.gradeId = route.params.gradeId
    }
    // console.log(store.state.temporarilyAnswerData[0].data[0].get('grade').id)
    // console.log(store.state.temporarilyAnswerData)
    if (store.state.temporarilyAnswerData && store.state.temporarilyAnswerData.length > 0) {
      state.gradeId = store.state.temporarilyAnswerData[0].data[0].get('grade').id
    }
    onMounted(async () => {
      if (!store.state.student) {
        const student = await new AV.Query('GYJDStudent')
          .equalTo('user', AV.User.current())
          .include('group')
          .include('school')
          .include('teacher')
          .include('user')
          .equalTo('default', true)
          .first()

        state.student = student
      } else {
        state.student = store.state.student
      }
    })

    return {

      ...toRefs(state)
    }
  }
}
</script>
