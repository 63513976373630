<template>
  <div
    v-if="!showMain"
    class=" min-h-screen bg-gray-100 pb-20 h-full flex flex-row justify-center items-center "
  >

    <div
      class="w-11/12 h-5/6 flex flex-row "
      style=" background: #FFFFFF;border: 1px solid #F8F8F8;box-shadow: 0px 3px 39px rgba(195, 195, 195, 0.16);"
    >
      <div class="flex-1 justify-center items-center flex flex-col">
        <div class="text-xl text-indigo-600">老师带你读绘本</div>
        <hr class="my-6 border-indigo-700 w-1/5" />
        <div>活动规则：</div>
        <div class="my-5 ml-40 mr-40 text-gray-500">
          需按照任务列表依次解锁四个活动任务
        </div>
        <div class=" ml-40 mr-40 text-gray-500">
          活动解锁后，可重复完成
        </div>
        <button
          @click="doStart"
          class="my-10 btn btn-primary pr-40 pl-40"
        >立即开始</button>
      </div>
      <div class="flex-1  justify-center items-center flex">
        <img
          src="@/assets/images/activity_bg.png"
          class="w-11/12 h-11/12"
        >
      </div>

    </div>

  </div>

  <div
    v-if="showMain"

    class=" min-h-screen bg-gray-100 pb-20 h-full flex flex-row justify-center items-center "
  >
    <div
      v-if="period<1"
      class="w-11/12 h-5/6 flex flex-col justify-center items-center"
      style=" background: #FFFFFF;border: 1px solid #F8F8F8;box-shadow: 0px 3px 39px rgba(195, 195, 195, 0.16);"
    >
      <button
        class="btn btn-primary w-80"
        role="button"
        aria-pressed="true"
        @click="doMain(1)"
      >进入第一期</button>
      <button
        class="btn  btn-primary  w-80 mt-10"
        role="button"
        aria-pressed="true"
        @click="doMain(2)"
      >进入第二期</button>
    </div>

    <ActivityMain
      v-else
      :period="period"
    />

  </div>

</template>

<script>
import { reactive, toRefs } from 'vue'

import ActivityMain from '@/views/activity/main.vue'
// import Confirm from '@/components/confirm.js'
// import Dialog from '@/components/dialog.js'
// import SuccessDialog from '@/components/successDialog.js'
// import ErrorDialog from '@/components/errorDialog.js'
export default {
  components: {
    ActivityMain
  },
  setup () {
    const state = reactive({
      showMain: false,
      currentStepIndex: 3,
      period: -1,
      readingVideoJSON: {}

    })

    const doStart = async () => {
      state.showMain = true
    }
    const doMain = async (period) => {
      state.period = period
    }
    return {
      doStart,
      doMain,
      ...toRefs(state)
    }
  }
}
</script>
<style scoped>
/* a:hover {
  color: rgba(99, 102, 241, var(--tw-text-opacity));
} */
</style>
