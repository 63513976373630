<template>
  <section class="w-full h-screen bg-green-500 flex  justify-center items-center p-10">
    <div class="w-full h-full bg-white  rounded-3xl flex flex-row  p-10   items-center ">
        <div class="w-3/4  flex flex-col items-center  justify-center">
      <div class="text-center mb-10 text-lg">申请表单</div>
      <div class="flex flex-row  items-center w-3/4  my-5">

        <div class="">*学校名称:</div>
        <div class=" ml-10   w-3/4">

          <div class="relative">
            <input
              type="text"
              placeholder="学校名称"
              v-model="schoolName"
              id="schoolName"
              name="schoolName"
              class="w-full pr-16 input input-primary input-bordered"
            />
            <button @click="searchSchool" class="absolute top-0 right-0 rounded-l-none btn btn-primary">搜索</button>
          </div>

        </div>
      </div>
        <div class="">输入学校名字，点击搜索。选择正确的学校信息。</div>

      <div class="flex flex-row  items-center  w-3/4  my-5">

        <div class="">*教师姓名:</div>
        <div class="ml-10   w-3/4">

            <input
            :disabled="!selectedSchool"
              type="text"
              placeholder="教师姓名"
              v-model="teacherName"
              id="teacherName"
              name="teacherName"
              class="w-full pr-16 input input-primary input-bordered "
            />

        </div>
      </div>

      <div class="flex flex-row  items-center  w-3/4  my-5">

        <div class="">*手机号码:</div>
        <div class=" ml-10  w-3/4">

            <input
        :disabled="!selectedSchool"
              type="text"
              placeholder="手机号码"
               id="mobilePhone"
              name="mobilePhone"
              v-model="mobilePhone"
              class="w-full pr-16 input input-primary input-bordered"
            />

        </div>
      </div>

        <button @click="submit"  :disabled="!selectedSchool || !teacherName || !mobilePhone" class="btn btn-warning w-48 mt-16">提交申请</button>
        <div class="mt-6">*审核通过后，将会收到短信提醒。使用手机号码和密码登录. </div>
    </div>
       <div class="w-1/4 bg-gray-50 p-3 rounded-lg h-full overflow-y-auto  z-50">
               <RadioGroup v-if="allNearbySchoolJSON && allNearbySchoolJSON.length>0" v-model="selectedSchool">
        <RadioGroupLabel ><span class="text-lg">请选择一个学校：</span></RadioGroupLabel>
        <div class="space-y-5 mt-10">
          <RadioGroupOption
            as="template"
            v-for="school in allNearbySchoolJSON"
            :key="school.schoolName"
            :value="school"
            v-slot="{ active, checked }"
          >
            <div
              :class="[
                active
                  ? 'ring-2 ring-offset-2 ring-offset-sky-300 ring-white ring-opacity-60'
                  : '',
                checked ? 'bg-sky-900 bg-opacity-75 text-white ' : 'bg-white ',
              ]"
              class="relative flex px-5 py-4 rounded-lg shadow-md cursor-pointer focus:outline-none"
            >
              <div class="flex items-center justify-between w-full">
                <div class="flex items-center">
                  <div class="text-sm">
                    <RadioGroupLabel
                      as="p"
                      :class="checked ? 'text-white' : 'text-gray-900'"
                      class=" font-extralight text-base"
                    >
                      {{ school.schoolName }}
                    </RadioGroupLabel>
                    <RadioGroupDescription
                      as="span"
                      :class="checked ? 'text-sky-100' : 'text-gray-500'"
                      class="inline"
                    >
                      <span> {{ school.city }}/{{ school.district }}</span>
                      <span aria-hidden="true"> &middot; </span>
                      <span>{{ school.schoolAddress }}</span>
                    </RadioGroupDescription>
                  </div>
                </div>
                <div v-show="checked" class="flex-shrink-0 text-white">
                  <svg class="w-6 h-6" viewBox="0 0 24 24" fill="none">
                    <circle
                      cx="12"
                      cy="12"
                      r="12"
                      fill="#fff"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M7 13l3 3 7-7"
                      stroke="#fff"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </RadioGroupOption>
        </div>
      </RadioGroup>
      <div v-if="allNearbySchoolJSON && allNearbySchoolJSON.length==0" class="text-red-400 text-lg items-center justify-center w-full h-full">
          <div>未搜到该学校</div>
      </div>
    </div>
    </div>

  </section>
</template>
<script>
import { defineComponent, reactive, toRefs, ref, watch } from 'vue'
import AV from 'leancloud-storage'
import { useRouter } from 'vue-router'
import _ from 'lodash'
import Util from '@/utils/util.js'
import HLDialog from '@/components/dialog.js'
import {
  RadioGroup,
  RadioGroupLabel,
  RadioGroupDescription,
  RadioGroupOption
} from '@headlessui/vue'

export default defineComponent({
  components: {
    RadioGroup,
    RadioGroupLabel,
    RadioGroupDescription,
    RadioGroupOption
  },
  setup () {
    const state = reactive({
      schoolName: ref(null),
      selectedSchool: ref(null),
      teacherName: ref(null),
      mobilePhone: ref(null),
      allNearbySchoolJSON: ref(null)

    })

    watch(
      () => state.selectedSchool,
      () => {
        if (state.selectedSchool) {
          state.schoolName = state.selectedSchool.schoolName
        }
      }
    )

    watch(
      () => state.schoolName,
      () => {
        if (!state.schoolName || state.schoolName.length < 2) {
          state.selectedSchool = ref(null)
        }
      }
    )

    const searchSchool = async () => {
      if (state.schoolName && state.schoolName.length > 2) {
        const allNearbySchool = await new AV.Query('NationwideSchool').contains('schoolName', state.schoolName).limit(1000).find()
        console.log(allNearbySchool)
        state.allNearbySchool = allNearbySchool
        state.allNearbySchoolJSON = Util._jsonify(allNearbySchool)
        if (state.allNearbySchoolJSON.length > 0) {
        //   state.selectedSchool = state.allNearbySchoolJSON[0]
        } else {
          state.selectedSchool = ref(null)
        }
      } else {
        HLDialog({
          content: '请输入正确的学校名字'
        })
      }
    }
    const submit = async () => {
      if (!state.selectedSchool) {
        HLDialog({
          content: '请选择学校'
        })
        return
      }
      if (!state.teacherName) {
        HLDialog({
          content: '请输如姓名'
        })
        return
      }
      if (!state.mobilePhone) {
        HLDialog({
          content: '请输入手机号码'
        })
        return
      }

      if (!/^1[3|4|5|6|7|8|9][0-9]{9}$/.test(state.mobilePhone)) {
        HLDialog({
          content: '手机号码格式不正确'
        })
        return
      }

      const hasExist = await new AV.Query('GYJDTeacherApply').equalTo('mobilePhone', state.mobilePhone).count() > 0
      if (hasExist) {
        HLDialog({
          content: '该手机号码已申请,请勿重复申请.'
        })
      } else {
        const teacherApply = new AV.Object('GYJDTeacherApply')
        teacherApply.set('mobilePhone', state.mobilePhone)
        teacherApply.set('teacherName', state.teacherName)
        teacherApply.set('status', 0)
        teacherApply.set('selectedSchool', state.allNearbySchool.find(temp => {
          return state.selectedSchool.objectId === temp.id
        }))
        await teacherApply.save()
        HLDialog({
          content: '审核结果将在5个工作日内，以短信方式通知您。'
        })
      }
    }
    return {
      submit,
      searchSchool,
      ...toRefs(state)

    }
  }
})
</script>
<style scoped>
input.disabled {
  pointer-events: none;
  cursor: default;
border-color: gray;
}
</style>
