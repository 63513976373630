import AV from 'leancloud-storage'
/**
 * 等级表
 */
class Grade extends AV.Object {
  get indexInt () {
    return this.get('indexInt')
  }

  set indexInt (value) {
    this.set('indexInt', value)
  }

  /**
   * 名称
   */
  get name () {
    return this.get('name')
  }

  /**
   * 名称
   */
  set name (value) {
    this.set('name', value)
  }

  /**
   * 类型,基础课堂是1，进阶课堂是2，精英课堂是3
   */
  get series () {
    return this.get('series')
  }

  /**
   * 类型
   */
  set series (value) {
    this.set('series', value)
  }

  /** 课程描述:启蒙 基础 进阶 精英 */
  get description () {
    return this.get('description')
  }

  /** 课程描述:启蒙 基础 进阶 精英 */
  set description (value) {
    this.set('description', value)
  }

  /** 课程导言 */
  get intro () {
    return this.get('intro')
  }

  /** 课程导言 */
  set intro (value) {
    this.set('intro', value)
  }

  /**
   * 哪个等级
   */
  get level () {
    return this.get('level')
  }

  /**
   * 哪个等级
   */
  set level (value) {
    this.set('level', value)
  }

  // 以下为新增

  /**
   * 展示在前端的icon
   */
  get showIcon () {
    return this.get('showIcon')
  }

  /**
   * 展示在前端的icon
   */
  set showIcon (value) {
    this.set('showIcon', value)
  }

  /**
  * 展示在前端的中文名
  */
  get showName () {
    return this.get('showName')
  }

  /**
   * 展示在前端的中文名
   */
  set showName (value) {
    this.set('showName', value)
  }

  /**
     * 展示在前端的英文名
     */
  get showEName () {
    return this.get('showEName')
  }

  /**
   * 展示在前端的英文名
   */
  set showEName (value) {
    this.set('showEName', value)
  }
}
AV.Object.register(Grade, 'GYJDGrade')
export default Grade
