import AV from "leancloud-storage";
/**
 * 一个等级内有哪些题型(比如，level 1 的基础等级)
 */
class QuestionTypeInGrade extends AV.Object {
   
 
  get questionType() {
    return this.get('questionType');
  }

  set questionType(value) {
    this.set('questionType', value);
  }
 
  get grade() {
    return this.get('grade');
  }
 
  set grade(value) {
    this.set('grade', value);
  }

  get anyDay() {
    return this.get('anyDay');
  }
 
  set anyDay(value) {
    this.set('anyDay', value);
  }

  get validated() {
    return this.get('validated');
  }
 
  set validated(value) {
    this.set('validated', value);
  }
  
  

}
AV.Object.register(QuestionTypeInGrade, 'GYJDQuestionTypeInGrade');
export default QuestionTypeInGrade; 