<template>
  <div
  style="background:#F4F6F1"
    class="w-full min-h-screen  py-4  flex flex-row justify-center items-center "
  >
     <HLGradeList :series="series" :operationType="operationType" @toDoAnswer="toDoAnswer"/>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import AV from 'leancloud-storage'
import HLGradeList from '@/views/home/gradeList.vue'
import DayJS from 'dayjs'

export default {
  components: {
    HLGradeList
  },
  setup () {
    const store = useStore()
    const state = reactive({
      series: ref(3),
      operationType: ref(3)
    })

    return {

      ...toRefs(state)
    }
  }
}
</script>
<style scoped>
/* a:hover {
  color: rgba(99, 102, 241, var(--tw-text-opacity));
} */
</style>
