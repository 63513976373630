<template>
  <div class="flex flex-row p-10">
    <!-- 题干-->
    <div class="w-1/5 flex flex-col justify-center items-center">
      <HLVoice
        :url="question.questionData[0][0].voice.url"
        :stop="requesting"
        @onEnd="onQuestionStemVoiceEnd"
        @onPlay="onQuestionStemVoicePlay"
      />
      <img
       :src="question.questionData[0][0].image.url+'?imageView2/2/500' "
        class="mt-10 h-40"
      >
    </div>

    <div class="w-3/5 flex flex-col  justify-center items-center">

      <div
        class="flex-3 flex-row flex justify-center items-center w-full "
        v-for="(choice,index) in question.questionData[1]"
        :key="choice"
      >
        <button
          v-if="currentShowVoiceButtonIndex>=index"
          class="btn btn-outline min-w-max w-80 mt-10 btn-primary  "
          @click="toChoiceWord(choice,index)"
          :disabled="requesting && choiceIndex!=index"
        ><span class="mr-3">{{index==0?'A':index==1?'B':index==2?'C':'D'}}.</span><span class="lowercase ">{{choice.intro}}</span></button>
        <HLVoiceButton

          class="mt-10"
          v-if="currentShowVoiceButtonIndex>=index"
          :url="choice.voice.url"
          :text="voiceButtonText"
          :stop="requesting"
          :data="index"
          @onStop="onVoiceButtonEnd"
        />
      </div>

    </div>
    <div class="w-1/5 flex  justify-center  items-center">
      <HLLottiePlayer
        v-if="requesting && correct"
        name="resultAnimation"
        loop
        :animationData="require('@/assets/lotties/great.json')"
      />
      <HLLottiePlayer
        v-if="requesting && !correct"
        name="resultAnimation"
        loop
        :animationData="require('@/assets/lotties/error.json')"
      />

    </div>
  </div>

</template>
<script>
import { reactive, toRefs, defineComponent } from 'vue'
import HLVoice from '@/views/components/common/voice.vue'
import HLVoiceButton from '@/views/components/common/voiceButton.vue'
// import { EmojiHappyIcon, EmojiSadIcon } from '@heroicons/vue/outline'
import VueLottiePlayer from 'vue-lottie-player'
import _ from 'lodash'
export default defineComponent({
  components: {
    HLVoice,
    HLVoiceButton,
    HLLottiePlayer: VueLottiePlayer
  },
  name: 'HL-tinglixuantingli',
  props: ['question'],
  emits: ['setAnswerData'],
  setup (props, { emit }) {
    const state = reactive({
      question: props.question,
      seconds: 1,
      correct: false,
      voiceButtonText: '',
      requesting: false,
      choiceIndex: -1,
      choiceShowComplete: false,
      currentShowVoiceButtonIndex: -1,
      timerInterval: setInterval(function () {
        state.seconds += 1
      }, 1000)

    })
    const toChoiceWord = async (choice, index) => {
      if (!state.requesting) {
        const resultData = {}
        state.choiceIndex = index
        resultData.correct = choice.sn === state.question.answerSelection[0].sn
        resultData.userChoice = choice
        resultData.score = resultData.correct ? 100 : 0
        resultData.useSeconds = state.seconds
        state.timerInterval && clearInterval(state.timerInterval)
        state.requesting = true
        state.correct = resultData.correct
        emit('setAnswerData', resultData)
      }
    }
    const onVoiceButtonEnd = async (index) => {
      _.delay(function () {
        if (state.currentShowVoiceButtonIndex === state.question.questionData[1].length - 1) {
          state.choiceShowComplete = true
        }

        if (!state.choiceShowComplete) {
          state.currentShowVoiceButtonIndex += 1
        }
      }, 500, '')
    }
    const onQuestionStemVoiceEnd = async () => {
      _.delay(function () {
        state.currentShowVoiceButtonIndex = 0
        state.choiceShowComplete = false
      }, 500, '')
    }
    const onQuestionStemVoicePlay = async () => {
      state.currentShowVoiceButtonIndex = -1
      state.choiceShowComplete = false
    }
    return {
      onQuestionStemVoiceEnd,
      onQuestionStemVoicePlay,
      onVoiceButtonEnd,
      toChoiceWord,
      ...toRefs(state)
    }
  }
})

</script>
