<template>
  <div
  style="background:#F4F6F1"
    class="w-full min-h-screen   pt-4  flex flex-row justify-center items-center "
  >

    <!-- 列表页面 -->
    <div
      v-if="allTestPaper && allTestPaper.length>0"
      class="w-11/12 h-full mx-auto bg-white   rounded-2xl"
    >
  <div v-if="other" class="text-center w-full m-4 text-lg">{{studentJSON.name?studentJSON.name:studentJSON.nickname}} 同学的作答记录</div>
      <ul class="w-5/6 mx-auto pb-10 ">
        <li
           @click="toDetail(training.id)"
          v-for="(training,index) in allTestPaper"
          :key="training.id"
          style="box-shadow: 0px 3px 20px rgba(159, 159, 159, 0.16);"
          class="cursor-pointer flex flex-row  justify-between items-center h-20 w-50 rounded-lg pl-6 pr-10  mt-4"
        >
          <div class="flex flex-row justify-center items-center">
            <img src="@/assets/images/test_paper.png" alt="" class="w-5 h-6" />
            <div class="ml-4">{{ training.name }}</div>
          </div>
          <div class="flex flex-row items-center">
          <div class="text-rose-500">已答{{allGradeDoneCount[index]}}次</div>
              <div
            class="bg-gyjdGreen-600 text-white rounded-lg pt-2 pb-2 pl-6 pr-6  ml-10"

          >

           查看详情
          </div>
          </div>

        </li>
      </ul>
    </div>
    <!-- 加载提示页面 -->
  <div v-else>

    <button class="btn loading">数据加载中...</button>
  </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, defineComponent } from 'vue'
import { useStore } from 'vuex'
import AV, { Query } from 'leancloud-storage'
import DayJS from 'dayjs'
import Util from '@/utils/util.js'

import router from '@/router'

export default defineComponent({
  props: ['student', 'other'],
  name: 'HL-learning-data',

  setup (props, { emit }) {
    const store = useStore()
    const state = reactive({
      allTestPaper: [],
      studentJSON: Util._jsonify(props.student),
      allGradeDoneCount: []

    })
    console.log(props.other)
    onMounted(async () => {
      // 非常重要

      state.allTestPaper = await new AV.Query('GYJDGrade')
        .equalTo('series', 3)
        .descending('indexInt')
        .find()
      const allGradeDoneCount = []
      for (let i = 0; i < state.allTestPaper.length; i++) {
        const gradeDoneCount = await new AV.Query('GYJDEveryGradeStatistics').equalTo('channel', 'web').equalTo('student', props.student).equalTo('grade', state.allTestPaper[i]).count()
        allGradeDoneCount.push(gradeDoneCount)
      }
      state.allGradeDoneCount = allGradeDoneCount
    })

    const toDetail = (gradeId) => {
      if (props.student.id === store.state.student.id) {
        router.push({ name: 'LearningDataDetail', params: { gradeId } })
      } else {
        router.push({ name: 'StudentLearningDataDetail', params: { gradeId, studentId: props.student.id } })
      }
    }

    return {

      toDetail,
      ...toRefs(state)
    }
  }
})
</script>
