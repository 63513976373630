<template>
  <div
    style="font-size:14px; "
    class="flex flex-col items-center w-full"
  >
    <!-- 倒计时区域-->
    <div
      style="height:40px;background: #FFE7C1;border-radius: 4px;margin-bottom:10px;"
      class="w-1/2 mt-4 flex flex-row  justify-center items-center"
    >
      <svg
        class="w-6 h-6"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
        ></path>
      </svg>
      <div style="font-weight: 900;color: black;font-size: 14px;letter-spacing: 1px;margin-left:18px;">{{systemOperationTip}}
        <HLVoiceGroup
          v-if="systemOperationType==0"
          @voicePlayEnd="questionItemVoiceEnd"
          :showLottie="false"
          :autoPlay="false"
          :allUrls="allUrls"
          :times="2"
          :order="index"
        >
        </HLVoiceGroup>
        <!-- 播放滴一声，进入下一题-->
        <HLVoiceGroup
          v-if="systemOperationType==3"
          :times="1"
          @voicePlayEnd="toNextQuestion"
          :showLottie="false"
          :autoPlay="false"
          :allUrls="['https://file.nj.hlingsoft.com/JMad9VoFnKXQiSloiTOAGXMGVy2538tk/beep.mp3']"
        >
        </HLVoiceGroup>

      </div>
      <div
        v-if="systemOperationSeconds>0"
        style="font-weight: 900;font-size: 14px;letter-spacing: 1px;margin-left:18px;"
      >
        {{systemOperationSeconds}}</div>
    </div>

    <div class="flex flex-col  justify-center  pb-40   w-3/4 pl-5">

      <div
        v-if="allQuestionInThisGroup[0].indexInOneDay==12.5"
        class="w-full"
        style="border:1px solid;"
      >
        <div
          style="background:#e6e7e9;font-weight:700;font-size:14px;letter-spacing:0rpx;height:33px;border-bottom:1px solid;"
          class="flex flex-col justify-center items-center"
        >
          {{allQuestionInThisGroup[0].questionData[0][0].intro}}</div>
        <div
          style="font-size:12px;padding: 3px;"
          class="flex flex-col"
          v-for="(item,index) in allQuestionInThisGroup[0].questionData[0]"
          :key="item.sn"
        >
          <div

            v-if="index!=0 && index!=(allQuestionInThisGroup[0].questionData[0].length - 1)"
            class="flex flex-row w-full"
          >
            <div class="w-full pb-2 "
             :style="[index==allQuestionInThisGroup[0].questionData[0].length-2?'':'border-bottom:1px solid;','font-size:14px;letter-spacing:0px;color:#181818;font-weight: 400;padding-left:10px;']"
            >

                 •{{item.intro.replaceAll('</u>','').split('<u>')[0]}}<u class="ml-2  ">{{(item.intro.replaceAll('</u>','').split('<u>')[1])}}</u>
            </div>
          </div>
        </div>
      </div>

      <div
        v-for="(question,questionIndex) in allQuestion"
        :key="question.objectId"
      >
        <div
          class="flex flex-row mt-5 "
          style="font-size: 15px;color: #11152B;font-weight: 700;"
        >
          {{question.indexInOneDay}}. {{question.questionData[0][0].intro}}

        </div>

        <div class="flex flex-row aminated fadeIn  ">

          <div class="flex  flex-col mt-5">
            <div
              @click="toChoiceWord(choice,index,questionIndex)"
              v-for="(choice,index) in question.questionData[1]"
              :key="choice.sn"
              :class="[choiceIndex[questionIndex] ==index ?'xuanzhon2 text-white':'text-black','flex flex-row cursor-pointer mt-5 w-full p-3 pr-20 ']"
            >
              <div
                :class="[choiceIndex[questionIndex]==index?'xuanzhon':'weixuanzhon']"
                style="width:20px;height:20px;border-radius: 50%;"
              ></div>
              <div style="margin-left:20px;font-weight:400;width:30px;">{{index==0?'A':index==1?'B':index==2?'C':'D'}}</div>

              <div
                class="flex"
                style=" justify-content:start;margin-left:30px;text-align:left;font-weight:400;letter-spacing: 0px;"
              >
                <div>{{choice.intro}}</div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script  >
import { reactive, toRefs, defineComponent, onBeforeUnmount } from 'vue'
import HLVoiceGroup from '@/views/components/common/voiceGroup.vue'

export default defineComponent({
  components: {
    HLVoiceGroup

  },
  name: 'HL-tingduihua',
  props: ['question', 'allQuestion'],
  emits: ['setAnswerData', 'toNextQuestion'],
  setup (props, { emit }) {
    // eslint-disable-next-line vue/no-setup-props-destructure
    const groupQuestion = props.question.groupQuestion

    const allQuestionInThisGroup = props.allQuestion.filter(q => {
      return q.groupQuestion && q.groupQuestion.objectId === groupQuestion.objectId
    })
    allQuestionInThisGroup.sort(function (a, b) {
      return a.indexInOneDay - b.indexInOneDay
    })

    const allUrls = []
    allQuestionInThisGroup[0].questionData[0].map(obj => {
      if (obj.voice && obj.voice.url && obj.voice.url.length > 0) {
        allUrls.push(obj.voice.url)
      }
    })
    const state = reactive({
      index: props.question.indexInOneDay - 1,
      allQuestionInThisGroup,
      allUrls,
      allQuestion: allQuestionInThisGroup.filter((q, i) => {
        return i > 0
      }),
      seconds: 1,
      correct: false,
      currentQuestionIndex: 0,
      systemOperationSeconds: (allQuestionInThisGroup.length - 1) * 5,
      systemOperationTimer: setInterval(function () {
        state.systemOperationSeconds--

        if (state.systemOperationSeconds === 0) {
          state.systemOperationTimer && clearInterval(state.systemOperationTimer)
          state.systemOperationType = 0
          state.systemOperationTip = '正在播放题目音频'
        }
      }, 1000),
      systemOperationTip: '阅读题目倒计时',
      systemOperationType: 1, // 0代表播放音频、1代表阅读题目、2代表回答倒计时、3代表播放滴，需要进入下一题
      requesting: false,
      choiceIndex: [],
      timerInterval: setInterval(function () {
        state.seconds += 1
      }, 1000)

    })
    state.allQuestion.map(q => {
      state.choiceIndex.push(-1)
    })

    const questionItemVoiceEnd = async () => {
      state.systemOperationTimer && clearInterval(state.systemOperationTimer)
      state.systemOperationType = 2
      state.systemOperationSeconds = state.allQuestion.length * 5
      state.systemOperationTip = '答题倒计时'

      state.systemOperationTimer = setInterval(function () {
        state.systemOperationSeconds--
        // console.log(state.systemOperationSeconds)
        if (state.systemOperationSeconds === 0) {
          // 答题倒计时结束了
          state.systemOperationTimer && clearInterval(state.systemOperationTimer)
          // 跳转到下一题

          state.systemOperationType = 3
          state.systemOperationTip = '准备进入下一题'
        }
      }, 1000)
    }

    const toChoiceWord = async (choice, index, questionIndex) => {
      //   if (!state.requesting) {
      const resultData = {}
      state.choiceIndex[questionIndex] = index
      state.question = state.allQuestion[questionIndex]
      resultData.correct = choice.sn === state.question.answerSelection[0].sn
      resultData.userChoice = choice
      resultData.score = resultData.correct ? 100 : 0
      resultData.useSeconds = state.seconds
      resultData.question = state.question
      state.timerInterval && clearInterval(state.timerInterval)
      state.requesting = true

      state.correct = resultData.correct
      console.log(resultData)
      emit('setAnswerData', resultData)
      //   }
    }
    const toNextQuestion = () => {
      state.timerInterval && clearInterval(state.timerInterval)
      state.systemOperationTimer && clearInterval(state.systemOperationTimer)

      setTimeout(function () {
        emit('toNextQuestion', {})
      }, 500)
    }
    onBeforeUnmount(async () => {
      // 销毁定时器
      state.timerInterval && clearInterval(state.timerInterval)
      state.systemOperationTimer && clearInterval(state.systemOperationTimer)
      // 销毁动画
      state.bgAnimation && state.bgAnimation.destroy()
    })
    return {
      toChoiceWord,
      questionItemVoiceEnd,
      toNextQuestion,
      ...toRefs(state)
    }
  }
})

</script>
