<template>

  <div class="font-HLFont ">
    <input type="checkbox" id="hl-confirm-modal" class="modal-toggle" checked>
    <div class="modal">
      <div class="modal-box flex flex-col  items-center " style="width:600px" >
        <p class="text-lg ">修改密码</p>

      <div class="flex flex-row  items-center    my-5">

        <div class="w-28">*新密码:</div>
        <div class="form-control ml-10  ">

            <input
             type="password"
              placeholder="请输入密码"
              v-model="password"
                 minlength="6"
                  required
              class="w-full pr-16 input input-primary input-bordered"
            >

        </div>
      </div>
      <div class="flex flex-row  items-center   my-5">

        <div class="w-28">*重复新密码:</div>
        <div class="form-control ml-10  ">

            <input
             type="password"
              placeholder="请输入密码"
              v-model="secondPassword"
              minlength="6"
              required
              class="w-full pr-16 input input-primary input-bordered"
            >

        </div>
      </div>

        <div class="modal-action mt-5 ">
          <button  @click="changePassword" for="hl-confirm-modal" class="w-40 btn bg-gyjdGreen-500 border-gyjdGreen-500 hover:bg-gyjdGreen-600 hover:border-gyjdGreen-600 mx-4" :disabled="password!=secondPassword" >确定</button>
          <button @click="close" for="hl-confirm-modal" class="btn btn-warning w-40  mx-4" >取消</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script  >
import { defineComponent, reactive, ref, toRefs } from 'vue'
import HLDialog from '@/components/dialog.js'
export default defineComponent({
  components: {

  },
  name: 'HL-change-password',
  props: ['student'],
  emits: ['changePasswordDone'],
  setup (props, { emit }) {
    const state = reactive({
      password: ref(null),
      secondPassword: ref(null),
      student: props.student
    })
    const close = () => {
      emit('changePasswordDone')
    }

    const changePassword = async () => {
      if (!state.password || state.password.length === 0) {
        HLDialog({
          content: '请输入密码'
        })
        return
      }
      if (state.password.length < 6) {
        HLDialog({
          content: '密码不少于6位'
        })
        return
      }

      state.student.set('password', state.password)
      await state.student.save()
      close()
    }
    return {
      changePassword,
      close,
      ...toRefs(state)

    }
  }
})
</script>
