<template>

  <div class="p-10 w-full min-h-full flex flex-col items-center ">
    <div class="text-xl">稍等一下,检查音量和麦克风是否打开</div>
    <hr class="my-8 border-gray-200 w-full" />
    <button class="btn  btn-outline btn-primary w-60  mt-10">
      <HLVoiceButton
        :url="testMp3Url"
        :text="testText"
      />
    </button>
    <div class="mt-5 text-gray-400 text-sm">如果没有声音,请检查是否调整了音量</div>
    <hr class="my-8 border-orange-300 w-1/2" />
    <button
      v-if="!checked"
      @click="openRecordAuth"
      :class="[isChecking?'loading':'','btn btn-outline btn-error w-60']"
    >点击授权录音权限</button>
    <div v-if="checked">
      <div
        v-if=" error"
        class="flex flex-col min-h-full justify-center items-center mt-10"
      >
        <div class="cursor-pointer text-red-600 text-lg flex flex-row  justify-center items-center underline">
          <a
            target="_blank"
            href="https://file.nj.hlingsoft.com/unF8MI3WAxekNbA5uzQOi1xBfLWRfevW/%E9%BA%A6%E5%85%8B%E9%A3%8E%E6%8E%88%E6%9D%83%E8%A7%A3%E5%86%B3%E6%96%B9%E6%B3%95.pdf
"
          > {{error}}(查看解决办法)</a>

        </div>
        <button
          class="btn btn-error w-40 mt-10"
          @click="reload"
        >刷新</button>

      </div>
      <div
        v-else
        class=""
      >
        <button
          @click="checkDone"
          class="btn w-60  btn-outline btn-primary "
        >
          录音权限已打开, 立即进入答题
        </button>
        <Loading
          v-if="requesting"
          :content="'正在加载题目...'"
        />

      </div>
    </div>
  </div>

</template>

<script>
import { reactive, toRefs, onMounted, ref, onBeforeUnmount, defineComponent } from 'vue'
import { useStore } from 'vuex'
import Recorder from 'recorder-core/recorder.mp3.min'
import _ from 'lodash'

import HLVoiceButton from '@/views/components/common/voiceButton.vue'
import Loading from '@/components/Loading.vue'
export default defineComponent({
  components: {
    HLVoiceButton,
    Loading
  },
  name: 'HL-device-check',
  emits: ['checkDone'],
  setup (props, { emit }) {
    const store = useStore()
    const state = reactive({
      error: ref(null),
      isChecking: ref(null),
      checked: ref(null),
      testText: '点击播放语音',
      testMp3Url: 'https://file.nj.hlingsoft.com/4ae99ea31614c2740095.mp3',
      recorder: ref(null),
      requesting: ref(null)
    })
    onMounted(async () => {
      state.recorder = Recorder({
        type: 'mp3',
        sampleRate: 16000,
        bitRate: 64000

      })
    })

    const openRecordAuth = async () => {
      state.isChecking = true
      state.recorder.open(function () {
        state.error = ref(null)
        state.recorder.start()
        _.delay(function () {
          state.recorder.stop()
          state.recorder.close()
          state.recorder = ref(null)
        }, 500, '')
        _.delay(function () {
          state.isChecking = false
          state.checked = true
        }, 2000, '')

        // success && success()
      }, function () { // 用户拒绝未授权或不支持
        state.error = '麦克风未授权'
        state.checked = true
      })
    }
    const reload = async () => {
      // localStorage.clear()

      location.reload()
    }

    onBeforeUnmount(async () => {
      state.recorder && state.recorder.close()
      state.recorder = ref(null)
    })
    const checkDone = () => {
      state.recorder && state.recorder.close()
      state.recorder = ref(null)
      state.requesting = true
      _.delay(function () {
        // state.requesting = false
        emit('checkDone')
      }, 1500, '')
    }
    return {
      reload,
      checkDone,
      openRecordAuth,
      ...toRefs(state)
    }
  }
})
</script>
