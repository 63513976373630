<template>
  <div class="my-2 flex flex-col justify-center items-baseline text-indigo-600 bg-gray-50 p-10 rounded-md w-full">
    <div class="my-1 w-40"><span>总分:</span><span :class="[score>=80?'text-green-500':score>=60?'text-orange-500':'text-red-500','text-3xl ml-4 italic ']  ">{{score}}</span></div>
    <div class="my-1 w-40"><span>完整度:</span><span class="text-indigo-700 text-lg ml-4 ">{{data.evaluationData.EngineResult.lines[0].integrity}}</span></div>
    <div class="my-1 w-40"><span>流利度:</span><span class="text-indigo-700 text-lg ml-4 ">{{data.evaluationData.EngineResult.lines[0].fluency}}</span></div>
    <div class="my-1 w-40"><span>发音:</span><span class="text-indigo-700 text-lg ml-4 ">{{data.evaluationData.EngineResult.lines[0].pronunciation}}</span></div>
    <div class="my-1 w-40"><span>语速:</span><span class="text-indigo-700 text-lg ml-4 ">{{data.evaluationData.EngineResult.lines[0].voiceSpeed}}</span></div>

  </div>
</template>
<script>
import { reactive, toRefs, defineComponent, onMounted, ref, watch, onBeforeUnmount } from 'vue'
export default defineComponent({

  components: {

  },
  name: 'HL-evaluation-result',
  props: ['question', 'data'],
  setup (props) {
    const state = reactive({
      score: props.data.evaluationData.EngineResult.score
    })
    return {
      ...toRefs(state)
    }
  }
})
</script>
