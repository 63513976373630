
import { createApp } from 'vue'
import DialogVue from './Dialog.vue'
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SuccessDialog ({ content, doneAndNext }) {
  return new Promise((resolve) => {
    const confirmInstance = createApp(DialogVue, {
      content: content || '',
      doneAndNext: async () => {
        unmount()
        if (doneAndNext) {
          await doneAndNext()
        }
        resolve('done')
      }
    })

    const unmount = () => {
      confirmInstance.unmount()
      document.body.removeChild(parentNode)
    }

    const parentNode = document.createElement('div')
    document.body.appendChild(parentNode)

    confirmInstance.mount(parentNode)
  })
}
export default SuccessDialog
