import AV from "leancloud-storage";
/**
 * 题目类别
 */
class QuestionType extends AV.Object {
   
  /**
   * 别名
   */
  get tips() {
    return this.get('tips');
  }
  /**
   * 别名
   */
  set tips(value) {
    this.set('tips', value);
  }
  /**
   * 类型值
   */
  get series() {
    return this.get('series');
  }
  /**
   * 类型值
   */
  set series(value) {
    this.set('series', value);
  }
 
  /**
   * 名称
   */
  get name() {
    return this.get('name');
  }
  /**
   * 名称
   */
  set name(value) {
    this.set('name', value);
  }

   
  get nameVoiceUrl() {
    return this.get('nameVoiceUrl');
  }

  set nameVoiceUrl(value) {
    this.set('nameVoiceUrl', value);
  }

  get seriesChinese() {
    return this.get('seriesChinese');
  }
 
  set seriesChinese(value) {
    this.set('seriesChinese', value);
  }


  get defaultScore() {
    return this.get('defaultScore');
  }
 
  set defaultScore(value) {
    this.set('defaultScore', value);
  }


  /**
   * icon
   */
  get showImageUrl() {
    return this.get('showImageUrl');
  }
  /**
   * icon
   */
  set showImageUrl(value) {
    this.set('showImageUrl', value);
  }

  /**
   * icon
   */
  get showImageActiveUrl() {
    return this.get('showImageActiveUrl');
  }
  /**
   * icon
   */
  set showImageActiveUrl(value) {
    this.set('showImageActiveUrl', value);
  }

  /**
   * 说明
   */
  get description() {
    return this.get('description');
  }
  /**
   * 说明
   */
  set description(value) {
    this.set('description', value);
  }

 
  get descriptionVoiceUrl() {
    return this.get('descriptionVoiceUrl');
  }

  set descriptionVoiceUrl(value) {
    this.set('descriptionVoiceUrl', value);
  }

   //是否在学习报告中展示这个题型
  get needShowInReport() {
    return this.get('needShowInReport');
  }
  
  set needShowInReport(value) {
    this.set('needShowInReport', value);
  }

  //学习报告中，是否可以查看详情
  get needShowAnswerDetail() {
    return this.get('needShowAnswerDetail');
  }
  
  set needShowAnswerDetail(value) {
    this.set('needShowAnswerDetail', value);
  }


  //答题模式，1 训练 2考试
  get answerModel() {
    return this.get('answerModel');
  }
  
  set answerModel(value) {
    this.set('answerModel', value);
  }



  //答题时长，默认为1小时
  get during() {
    return this.get('during');
  }
  
  set during(value) { 
    this.set('during', value);
  }

  //答题时长，默认为1小时
  get gradeSeries() {
    return this.get('gradeSeries');
  }
  
  set gradeSeries(value) { 
    this.set('gradeSeries', value);
  }


  get testQuestion() {
    return this.get('testQuestion');
  }
  
  set testQuestion(value) { 
    this.set('testQuestion', value);
  }




  get detailImages() {
    return this.get('detailImages');
  }
  
  set detailImages(value) { 
    this.set('detailImages', value);
  }


  get intro() {
    return this.get('intro');
  }
  
  set intro(value) { 
    this.set('intro', value);
  }

  /**
   * 是否有效
   */
  get validated() {
    return this.get('validated');
  }
  /**
   * 是否有效
   */
  set validated(value) {
    this.set('validated', value);
  }
}
AV.Object.register(QuestionType, 'GYJDQuestionType');
export default QuestionType;