<template>
  <div id="content"
  style="background:#F4F6F1"
    class="w-full min-h-screen  py-4  flex flex-row justify-center items-center "
  >

     <HLGradeList :series="series"  :operationType="operationType" />

  </div>
</template>

<script>
import { reactive, toRefs, onMounted, ref, onBeforeUpdate, onUpdated } from 'vue'
import { useStore } from 'vuex'
import AV from 'leancloud-storage'
import HLGradeList from './gradeList.vue'
import DayJS from 'dayjs'

import Util from '@/utils/util'
import Confirm from '@/components/confirm.js'
import Dialog from '@/components/dialog.js'
export default {
  components: {
    HLGradeList

  },
  setup (props, context) {
    const store = useStore()
    const state = reactive({
      series: ref(3),
      operationType: ref(2)

    })

    onMounted(async () => {
      if (!store.state.student) {
        store.state.student = await new AV.Query('GYJDStudent')
          .equalTo('user', AV.User.current())
          .include('group')
          .include('school')
          .include('teacher')
          .include('user')
          .equalTo('default', true)
          .first()
        store.state.studentJSON = Util._jsonify(store.state.student)
      }

      const studySchedule = await new AV.Query('GYJDStudySchedule').include('grade').equalTo('student', store.state.student).equalTo('validated', true).first()
      // console.log(studySchedule)
      store.state.studySchedule = studySchedule
      store.state.studyScheduleJSON = Util._jsonify(studySchedule)

      // 非常重要
      const gradesInCurrentLevel = await new AV.Query('GYJDGrade').ascending('indexInt').equalTo('level', store.state.studyScheduleJSON.grade.level).find()
      store.state.gradesInCurrentLevel = gradesInCurrentLevel
    })
    return {

      ...toRefs(state)
    }
  }
}
</script>
<style scoped>
/* a:hover {
  color: rgba(99, 102, 241, var(--tw-text-opacity));
} */
</style>
