<template>
    <div @click="toReplay" class="cursor-pointer flex flex-row justify-center items-center">
        <div class="mr-5" v-if="text.length>0" >{{text}}</div>
        <VueLottiePlayer  name="voiceAnim"  loop  width="50px" height="50px" :animationData="require('@/assets/lotties/pur_voice.json')" renderer="canvas" @animControl="anim => (voiceAnim = anim)"/>
    </div>
</template>
<script>
import { Howl } from 'howler'
import { reactive, toRefs, defineComponent, onMounted, ref, watch, onBeforeUnmount } from 'vue'
import VueLottiePlayer from 'vue-lottie-player'
import { useStore } from 'vuex'
export default defineComponent({
  components: {
    VueLottiePlayer
  },
  name: 'HL-voice',
  props: {
    url: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: false
    },
    data: {
      type: Number,
      required: false
    },

    stop: {
      type: Boolean,
      required: false
    }
  },
  // props: ['url', 'text', 'data', 'stop'],
  emits: ['onStop'],
  setup (props, { emit }) {
    const store = useStore()
    const state = reactive({
      voiceAnim: ref(null),
      isPlaying: false
    })
    watch(() => props.stop, () => {
      if (props.stop) {
        state.voiceAnim.stop()
        state.sound.stop()
      }
    },
    {
      deep: true // 深度监听的参数
    })
    onMounted(async () => {
      toReplay()
    })
    const toReplay = async () => {
      state.voiceAnim && state.voiceAnim.stop()
      state.sound && state.sound.stop()
      store.state.sound && store.state.sound.stop()
      store.state.voiceAnim && store.state.voiceAnim.stop()
      const sound = new Howl({
        src: [props.url],
        autoplay: true,
        loop: false,
        volume: 1,
        onend: function () {
          state.isPlaying = false
          state.voiceAnim.stop()
          emit('onStop', props.data)
        },
        onplay: function () {
          state.isPlaying = true
          state.voiceAnim.play()
          if (store.state.sound) {
            store.state.sound.stop()
            store.state.sound = ref(null)
          }
          if (store.state.voiceAnim) {
            store.state.voiceAnim.stop()
            store.state.voiceAnim = ref(null)
          }
          state.sound = sound
          store.state.sound = sound
          store.state.voiceAnim = state.voiceAnim
        }

      })
    }
    onBeforeUnmount(async () => {
      console.log('onBeforeUnmount')
      state.voiceAnim && state.voiceAnim.stop()
      state.sound && state.sound.stop()
      state.sound = ref(null)
    })
    return {
      toReplay,
      ...toRefs(state)
    }
  }
})
</script>
