
import { defineComponent, reactive, toRefs, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import AV from 'leancloud-storage'
import { useRouter } from 'vue-router'
import HLDialog from '@/components/dialog.js'
export default defineComponent({
  name: 'accountPasswordLogin',
  props: {},
  components: {

  },
  setup () {
    const router = useRouter()
    const store = useStore()

    const state = reactive({
      errorTitle: '提示',
      errorContent: '账号密码错误',
      loginFormData: {
        account: '',
        password: ''
      },
      requesting: false
    })
    onMounted(async () => {
      if (AV.User.current()) {
        AV.User.logOut()
      }
    })

    const doneAndNext = () => {
      state.requesting = false
      console.log('点击进入下一步')
      router.push({ name: 'Home', force: true })
    }

    const errorDoneAndNext = () => {

    }
    const handleSubmit = (e: any) => {
      e.preventDefault()

      doLogin()
    }
    // 点击登录
    const doLogin = async () => {
      console.log(state.loginFormData)

      if (!state.loginFormData.account || state.loginFormData.account.length < 11) {
        HLDialog({
          content: '账号格式不正确',
          doneAndNext: () => {

          }
        })
        return
      }

      if (!state.loginFormData.password || state.loginFormData.account.length < 6) {
        HLDialog({
          content: '密码格式不正确',
          doneAndNext: () => {

          }
        })
        return
      }
      state.requesting = true
      // 直接已默认账号登录
      // const student = await new AV.Query('GYJDStudent').include('user').equalTo('mobilePhone', state.loginFormData.account).equalTo('password', state.loginFormData.password).equalTo('default', true).first()
      AV.Cloud.run('loginByAccount', {
        account: state.loginFormData.account,
        password: state.loginFormData.password
      }).then((data) => {
        // 处理结果
        console.log(data)

        state.requesting = false
        AV.User.become(data).then(() => {
          // 登录成功
          router.push({ name: 'Home' })
        })
      }, (err) => {
        console.log(err)
        state.requesting = false
        HLDialog({
          content: '账号或密码不正确',
          doneAndNext: () => {

          }
        })
      })
      // if () {
      //   // console.log(student.get('user').getSessionToken())
      //   // AV.User.become(student.get('user').getSessionToken()).then((user) => {
      //   //   // 登录成功
      //   //   router.push({ name: 'Home' })
      //   // })
      // } else {

      // }
    }
    return {
      handleSubmit,

      doneAndNext,
      errorDoneAndNext,
      ...toRefs(state)
    }
  }
})
