<template>
    <div @click="toReplay" class=" cursor-pointer">
        <VueLottiePlayer  name="voiceAnim"  loop  width="80px" height="80px" :animationData="require('@/assets/lotties/voice.json')" renderer="canvas" @animControl="anim => (voiceAnim = anim)"/>
    </div>
</template>
<script>
import { Howl, Howler } from 'howler'
import { reactive, toRefs, defineComponent, onMounted, ref, watch, onBeforeUnmount } from 'vue'
import VueLottiePlayer from 'vue-lottie-player'
import { useStore } from 'vuex'
export default defineComponent({
  components: {
    VueLottiePlayer
  },
  name: 'HL-voice',
  props: ['url', 'stop'],
  emits: ['onEnd', 'onPlay'],
  setup (props, { emit }) {
    const store = useStore()
    const state = reactive({
      voiceAnim: ref(null),
      isPlaying: false
    })
    watch(() => props.stop, () => {
      if (props.stop) {
        state.voiceAnim.stop()
        state.sound.stop()
      }
    },
    {
      deep: true // 深度监听的参数
    })

    onMounted(async () => {
      const sound = new Howl({
        src: [props.url],
        autoplay: true,
        loop: false,
        volume: 1,
        onend: function () {
          state.isPlaying = false
          state.voiceAnim.stop()
          emit('onEnd')
        },
        onplay: function () {
          state.isPlaying = true
          state.voiceAnim.play()
          emit('onPlay')
        }

      })
      if (store.state.sound) {
        store.state.sound.stop()
        store.state.sound = null
      }
      if (store.state.voiceAnim) {
        store.state.voiceAnim.stop()
        store.state.voiceAnim = null
      }
      state.sound = sound
      store.state.sound = sound
      store.state.voiceAnim = state.voiceAnim
    })
    const toReplay = async () => {
      state.voiceAnim.stop()
      state.sound.stop()

      state.voiceAnim.play()
      state.sound.play()
    }
    onBeforeUnmount(async () => {
      console.log('onBeforeUnmount')

      state.voiceAnim.stop()
      state.sound.stop()
    })
    return {
      toReplay,
      ...toRefs(state)
    }
  }
})
</script>
