<template>
    <div style="font-size:14px; " class="flex flex-col items-center w-full">
  <!-- 倒计时区域-->
  <div style="height:40px;background: #FFE7C1;border-radius: 4px;margin-bottom:10px;" class="w-1/2 mt-4 flex flex-row  justify-center items-center">
   <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
    <div style="font-weight: 900;color: black;font-size: 14px;letter-spacing: 1px;margin-left:18px;">{{systemOperationTip}}
      <HLVoiceGroup v-if="systemOperationType==0" @voicePlayEnd="questionItemVoiceEnd" :showLottie="false" :autoPlay="false" :allUrls="allUrls" :times="2" :order="allQuestionInThisGroup[currentQuestionIndex].indexInOneDay">
      </HLVoiceGroup>
      <!-- 播放滴一声，进入下一题-->
      <HLVoiceGroup v-if="systemOperationType==3"  :times="1"  @voicePlayEnd="toNextQuestion" :showLottie="false" :autoPlay="false" :allUrls="['https://file.nj.hlingsoft.com/JMad9VoFnKXQiSloiTOAGXMGVy2538tk/beep.mp3']">
      </HLVoiceGroup>

    </div>
    <div v-if="systemOperationSeconds>0" style="font-weight: 900;font-size: 14px;letter-spacing: 1px;margin-left:18px;">
      {{systemOperationSeconds}}</div>
  </div>

  <div class="flex flex-col  justify-center  pb-40   w-3/4 pl-5" >

   <div class="flex flex-col" style="text-indent:30px; margin:20px;line-height: 25px;color:black">

      <div   style="font-size:14px;text-indent: 30px;margin-top:3px;"  >

                 {{tips}}

       </div>

     </div>
     <div class="w-full flex flex-col items-center justify-center -mt-20">
            <HLRecord
            v-if="showRecordComponent"
            :question="question"
            :maxSeconds="15"
            @onEnd="onRecordEnd"
            @beingEvaluation="beingEvaluation"
          />

     </div>

  </div>

</div>
</template>
<script>
import { reactive, toRefs, defineComponent, ref, onMounted } from 'vue'

import HLRecord from '@/views/components/common/record.vue'

import HLVoiceGroup from '@/views/components/common/voiceGroup.vue'
import _ from 'lodash'
export default defineComponent({
  components: {
    HLVoiceGroup,

    HLRecord

  },
  name: 'HL-qingjingwenda',
  props: ['question', 'allQuestion'],
  emits: ['setAnswerData'],
  setup (props, { emit }) {
    // eslint-disable-next-line vue/no-setup-props-destructure
    const groupQuestion = props.question.groupQuestion

    let allQuestionInThisGroup = []

    allQuestionInThisGroup = props.allQuestion.filter(q => {
      return q.groupQuestion && q.groupQuestion.objectId === groupQuestion.objectId
    })
    allQuestionInThisGroup.sort(function (a, b) {
      return a.indexInOneDay - b.indexInOneDay
    })

    const state = reactive({
      isGroup: true,
      tips: allQuestionInThisGroup[0].questionData[0][0].intro,
      allQuestionInThisGroup,
      // question: props.question,
      seconds: 0,

      allQuestion: allQuestionInThisGroup.filter((q, i) => {
        return i > 0
      }),

      myVoiceText: '我的录音',
      correct: false,
      allUrls: [],
      requesting: false,
      choiceIndex: -1,
      showRecordComponent: false,
      currentShowVoiceButtonIndex: -1,
      resultData: ref(null),
      timerInterval: setInterval(function () {
        state.seconds += 1
      }, 1000),
      currentQuestionIndex: 1, // 默认是group的第二题作为本界面答题的第一题。group的第一题作为题干
      systemOperationTip: '阅读题目倒计时',
      systemOperationType: 1, // 0代表播放音频、1代表阅读题目、2代表回答倒计时、3代表播放滴，需要进入下一题

      systemOperationSeconds: 10
      // systemOperationTimer: setInterval(function () {
      //   state.systemOperationSeconds--
      //   if (state.systemOperationSeconds === 0) {
      //     // 回答倒计时
      //     // 答题倒计时结束了
      //     state.systemOperationTimer && clearInterval(state.systemOperationTimer)
      //     // 进行录音
      //     toRecording()
      //   }
      // }, 1000)

    })
    onMounted(async () => {
      _restart()
    })

    const _restart = async () => {
      state.requesting = false
      state.showRecordComponent = false
      const allUrls = []
      state.allQuestionInThisGroup[state.currentQuestionIndex].questionData[0].map(obj => {
        if (obj.voice && obj.voice.url && obj.voice.url.length > 0) {
          allUrls.push(obj.voice.url)
        }
      })
      state.allUrls = allUrls
      state.question = state.allQuestionInThisGroup[state.currentQuestionIndex]

      if (state.currentQuestionIndex === 1) {
        // 第一次，题目阅读倒计时
        state.systemOperationTip = '阅读题目倒计时'
        state.systemOperationType = 1
        state.systemOperationSeconds = 10
        state.systemOperationTimer = setInterval(function () {
          state.systemOperationSeconds--
          if (state.systemOperationSeconds === 0) {
          // 回答倒计时
          // 答题倒计时结束了
            state.systemOperationTimer && clearInterval(state.systemOperationTimer)
            state.systemOperationType = 0
            state.systemOperationSeconds = -1
            state.systemOperationTip = '正在播放第' + state.currentQuestionIndex + '小问的音频'

            state.systemOperationTimer && clearInterval(state.systemOperationTimer)
          }
        }, 1000)
      } else {
        // 直接播放音频
        state.systemOperationType = 0
        state.systemOperationSeconds = -1
        state.systemOperationTip = '正在播放第' + state.currentQuestionIndex + '小问的音频'
        state.systemOperationTimer && clearInterval(state.systemOperationTimer)
      }
    }

    const toRecording = async () => {
      state.showRecording = true
      state.showRecordComponent = true
      state.systemOperationSeconds = 15
      state.systemOperationType = 5
      state.systemOperationTip = '正在答题...'
      state.systemOperationTimer = setInterval(function () {
        state.systemOperationSeconds--
        if (state.systemOperationSeconds === 0) {
          state.systemOperationTimer && clearInterval(state.systemOperationTimer)
        }
      }, 1000)
    }

    // const onQuestionStemVoicePlay = async () => {
    //   state.showRecordComponent = false
    //   state.resultData = ref(null)
    // }
    // const onQuestionStemVoiceEnd = async () => {
    //   _.delay(function () {
    //     state.showRecordComponent = true
    //     state.resultData = ref(null)
    //   }, 500, '')
    // }
    const onRecordEnd = async (data) => {
    //   console.log('onRecordEnd', data)
      state.showRecordComponent = false
      state.resultData = data
      if (!state.requesting) {
        state.requesting = true
        const evaluationData = state.resultData.evaluationData.EngineResult
        emit('setAnswerData', {
          question: state.question,
          useSeconds: state.seconds,
          evalType: evaluationData.EvalType,
          answerDetail: evaluationData,
          voiceUrl: state.resultData.userVoiceUrl,
          evaluationSeconds: state.resultData.evaluationSeconds,
          score: evaluationData.score,
          correct: evaluationData.score >= 60,
          voiceSpeed: evaluationData.voiceSpeed,
          fluency: Math.ceil(evaluationData.lines[0].fluency),
          sample: evaluationData.lines[0].sample,
          usertext: evaluationData.lines[0].usertext,
          integrity: Math.ceil(evaluationData.lines[0].integrity),
          pronunciation: Math.ceil(evaluationData.lines[0].pronunciation)
        })
      }

      if (state.currentQuestionIndex === 1) {
        state.currentQuestionIndex++
        _restart()
      } else {
        state.systemOperationSeconds = -1
        state.systemOperationTip = '准备进入下一题'
        state.systemOperationType = 3
      }
    }
    const beingEvaluation = async () => {
      state.systemOperationTimer && clearInterval(state.systemOperationTimer)
      state.systemOperationSeconds = -1
      state.systemOperationTip = '系统正在打分...'
    }

    const toNextQuestion = async () => {
      _.delay(function () {
        emit('toNextQuestion')
      }, 500, '')
    }
    const questionItemVoiceEnd = async () => {
      state.systemOperationSeconds = 5
      state.systemOperationType = 2
      state.systemOperationTip = '答题倒计时'
      state.systemOperationTimer = setInterval(function () {
        state.systemOperationSeconds--
        if (state.systemOperationSeconds === 0) {
          // 答题倒计时结束了
          state.systemOperationTimer && clearInterval(state.systemOperationTimer)
          // 进行录音

          toRecording()
        }
      }, 1000)
    }
    return {
      toNextQuestion,
      questionItemVoiceEnd,
      onRecordEnd,
      beingEvaluation,
      ...toRefs(state)
    }
  }
})

</script>
