<template>
  <div class="flex flex-col w-full h-full min-h-full min-w-full">
    <div
      class="flex flex-row pl-10 pr-10  pb-5 text-lg text-indigo-500 border-b-2 align-baseline "
    >
      <!-- 题型名字-->
      <div class=" flex-1">
        {{ questionJSON.answerType == 1 ? "判断题" : "选择题" }}
      </div>
      <div class="hidden">
        <vue-countdown
          @progress="onCountDownProgress"
          :time="countDonwDefaultSeconds * 1000"
        >
        </vue-countdown>
      </div>

      <div class="text-black  italic">{{ questionJSON.name }}</div>
      <div class="flex-1 text-right">
        <span class="text-2xl">{{ currentQuestionIndex + 1 }}</span
        >/<span class="text-gray-400">{{ allQuestionLength }}</span>
      </div>
    </div>
    <div class="" v-if="showResult === false">
      <component
        :is="questionTypeComponent"
        :key="questionJSON.objectId"
        @setAnswerData="setAnswerData"
        :question="questionJSON"
      />
    </div>
    <div v-else class="flex flex-row justify-center items-center p-20">
      <div class="flex-1 flex flex-col justify-center h-full ">
        <img
          class="w-12  "
          src="https://6e62-nbec-4gtpmm6p00694b42-1304760731.tcb.qcloud.la/images/answer/left_quotation_marks.png"
        />
        <div class="mt-10">
          本轮正确率<span class="ml-3 font-extrabold text-lg text-indigo-600"
            >{{ correctRate }}%</span
          >
        </div>
        <div class="mt-4">
          本轮总用时<span class="ml-3 font-extrabold text-lg text-indigo-600"
            >{{ totalUseSeconds }}秒</span
          >
        </div>
        <img
          class="w-12 mt-4 self-end"
          src="https://6e62-nbec-4gtpmm6p00694b42-1304760731.tcb.qcloud.la/images/answer/right_quotation_marks.png"
        />
      </div>
      <HLLottiePlayer
        class="flex-1"
        name="resultAnimation"
        loop
        :animationData="require('@/assets/lotties/gold.json')"
      />
    </div>
  </div>
</template>
<script>
import {
  reactive,
  toRefs,
  onMounted,
  defineComponent,
  ref,
  markRaw,
  watch,
} from "vue";
import { useStore } from "vuex";
import _ from "lodash";

import AV from "leancloud-storage";
import DayJS from "dayjs";
import Util from "@/utils/util.js";
import HLPanDuanTi from "@/views/components/questionType/panduanti.vue";
import HLXuanZeTiForActivity from "@/views/components/questionType/xuanzetiForActivity.vue";
import VueLottiePlayer from "vue-lottie-player";
export default defineComponent({
  components: {
    HLPanDuanTi,
    HLXuanZeTiForActivity,
    HLLottiePlayer: VueLottiePlayer,
  },
  name: "HL-doReadingAnswer",
  props: ["period"],
  setup(props) {
    const store = useStore();

    const state = reactive({
      period: props.period,
      needSaveAnswer: false,
      currentQuestionIndex: 0,
      allQuestionLength: 0,
      questionJSON: {},
      countDonwDefaultSeconds: 60 * 60,
      allAnswer: [],
      questionTypeComponent: ref(null),
      showResult: false,
      correctRate: 0,
      totalUseSeconds: 0,
      // countDownRemainData: {}
    });

    onMounted(async () => {
      state.allAnswer = [];
      await getQuestionsByQuestionTypeAndGradeAndIndexInOneDay();
    });

    // 找到所有的题目
    const getQuestionsByQuestionTypeAndGradeAndIndexInOneDay = async () => {
      const allQuestions = await new AV.Query("GYJDReadingQuestion")
        .startsWith("level", store.state.studentJSON.group.gradeIndex + "")
        .ascending("order")
        .equalTo("period", state.period)
        .ascending("answerType")
        .find();
      state.allQuestions = allQuestions;
      state.allQuestionsJSON = Util._jsonify(allQuestions);
      state.allQuestionLength = allQuestions.length;
      state.questionJSON = state.allQuestionsJSON[state.currentQuestionIndex];
    };
    watch(
      () => state.questionJSON,
      () => {
        if (state.questionJSON.answerType === 1) {
          state.questionTypeComponent = markRaw(HLPanDuanTi);
        } else {
          state.questionTypeComponent = markRaw(HLXuanZeTiForActivity);
        }
      },
      {
        deep: true, // 深度监听的参数
      }
    );
    const setAnswerData = async (data) => {
      state.allAnswer.push(data);
      const timerId = _.delay(
        async function() {
          timerId && clearTimeout(timerId);
          toNextQuestion();
        },
        1000,
        ""
      );
    };
    const toNextQuestion = async () => {
      // await delay()
      if (state.currentQuestionIndex === state.allQuestionLength - 1) {
        // 跳转到结果界面
        let useSeconds = 0;
        let correctCount = 0;

        state.allAnswer.forEach((answer) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          useSeconds += answer.useSeconds;
          if (answer.correct) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            correctCount++;
          }
        });

        state.totalUseSeconds =
          state.countDonwDefaultSeconds -
          state.countDownProgressData.totalSeconds;
        state.wrongCount = state.allAnswer.length - correctCount;
        state.correctRate =
          _.floor(_.divide(correctCount, state.allAnswer.length), 2) * 100;
        state.showResult = true;
      } else {
        // 下一题
        state.showResult = false;
        state.currentQuestionIndex += 1;
        state.questionJSON = state.allQuestionsJSON[state.currentQuestionIndex];
      }
    };

    const onCountDownProgress = async (data) => {
      state.countDownProgressData = data;
    };
    return {
      getQuestionsByQuestionTypeAndGradeAndIndexInOneDay,
      toNextQuestion,
      setAnswerData,
      onCountDownProgress,
      ...toRefs(state),
    };
  },
});
</script>
