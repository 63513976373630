import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import 'animate.css'

import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import AV from 'leancloud-storage'

import VueCountdown from '@chenfengyuan/vue-countdown'
import GlobalHeader from '@/views/common/header.vue'
import GlobalLeft from '@/views/common/left.vue'
import GlobalFooter from '@/views/common/footer.vue'

const APP_ID = 'SDzRSQQ7Omo13lAi7sYEJTrl-gzGzoHsz'
const APP_KEY = 'AAaqYK0FFQQowT71njFiFWJa'
AV.init({
  appId: APP_ID,
  appKey: APP_KEY,
  serverURLs: 'https://api.nj.hlingsoft.com'
})
// AV.Cloud.useMasterKey()
// AV.setProduction(false);

// AV.debug.enable();
const app = createApp(App)
console.log(process.env.NODE_ENV)

app.use(store)
app.use(router)
app.use(Antd)

app.component('vue-countdown', VueCountdown)
app.component('GlobalHeader', GlobalHeader)
app.component('GlobalLeft', GlobalLeft)
app.component('GlobalFooter', GlobalFooter)

app.mount('#app')
