<template>

  <div
    class="w-full max-h-screen h-full  py-4 pb-20 flex flex-col justify-center items-center"
    style="background:#F4F6F1"
  >

    <div
      v-if="studentJSON && gradeJSON  "
      class="w-11/12 h-full flex justify-between "
    >
      <!-- 左侧列表 -->
      <div  :class="[!deviceIsCheck?'hidden':'','w-1/4 h-full bg-white  rounded-xl flex flex-col p-2  items-center']">

        <div  class="flex flex-col w-full pl-5 mt-3  rounded-lg border-gray-200 border-dashed">
            <div class="mt-3">姓名:{{studentJSON.name?studentJSON.name:studentJSON.nickname}}</div>
            <div v-if="studentJSON.candidateNumber" class="mt-3">性别:{{studentJSON.gender==0?'男':'女'}}</div>
            <div  v-if="studentJSON.candidateNumber" class="mt-3">考号:{{studentJSON.candidateNumber}}</div>
            <!-- <div  v-if="studentJSON.candidateNumber" class="mt-3">座号:A08</div> -->
        </div>
        <div class="my-2 bg-gray-50 w-full h-1"></div>
        <div v-if="operationType==3" class="w-full">

        <!-- 上一题 下一题-->
        <div
          class="my-3 flex flex-row text-xs justify-between w-full "
          style="color:#A3A3A3"
        >
          <div @click="toPreQuestion" class="flex flex-col justify-center items-center cursor-pointer flex-1">
            <ArrowCircleLeftIcon
               :class="[currentQuestionIndex==0?'text-gray-100':'text-gyjdGreen-500',' h-6 w-6'] "
              aria-hidden="true"
            />

            <div class="mt-2">上一题</div>
          </div>

          <div class="flex flex-col justify-center items-center cursor-pointer flex-1">

            <div><span class="text-xl text-gyjdGreen-500">{{currentQuestionIndex+1}}</span><span class="text-xs">/24</span></div>
            <div class="mt-2"></div>
          </div>

          <!-- <div class="w-1 h-10 bg-gray-50"></div> -->
          <div @click="toNextQuestion" class="flex flex-col  justify-center items-center cursor-pointer flex-1">
            <ArrowCircleRightIcon
              :class="[currentQuestionIndex==23?'text-gray-100':'text-gyjdGreen-500',' h-6 w-6'] "
              aria-hidden="true"
            />

            <div class="mt-2">下一题</div>
          </div>
        </div>

        <!-- 重选题型-->
        <div class="my-2 bg-gray-50 w-full h-1"></div>
        <div
          v-if="allQuestionTypeJSON && currentSelectedQuestionType"
          class="w-full  p-3 cursor-pointer"
        >

          <Listbox v-model="currentSelectedQuestionType" >
            <div class="relative mt-1">
              <ListboxButton @click="changeQuestionType()" class="relative w-full  pl-3  pr-10 text-left bg-white rounded-lg  focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                <span class="block truncate">{{ allQuestionTypeJSON[currentSelectedQuestionTypeIndex].name }}</span>
                <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <SelectorIcon
                    class="w-5 h-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </ListboxButton>

              <transition
                leave-active-class="transition duration-100 ease-in"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <ListboxOptions style="z-index:9999999" class="absolute   w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  <ListboxOption
                    v-slot="{ active }"
                    v-for="questionType in allQuestionTypeJSON"
                    :key="questionType"
                    :value="questionType"
                    as="template"
                  >
                    <li :class="[
                  active ? 'text-amber-900 bg-amber-100' : 'text-gray-900',
                  'cursor-default select-none relative py-2 pl-10 pr-4',
                ]">
                      <span :class="[
                currentSelectedQuestionType.series==questionType.series? ' font-extrabold' : 'font-normal',
                    'block truncate',
                  ]">{{ questionType.name }}</span>

                      <span
                        v-if="currentSelectedQuestionType.series==questionType.series"
                        class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
                      >
                        <CheckIcon
                          class="w-5 h-5"
                          aria-hidden="true"
                        />
                      </span>
                    </li>
                  </ListboxOption>
                </ListboxOptions>
              </transition>
            </div>
          </Listbox>
        </div>

        <!-- 答案提示-->
        <div class="my-2 bg-gray-50 w-full h-1"></div>
        <div @click="toShowTips" class=" p-3 w-full pl-6 cursor-pointer">
          答案提示
          <!-- <button class="btn btn-outline btn-warning w-full ">答案提示</button> -->
        </div>

        <div class="my-2 bg-gray-50 w-full h-1"></div>
        </div>
        <!-- 音量-->
        <div class="flex flex-row justify-start p-2 items-center mt-6">
          <VolumeUpIcon
            class=" h-8 w-8 text-gyjdGreen-500 mr-2"
            aria-hidden="true"
          />
           <input type="range" max="100" v-model="volume" @input="volumeChange" class="range ">

        </div>
        <!-- 提交答案-->

        <div class=" p-3 w-full ">
          <button @click="toSubmit"  class="btn btn-outline  w-full bg-gyjdGreen-500 text-white hover:bg-gyjdGreen-500 hover:border-gyjdGreen-500">提交答案</button>
        </div>

      </div>

      <!-- 右侧考试信息 -->
      <div class="w-3/4 h-full rounded-xl ml-1 flex  justify-center items-center bg-white scrollbar scrollbar-thin scrollbar-thumb-gyjdGreen-500 ">
        <div
          v-if="deviceIsCheck && allQuestionsJSON"
          class="w-full h-full flex flex-col  items-center relative"
        >
        <div class="w-full p-4 pl-10 flex flex-row items-center ">

              <div class="text-lg">{{gradeJSON.name}} </div>
            <vue-countdown
                    class="hidden"
                      @progress="onCountDownProgress"
                      v-slot="{ minutes, seconds }"
                      :time="countDonwDefaultSeconds * 1000"
                    >
                    倒计时:  {{ minutes }}分{{ seconds }}秒
                    </vue-countdown>
        </div>

          <div class="my-2 bg-gray-50 w-full h-1"></div>
          <!-- 题型提示-->
          <div
            class="mt-3  text-lg"
            style="color:#11152B"
          >第{{currentSelectedQuestionType.seriesChinese}}部分 {{currentSelectedQuestionType.name}}<span class="text-gray-400 text-sm">（计{{currentSelectedQuestionType.defaultScore}}分）</span></div>
          <div
            class="mt-3 w-3/5 h-auto p-5 rounded "
            style="text-indent:32px;background:#FCFAFD;color:#11152B;line-height:26px;font-weight:400;font-size:13px;"
          >{{currentSelectedQuestionType.description}}</div>
          <HLVoiceGroup v-if="subjectAllUrls && questionJSON" :allUrls="subjectAllUrls" :times="1" :order="-1" @voicePlayEnd="subjectVoicePlayEnd"/>
          <div  v-if="secondDescription && secondDescription.length>0"
            class=" w-3/5 h-auto p-5 pt-0 rounded "
            style="text-indent:32px;background:#FCFAFD;color:#11152B;line-height:26px;font-weight:400;font-size:13px;"
          >{{secondDescription}}</div>
          <HLVoiceGroup v-if="!subjectAllUrls && secondDescriptionVoiceUrl  && questionJSON" :allUrls="secondDescriptionVoiceUrl" :times="1"  :order="-1"  @voicePlayEnd="secondSubjectVoicePlayEnd"/>

          <div v-if="startDoAnswer && questionJSON && !secondDescriptionVoiceUrl" class="w-5/6">

               <component
                :is="questionTypeComponent"
                :key="questionJSON.objectId"
                @setAnswerData="setAnswerData"
                @toNextQuestion="toNextQuestion"
                :question="questionJSON"
                :allQuestion="allHasGroupQuestionsJSON"
             />
          </div>

        </div>

        <HLDeviceCheck

          v-if="!deviceIsCheck"
          @checkDone="deviceCheckDone"
        />

      </div>

    </div>
    <div
      v-else
      class="w-11/12 h-full flex justify-center items-center bg-white"
    >
      <button class="btn loading">正在加载数据</button>
    </div>
  </div>
  <Loading v-if="isSavingData" :content="'正在计算成绩,请稍后...'"/>
</template>

<script >
import { reactive, toRefs, onMounted, ref, watchEffect, markRaw, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import AV from 'leancloud-storage'
import { Howl, Howler } from 'howler'
import DayJS from 'dayjs'
import HLDeviceCheck from '@/views/doAnswer/component/deviceCheck.vue'
import HLVoiceGroup from '@/views/components/common/voiceGroup.vue'
import HLTingDuiHua from '@/views/components/questionType/tingduihua.vue'
import HLLangdDuDuanWen from '@/views/components/questionType/langduduanwen.vue'
import HLTingDuiHuaHeDuanWen from '@/views/components/questionType/tingduihuaheduanwen.vue'
import HLQingJingWenDa from '@/views/components/questionType/qingjingwenda.vue'
import HLHuaTiJianShu from '@/views/components/questionType/huatijianshu.vue'
import HLAnswerTipsComponent from '@/views/doAnswer/answerTips.js'
import Util from '@/utils/util.js'
import Loading from '@/components/Loading.vue'
import Dialog from '@/components/dialog.js'
import Confirm from '@/components/confirm.js'
import router from '@/router'
import _ from 'lodash'
import Student from '@/entity/student.ts'
import Question from '@/entity/question.ts'
import QuestionType from '@/entity/questionType.ts'
import Grade from '@/entity/grade'
import Answer from '@/entity/answer.ts'
import QuestionTypeInGrade from '@/entity/questionTypeInGrade.ts'
import
EverySetAnswerStatistics from '@/entity/everySetAnswerStatistics.ts'
import EveryGradeStatistics from '@/entity/everyGradeStatistics.ts'

import { ClockIcon, ArrowCircleLeftIcon, ArrowCircleRightIcon, ReplyIcon, CheckIcon, SelectorIcon, VolumeUpIcon } from '@heroicons/vue/solid'
import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption
} from '@headlessui/vue'
export default {
  name: 'Answer',
  props: {},
  components: {
    HLVoiceGroup,
    HLDeviceCheck,
    Loading,
    HLTingDuiHua,
    HLTingDuiHuaHeDuanWen,
    HLLangdDuDuanWen,
    HLQingJingWenDa,
    HLHuaTiJianShu,
    HLAnswerTipsComponent,
    ArrowCircleLeftIcon,
    ArrowCircleRightIcon,
    ReplyIcon,
    Listbox,
    ListboxLabel,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    CheckIcon,
    SelectorIcon,
    VolumeUpIcon,
    ClockIcon,
    Confirm
  },
  setup () {
    const store = useStore()
    const route = useRoute()
    console.log(route.params) // 第二步
    if (!route.params.gradeId) {
      router.push({ name: 'Home' })
    }

    const state = reactive({
      isSavingData: false,
      volume: 100,
      countDonwDefaultSeconds: 3600,
      countDownProgressData: ref(null),
      questionTypeChangeByTypeList: true,
      answers: [],
      allQuestion: ref(null),
      allHasGroupQuestionsJSON: ref(null),
      allQuestionsJSON: ref(null),
      allQuestionType: ref(null),
      allQuestionTypeJSON: ref(null),
      questionJSON: ref(null),
      currentSelectedQuestionTypeIndex: 0,
      currentSelectedQuestionType: ref(null),
      currentQuestionIndex: 0,
      gradeId: route.params.gradeId,
      gradeJSON: ref(null),
      operationType: Number(route.params.operationType),
      studentJSON: ref(null),
      deviceIsCheck: localStorage.getItem('deviceIsCheck'),
      secondDescription: ref(null),
      secondDescriptionVoiceUrl: ref(null),
      subjectAllUrls: ref(null),
      allAnswer: [],
      questionTypeComponent: ref(null),
      startDoAnswer: false,
      playSubject: Number(route.params.operationType) === 2
    })

    watch(
      () => state.currentSelectedQuestionType,
      (newObject, oldObject) => {
        if ((!oldObject && newObject) || (newObject.series !== oldObject.series)) {
          console.log('检测到questionType变化')
          state.startDoAnswer = false
          state.subjectAllUrls = ref(null)
          state.secondDescriptionVoiceUrl = ref(null)
          state.secondDescription = ref(null)
          state.questinJSON = ref(null)

          // 从听力切换到口语
          if (oldObject && oldObject.series < 3 && newObject.series > 2) {
            Dialog({
              content: '进入口语部分',
              doneAndNext: () => {
                _changeQuestionType()
              }
            })
          } else {
            // 切换题型时
            _changeQuestionType()
          }
        }
      })
    const _changeQuestionType = () => {
      // 切换题型时

      state.currentSelectedQuestionTypeIndex = state.allQuestionTypeJSON ? state.allQuestionTypeJSON.findIndex(questionType => {
        return questionType.series === state.currentSelectedQuestionType.series
      }) : 0

      // console.log(state.questionTypeChangeByTypeList)
      if (state.questionTypeChangeByTypeList) {
        // 如果用户点击切换题型
        if (state.currentSelectedQuestionType.series === 1) {
          state.currentQuestionIndex = 0
        } else if (state.currentSelectedQuestionType.series === 2) {
          state.currentQuestionIndex = 10
        } else if (state.currentSelectedQuestionType.series === 3) {
          state.currentQuestionIndex = 20
        } else if (state.currentSelectedQuestionType.series === 4) {
          state.currentQuestionIndex = 21
        } else if (state.currentSelectedQuestionType.series === 5) {
          state.currentQuestionIndex = 23
        }
      }
      console.log('  state.currentQuestionIndex', state.currentQuestionIndex)

      const nextTimeout = setTimeout(function () {
        nextTimeout && clearTimeout(nextTimeout)

        _readyForPlaySubject()
        _readyForPlaySecondSubject()
      }, 100)
    }

    const _readyForPlaySubject = async () => {
      console.log('_readyForPlaySubject')
      // 播放题干听力

      state.subjectAllUrls = state.playSubject ? [state.currentSelectedQuestionType.nameVoiceUrl, state.currentSelectedQuestionType.descriptionVoiceUrl] : ['https://file.nj.hlingsoft.com/A6LQ19O7I00NFfTQOcKzzlsh3ro0iuCS/emptyAudio.mp3']
      console.log(state.subjectAllUrls)
    }
    const _readyForPlaySecondSubject = async () => {
      if (state.currentSelectedQuestionType.series === 2) {
        // 11-12小题
        if (state.currentQuestionIndex === 10) {
          state.secondDescription = '听一段对话，回答第11—12小题。答题完毕，请等待“嘀”的信号，进入第一篇短文。'
          // state.secondDescriptionVoiceUrl = state.playSubject ? ['https://file.nj.hlingsoft.com/e1a77b15085e34f1e6a6.mp3/ss.mp3'] : ['https://file.nj.hlingsoft.com/A6LQ19O7I00NFfTQOcKzzlsh3ro0iuCS/emptyAudio.mp3']
          state.secondDescriptionVoiceUrl = state.playSubject ? ['https://file.nj.hlingsoft.com/pnmMjeQGCYovVK8qJD0GXxnXDgXr2dLd/03-%E4%B8%80%E6%AE%B5%E5%AF%B9%E8%AF%9D.mp3'] : ['https://file.nj.hlingsoft.com/A6LQ19O7I00NFfTQOcKzzlsh3ro0iuCS/emptyAudio.mp3']
        } else if (state.currentQuestionIndex === 12) {
          state.secondDescription = '听第一篇短文，回答第13—15小题。请根据短文内容，选择正确答案，完成信息记录表。答题完毕，请等待“嘀”的信号，进入下一篇短文。'
          // state.secondDescriptionVoiceUrl = state.playSubject ? ['https://file.nj.hlingsoft.com/79114c68f25fc22b1616.mp3/8.mp3'] : ['https://file.nj.hlingsoft.com/A6LQ19O7I00NFfTQOcKzzlsh3ro0iuCS/emptyAudio.mp3']
          state.secondDescriptionVoiceUrl = state.playSubject ? ['https://file.nj.hlingsoft.com/Nu2clVN17m8Oi1LvjU6fYx9Q08xvhmPX/04-%E7%AC%AC%E4%B8%80%E7%AF%87%E7%9F%AD%E6%96%87.mp3'] : ['https://file.nj.hlingsoft.com/A6LQ19O7I00NFfTQOcKzzlsh3ro0iuCS/emptyAudio.mp3']
        } else if (state.currentQuestionIndex === 15) {
          state.secondDescription = '听第二篇短文，回答第16—20小题。答题完毕，请等待“嘀”的信号，进入测试的第三部分。'
          // state.secondDescriptionVoiceUrl = state.playSubject ? ['https://file.nj.hlingsoft.com/a508d41dc3547fcf709a.mp3/9.mp3'] : ['https://file.nj.hlingsoft.com/A6LQ19O7I00NFfTQOcKzzlsh3ro0iuCS/emptyAudio.mp3']
          state.secondDescriptionVoiceUrl = state.playSubject ? ['https://file.nj.hlingsoft.com/SUoRMgWHL0ajzQzIhCWLd82IwuXBDR2u/06-%E7%AC%AC%E4%BA%8C%E7%AF%87%E7%9F%AD%E6%96%87-%E6%A8%A1%E6%8B%9F.mp3'] : ['https://file.nj.hlingsoft.com/A6LQ19O7I00NFfTQOcKzzlsh3ro0iuCS/emptyAudio.mp3']
        }
      } else {
        state.secondDescription = ref(null)
        state.secondDescriptionVoiceUrl = ref(null)
      }
    }
    const secondSubjectVoicePlayEnd = () => {
      console.log('secondSubjectVoicePlayEnd')
      state.startDoAnswer = true
      state.secondDescriptionVoiceUrl = ref(null)
      // _reAnswer()
    }
    // 题干播放完成
    const subjectVoicePlayEnd = () => {
      console.log('subjectVoicePlayEnd')
      state.subjectAllUrls = ref(null)

      if (state.playSubject && state.secondDescriptionVoiceUrl) {
        state.startDoAnswer = false
        _reAnswer()
      } else {
        state.startDoAnswer = true
        _reAnswer()
      }
    }
    const _reAnswer = () => {
      if (state.allQuestionsJSON) {
        _readyForPlaySecondSubject()
        state.questionJSON = ref(null)
        const nextTimeout = setTimeout(function () {
          nextTimeout && clearTimeout(nextTimeout)

          state.questionJSON = state.allQuestionsJSON[state.currentQuestionIndex]
          // if (state.currentSelectedQuestionType && state.questionJSON.questionType.series !== state.currentSelectedQuestionType.series) {
          state.currentSelectedQuestionType = state.questionJSON.questionType
          // }
          console.log(state.currentSelectedQuestionType.series)
          if (state.questionJSON.questionType.series === 1) {
            state.questionTypeComponent = markRaw(HLTingDuiHua)
          } else if (state.questionJSON.questionType.series === 2) {
            state.questionTypeComponent = markRaw(HLTingDuiHuaHeDuanWen)
          } else if (state.questionJSON.questionType.series === 3) {
            state.questionTypeComponent = markRaw(HLLangdDuDuanWen)
          } else if (state.questionJSON.questionType.series === 4) {
            state.questionTypeComponent = markRaw(HLQingJingWenDa)
          } else if (state.questionJSON.questionType.series === 5) {
            state.questionTypeComponent = markRaw(HLHuaTiJianShu)
          }
        }, 100)
      }
    }

    onMounted(async () => {
      store.state.studentJSON = Util._jsonify(store.state.student)
      state.studentJSON = store.state.studentJSON
      const studySchedule = await new AV.Query('GYJDStudySchedule').include('grade').equalTo('student', store.state.student).equalTo('validated', true).first()

      store.state.studySchedule = studySchedule
      store.state.studyScheduleJSON = Util._jsonify(studySchedule)
      await _init()
    })

    const _init = async () => {
      // 获取题目
      if (state.gradeId) {
        await getGrade()
        await getAllQuestionTypeInThisGrade()
        // await getQuestionsByAllQuestionTypeAndGradeAndIndexInOneDay()

        // 去找题目,
        // 含有10.5 12.5 15.5
        const allHasGroupQuestions = await getQuestionsByAllQuestionTypeAndGradeAndIndexInOneDay()
        // 具体作答的题目，不含10.5  12.5 15.5
        const allQuestions = allHasGroupQuestions.filter(q => {
          return q.indexInOneDay % 1 === 0
        })

        state.answers = []
        // 为每个题目创建一个空答案
        for (let i = 0; i < state.allQuestionType.length; i++) {
          const questionsWithThisQuestionType = allQuestions.filter(question => {
            return question.questionType.id === state.allQuestionType[i].id
          })
          state.answers[i] = []

          questionsWithThisQuestionType.map(questionTemp => {
            const answer = new Answer()
            answer.ifDoAnswer = false
            answer.question = questionTemp
            answer.channel = 'web'
            state.answers[i].push(answer)
          })
        }

        state.allQuestion = allQuestions
        state.allQuestionsJSON = Util._jsonify(allQuestions)
        state.allHasGroupQuestionsJSON = Util._jsonify(allHasGroupQuestions)

        if (state.operationType === 3 && state.deviceIsCheck) {
          _showSubjectTipsDialog()
        } else {
          if (state.deviceIsCheck) {
            _reAnswer()
          }
        }
      }
    }

    const _showSubjectTipsDialog = async () => {
      Confirm({
        title: '提示',
        content: '是否跳过题干?',
        close: () => {
          console.log('close')
          state.playSubject = true

          _readyForPlaySubject()
          _reAnswer()
        },
        doneAndNext: () => {
          console.log('doneAndNext')
          state.playSubject = false
          _reAnswer()
        }
      })
    }
    const getGrade = async () => {
      const grade = await new AV.Query(Grade).get(state.gradeId)
      state.grade = grade
      state.gradeJSON = Util._jsonify(grade)
      // console.log('state.gradeJSON', state.gradeJSON)
    }

    const getAllQuestionTypeInThisGrade = async () => {
      const allQuestionTypeInGrade = await new AV.Query(QuestionTypeInGrade).equalTo('grade', state.grade).include('questionType').find()
      const allQuestionType = []
      allQuestionTypeInGrade.map(questionTypeInGrade => {
        allQuestionType.push(questionTypeInGrade.questionType)
      })
      allQuestionType.sort(function (a, b) {
        return a.series - b.series
      })
      state.allQuestionType = allQuestionType
      state.allQuestionTypeJSON = Util._jsonify(allQuestionType)
      state.currentSelectedQuestionType = state.allQuestionTypeJSON[state.currentSelectedQuestionTypeIndex]
    }

    const toPreQuestion = async () => {
      if (!state.startDoAnswer) {
        return
      }
      state.questionTypeChangeByTypeList = false
      if (state.allQuestion[state.currentQuestionIndex - 1].groupQuestion) {
        // 存在群组情况
        // 找到这组的头
        state.currentQuestionIndex = state.currentQuestionIndex - state.allQuestion.filter(q => {
          return q.groupQuestion && q.groupQuestion.id === state.allQuestion[state.currentQuestionIndex - 1].groupQuestion.id
        }).length
      } else {
        state.currentQuestionIndex--
      }

      if (state.currentQuestionIndex >= 0) {
        _reAnswer()
      }
    }

    const toNextQuestion = async () => {
      if (!state.startDoAnswer) {
        return
      }
      state.questionTypeChangeByTypeList = false
      let nextQuestionIndex
      if (state.allQuestion[state.currentQuestionIndex].groupQuestion) {
        // 存在群组情况
        // 找到这一个组有多少个
        nextQuestionIndex = state.currentQuestionIndex + state.allQuestion.filter(q => {
          return q.groupQuestion && q.groupQuestion.id === state.allQuestion[state.currentQuestionIndex].groupQuestion.id
        }).length
      } else {
        nextQuestionIndex = state.currentQuestionIndex + 1
      }

      if (nextQuestionIndex >= state.allQuestion.length) {
        // 已答完最后一题，需要提交

        toSubmit()
        // wx.showToast({
        //   title: '已是最后一题',
        //   icon: 'none',
        //   duration: 3000
        // })
      } else {
        state.currentQuestionIndex = nextQuestionIndex

        _reAnswer()
      }
    }
    const setAnswerData = async (currentAnswerData) => {
    // 子组件进行了选择或者口语测评之后或者其他作答之后，ifDoAnswer为true调用以及
    // 当前界面倒计时结束或者点击下一题或者切换题型

      // 处理一下一套题有多个question的情况
      let currentQuestionIndexInAllQuestion = state.currentQuestionIndex
      if (currentAnswerData.question) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        currentQuestionIndexInAllQuestion = state.allQuestion.findIndex(q => {
          return q.id === currentAnswerData.question.objectId
        })
      }
      const answer = new Answer()
      answer.set('channel', 'web')
      // 处理web端计时器bug
      answer.set(
        'totalUseSeconds',
        currentAnswerData.totalUseSeconds >= currentAnswerData.useSeconds
          ? currentAnswerData.totalUseSeconds
          : currentAnswerData.useSeconds
      )
      answer.set('remainTime', currentAnswerData.remainTime)

      answer.set('user', AV.User.current())
      answer.set('student', store.state.student)
      answer.set('question', state.allQuestion[currentQuestionIndexInAllQuestion])
      answer.set('questionType', state.currentSelectedQuestionType)
      answer.set('grade', state.grade)

      answer.set('correct', currentAnswerData.correct)
      answer.set('useSeconds', currentAnswerData.useSeconds)
      answer.set('remainTime', state.countDownProgressData ? state.countDownProgressData.totalSeconds : state.countDonwDefaultSeconds)
      answer.set('anyDay', store.state.studyScheduleJSON.currentDay)
      answer.set('todayString', DayJS().format('YYYY-MM-DD'))
      answer.set('today', new Date())

      if (currentAnswerData.correct) {
        answer.set('rightDayString', answer.get('todayString'))
        answer.set('rightDay', answer.get('today'))
      }
      answer.set('score', currentAnswerData.score)
      answer.set('userChoice', currentAnswerData.userChoice)
      answer.set('answerDetail', currentAnswerData.answerDetail)
      answer.set('voiceUrl', currentAnswerData.voiceUrl)
      answer.set('fluency', currentAnswerData.fluency)
      answer.set('pronunciation', currentAnswerData.pronunciation)
      answer.set('integrity', currentAnswerData.integrity)
      answer.set('voiceSpeed', currentAnswerData.voiceSpeed)
      answer.set('evalType', currentAnswerData.evalType)
      answer.set('usertext', currentAnswerData.usertext)
      answer.set('sample', currentAnswerData.sample)
      answer.set('evaluationSeconds', currentAnswerData.evaluationSeconds)

      if (answer.question.questionType.series === 1 || answer.question.questionType.series === 2) {
        answer.scoreInGrade = answer.correct ? 1 : 0
      } else if (answer.question.questionType.series === 3) {
        if (answer.score <= 10) {
          answer.scoreInGrade = 0
        } else if (answer.score <= 60) {
          answer.scoreInGrade = 1
        } else if (answer.score <= 70) {
          answer.scoreInGrade = 2
        } else {
          answer.scoreInGrade = 3
        }
      } else if (answer.question.questionType.series === 4) {
        if (answer.score <= 30) {
          answer.scoreInGrade = 0
        } else {
          answer.scoreInGrade = 1
        }
      } else if (answer.question.questionType.series === 5) {
        if (answer.question.grade.series === 2) {
        // 口语训练里面的话题简述
          if (answer.score <= 30) {
            answer.scoreInGrade = 0
          } else {
            answer.scoreInGrade = 1
          }
        } else {
        // 模拟训练和随机组卷里面的话题简述
          if (answer.score <= 10) {
            answer.scoreInGrade = 0
          } else if (answer.score <= 30) {
            answer.scoreInGrade = 1
          } else if (answer.score <= 60) {
            answer.scoreInGrade = 2
          } else if (answer.score <= 70) {
            answer.scoreInGrade = 3
          } else if (answer.score <= 80) {
            answer.scoreInGrade = 4
          } else {
            answer.scoreInGrade = 5
          }
        }
      }
      answer.ifDoAnswer = true
      // 从每个题型中找

      const findIndex = state.answers[state.currentSelectedQuestionTypeIndex].findIndex(answerTemp => {
        return answerTemp.question.id === answer.question.id
      })
      if (findIndex > -1) {
        state.answers[state.currentSelectedQuestionTypeIndex][findIndex] = answer
      } else {
        state.answers[state.currentSelectedQuestionTypeIndex].push(answer)
      }
      console.log(state.answers)
    }

    const getQuestionsByAllQuestionTypeAndGradeAndIndexInOneDay = async () => {
      let allQuestions = []
      if (state.grade.series === 0) {
        // 随机

        const innerQuery = new AV.Query(Grade)
        innerQuery.equalTo('series', 3)
        innerQuery.equalTo('isRandom', false)
        const questionsWithQuestionType = await new AV.Query(Question).matchesQuery('grade', innerQuery).equalTo('anyDay', store.state.studyScheduleJSON.currentDay).include('grade').include('questionType').include('groupQuestion').include('groupQuestion.questionType').limit(1000).find()

        // 按照indexInOneDay排序并分组
        const sortedQuestions = (_.groupBy(_.sortBy(questionsWithQuestionType, function (a) {
          return a.indexInOneDay
        }), function (a) {
          return a.indexInOneDay
        }))

        let latestQuestions = []
        // 1-10为听对话回答问题
        // 注意sortedQuestions是个set，key为indexInOney，value为对应的题目数组
        for (let i = 1; i < 11; i++) {
          latestQuestions.push(_.sample(sortedQuestions[i]))
        }

        // 11-20为听对话和短文回答问题
        // 10.5-12,共3题
        // 随机8套里面一个10.5

        const tempQuestion10And5 = _.sample(sortedQuestions[10.5])
        latestQuestions = _.concat(latestQuestions, questionsWithQuestionType.filter(q => {
          return q.groupQuestion && q.groupQuestion.id === tempQuestion10And5.id
        }))
        const tempQuestion12And5 = _.sample(sortedQuestions[12.5])

        latestQuestions = _.concat(latestQuestions, questionsWithQuestionType.filter(q => {
          return q.groupQuestion && q.groupQuestion.id === tempQuestion12And5.id
        }))

        const tempQuestion15A5 = _.sample(sortedQuestions[15.5])
        latestQuestions = _.concat(latestQuestions, questionsWithQuestionType.filter(q => {
          return q.groupQuestion && q.groupQuestion.id === tempQuestion15A5.id
        }))

        latestQuestions.push(_.sample(sortedQuestions[21]))

        const tempQuestion21And5 = _.sample(sortedQuestions[21.5])
        latestQuestions = _.concat(latestQuestions, questionsWithQuestionType.filter(q => {
          return q.groupQuestion && q.groupQuestion.id === tempQuestion21And5.id
        }))

        latestQuestions.push(_.sample(sortedQuestions[24]))

        return latestQuestions.sort((a, b) => {
          return a.indexInOneDay - b.indexInOneDay
        })
      } else {
        allQuestions = await new AV.Query(Question).equalTo('grade', state.grade).equalTo('anyDay', store.state.studyScheduleJSON.currentDay).include('grade').include('questionType').include('groupQuestion').include('groupQuestion.questionType').limit(1000).find()
      }

      allQuestions.sort((a, b) => {
        return a.indexInOneDay - b.indexInOneDay
      })
      return allQuestions
      // state.allQuestion = allQuestions
      // state.allQuestionsJSON = Util._jsonify(allQuestions)
    }

    const deviceCheckDone = async () => {
      localStorage.setItem('deviceIsCheck', 'true')
      state.deviceIsCheck = 'true'
      await _init()
    }
    const changeQuestionType = () => {
      console.log('click changeQuestionType')
      state.questionTypeChangeByTypeList = true
    }
    const toShowTips = () => {
      // state.showTips = true
      HLAnswerTipsComponent({
        question: state.questionJSON,
        allHasGroupQuestionsJSON: state.allHasGroupQuestionsJSON,
        allQuestion: state.allQuestion

      })
    }
    const onCountDownProgress = async (data) => {
      state.countDownProgressData = data
      if (data.totalSeconds === 0) {
        toSubmit(true)
      }
    }
    const toSubmit = async (flag) => {
      Confirm({
        title: '提示',
        content: '确定提交本次模拟考试吗?',
        doneAndNext: async () => {
          _saveData()
        }
      })
    }
    const _saveData = async () => {
      state.submitUnionCode = new Date().getTime()
      state.startDoAnswer = ref(null)
      state.questionJSON = ref(null)
      state.isSavingData = true
      const allSetAnswerStatisticsJSON = []
      for (let i = 0; i < state.answers.length; i++) {
      // temporarilyAnswers二维数组
      // 统计每个题型的数据
      // 保存每个题型的答案

        state.answers[i] = state.answers[i].filter(answer => {
          return answer.ifDoAnswer
        })

        allSetAnswerStatisticsJSON.push(await _statisticsEverySet(state.answers[i], state.allQuestionType[i]))
      }
      state.allSetAnswerStatisticsJSON = allSetAnswerStatisticsJSON
      await _statisticsTotalQuestionTypeInGrade()
      await _statisticsGrade()
      await _formatData()
      _.delay(function () {
        router.push({ name: 'LearningDataDetail' })
      }, 1000, '')

      // console.log('数据保存完了')
    }
    const _formatData = async () => {
      const answers = _.flattenDeep(state.answers)

      const questions = state.allQuestion
      const allQuestionTypeResult = state.allSetAnswerStatisticsJSON

      questions.map(q => {
        q.answer = answers.find(a => {
          return (q.id === a.question.id)
        })
      })
      // console.log('allQuestionTypeResult', allQuestionTypeResult)
      // questions,按照questionType分组

      const grouping = _.groupBy(questions, function (a) {
        return [a.questionType.series]
      })
      const questionAndAnswers = []
      _.keys(grouping).map((key) => {
        const questionTypeResult = allQuestionTypeResult.find(qtr => {
          // console.log(qtr.questionType.series, key, qtr.series === key)
          return qtr.questionType.series === Number(key)
        })
        questionAndAnswers.push({
          score: questionTypeResult ? questionTypeResult.totalScoreInGrade : 0,
          data: grouping[key]
        })
      })
      // console.log(_.toArray(grouping))
      store.state.temporarilyAnswerData = questionAndAnswers
    }
    const _saveEverySetAnswerStatistics = async (questionType, totalAnswerLength, totalAnswerScoreInGrade, useSeconds, totalUseSeconds, correctRate, wrongCount, correctCount) => {
      const everySetAnswerStatistics = new EverySetAnswerStatistics()
      everySetAnswerStatistics.user = AV.User.current()
      everySetAnswerStatistics.submitUnionCode = state.submitUnionCode
      everySetAnswerStatistics.student = store.state.student
      everySetAnswerStatistics.grade = state.grade
      everySetAnswerStatistics.questionType = questionType
      everySetAnswerStatistics.useSeconds = useSeconds
      everySetAnswerStatistics.totalUseSeconds = totalUseSeconds
      everySetAnswerStatistics.correctRate = correctRate
      everySetAnswerStatistics.isAllCorrect = correctRate === 100
      everySetAnswerStatistics.validated = true
      everySetAnswerStatistics.anyDay = store.state.studyScheduleJSON.currentDay
      everySetAnswerStatistics.wrongCount = wrongCount
      everySetAnswerStatistics.correctCount = correctCount
      everySetAnswerStatistics.channel = 'web'
      everySetAnswerStatistics.totalAnswerLength = totalAnswerLength
      everySetAnswerStatistics.totalAnswerScoreInGrade = totalAnswerScoreInGrade
      return await everySetAnswerStatistics.save()
    }
    const _statisticsTotalQuestionTypeInGrade = async () => {
      // const allQuestionType = state.allQuestionType

      const allQuestionTypeStatus = []
      for (let i = 0; i < state.allQuestionType.length; i++) {
        const everySetAnswerStatistics = state.allSetAnswerStatisticsJSON.find(everySetStatistics => {
          return everySetStatistics.key === state.allQuestionType[i].id
        })

        allQuestionTypeStatus.push({

          everySetAnswerStatistics
        })
      }

      state.allQuestionTypeStatus = allQuestionTypeStatus
    }
    const _statisticsEverySet = async (answers, questionType) => {
      let useSeconds = 0

      let correctCount = 0
      let totalScoreInGrade = 0
      let wrongCount = 0
      let correctRate = 0
      let totalUseSeconds = 0

      answers.forEach((answer) => {
        useSeconds += answer.useSeconds
        totalUseSeconds += answer.totalUseSeconds
        totalScoreInGrade += answer.scoreInGrade
        if (answer.correct) {
          correctCount++
        }
      })

      wrongCount = answers.length - correctCount
      if (answers.length > 0) {
        console.log(answers[0])
        const currentAnswerWithQuestionType = questionType
        let totalAnswerCountWithQuestionTypeAndGrade

        if (answers[0].questionType.series === 1) {
          // 听对话回答问题
          totalAnswerCountWithQuestionTypeAndGrade = 10
        } else if (answers[0].questionType.series === 2) {
          totalAnswerCountWithQuestionTypeAndGrade = 10
        } else if (answers[0].questionType.series === 3) {
          totalAnswerCountWithQuestionTypeAndGrade = 1
        } else if (answers[0].questionType.series === 4) {
          totalAnswerCountWithQuestionTypeAndGrade = 2
        } else if (answers[0].questionType.series === 5) {
          totalAnswerCountWithQuestionTypeAndGrade = 1
        }
        correctRate = Util.formatCorrect(correctCount, totalAnswerCountWithQuestionTypeAndGrade)

        if (state.grade.series !== 0) {
        // grade.series==0是随机组卷，不需要保存答案
          const everySetAnswerStatistics = await _saveEverySetAnswerStatistics(currentAnswerWithQuestionType, answers.length, totalScoreInGrade, useSeconds, totalUseSeconds, correctRate, wrongCount, correctCount)

          for (let i = 0; i < answers.length; i++) {
            answers[i].everySetAnswerStatistics = everySetAnswerStatistics
            try {
              if (answers[i].ifDoAnswer) {
                answers[i].submitUnionCode = state.submitUnionCode
                await answers[i].save()
              }
            } catch (e) {
              continue
            }
          }
        }
      }

      return {
        key: answers.length > 0 ? answers[0].questionType.id : '',
        totalUseSeconds: totalUseSeconds,
        useSeconds,
        totalScoreInGrade: totalScoreInGrade,
        showName: answers.length > 0 ? answers[0].questionType.name : '',
        correctCount,
        questionType,
        wrongCount,
        correctRate
      }
    }

    // 计算这个grade
    const _statisticsGrade = async () => {
      const everyGradeStatistics = new EveryGradeStatistics()
      everyGradeStatistics.user = AV.User.current()
      everyGradeStatistics.student = store.state.student
      everyGradeStatistics.grade = state.grade
      let useSeconds = 0
      let correctCount = 0
      let totalScoreInGrade = 0
      let wrongCount = 0
      let correctRate = 0
      let totalUseSeconds = 0

      state.allQuestionTypeStatus.forEach((everyQuestionTypeStatus) => {
        if (everyQuestionTypeStatus.everySetAnswerStatistics) {
          console.log(everyQuestionTypeStatus.everySetAnswerStatistics)
          useSeconds += everyQuestionTypeStatus.everySetAnswerStatistics.useSeconds
          totalUseSeconds += everyQuestionTypeStatus.everySetAnswerStatistics.totalUseSeconds
          totalScoreInGrade += everyQuestionTypeStatus.everySetAnswerStatistics.totalScoreInGrade
          wrongCount += everyQuestionTypeStatus.everySetAnswerStatistics.wrongCount
          correctCount = everyQuestionTypeStatus.everySetAnswerStatistics.correctCount
          correctRate += everyQuestionTypeStatus.everySetAnswerStatistics.correctRate
        }
      })

      everyGradeStatistics.useSeconds = useSeconds
      everyGradeStatistics.totalUseSeconds = totalUseSeconds
      everyGradeStatistics.score = totalScoreInGrade
      everyGradeStatistics.wrongCount = wrongCount
      everyGradeStatistics.correctCount = correctCount
      everyGradeStatistics.correctRate = Util.formatCorrect(correctRate, state.allQuestionTypeStatus.length) / 100
      everyGradeStatistics.channel = 'web'
      everyGradeStatistics.isAllCorrect = (correctRate === 100)
      everyGradeStatistics.submitUnionCode = state.submitUnionCode
      await everyGradeStatistics.save()
    }

    const volumeChange = async () => {
      // console.log(state.volume)
      Howler.volume(state.volume / 100)
    }
    return {
      volumeChange,
      deviceCheckDone,
      subjectVoicePlayEnd,
      secondSubjectVoicePlayEnd,
      toNextQuestion,
      toPreQuestion,
      setAnswerData,
      changeQuestionType,
      toShowTips,
      onCountDownProgress,
      toSubmit,
      ...toRefs(state)
    }
  }
}
</script>
<style>
/* pages/quesiton/list.wxss */
.xuanzhon {
  border: 3px solid #FFF;
  /* background: #E0F7EF; */

}

.weixuanzhon {
  border: 3px solid #E6E6E6;

}
.jvzhon{
  display: flex;
  flex-direction: column;
  justify-content: center;

}
.que1{
  border:3px solid #00D48E;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.16);

}
.que1w{
  border: 3px solid #FFF;
}
.xuanzhon2{
  background-color: #00D48E;
  border-radius: 13px;
}
.slidecontainer {
  width: 100%; /* Width of the outside container */
}

</style>
