<template>

  <div class="font-HLFont ">
    <input
      type="checkbox"
      id="hl-confirm-modal"
      class="modal-toggle"
      checked
    >
    <div class="modal">
      <div
        class="modal-box flex flex-col  items-center "
        style="width:600px"
      >
        <p class="text-lg ">添加班级</p>

        <div class="flex flex-row  items-center    my-5">

          <div class="w-28">年级</div>
          <div class="form-control ml-10 w-48  ">

            <Listbox v-model="selectedGrade">
              <div class="relative mt-1">
                <ListboxButton class="relative cursor-pointer w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                  <span class="block truncate">{{ selectedGrade.name }}</span>
                  <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon
                      class="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </ListboxButton>

                <transition
                  leave-active-class="transition duration-100 ease-in"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <ListboxOptions class="absolute  z-50 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    <ListboxOption
                      v-slot="{ active, selected }"
                      v-for="grade in allGrade"
                      :key="grade.name"
                      :value="grade"
                      as="template"
                    >
                      <li :class="[
                  active ? 'text-amber-900 bg-amber-100' : 'text-gray-900',
                  'cursor-default select-none relative py-2 pl-10 pr-4',
                ]">
                        <span :class="[
                    selected ? 'font-medium' : 'font-normal',
                    'block truncate',
                  ]">{{ grade.name }}</span>
                        <span
                          v-if="selected"
                          class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
                        >
                          <CheckIcon
                            class="w-5 h-5"
                            aria-hidden="true"
                          />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>

          </div>
        </div>
          <div class="flex flex-row  items-center    my-5">

          <div class="w-28">班级</div>
          <div class="form-control ml-10   w-48 ">

            <Listbox v-model="selectedClass" >
              <div class="relative mt-1 ">
                <ListboxButton class="relative  cursor-pointer w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                  <span class="block truncate">{{ selectedClass.name }}</span>
                  <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon
                      class="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </ListboxButton>

                <transition
                  leave-active-class="transition duration-100 ease-in"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <ListboxOptions class="absolute z-50 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    <ListboxOption
                      v-slot="{ active, selected }"
                      v-for="classes in allClass"
                      :key="classes.name"
                      :value="classes"
                      as="template"
                    >
                      <li :class="[
                  active ? 'text-amber-900 bg-amber-100' : 'text-gray-900',
                  'cursor-default select-none relative py-2 pl-10 pr-4',
                ]">
                        <span :class="[
                    selected ? 'font-medium' : 'font-normal',
                    'block truncate',
                  ]">{{ classes.name }}</span>
                        <span
                          v-if="selected"
                          class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
                        >
                          <CheckIcon
                            class="w-5 h-5"
                            aria-hidden="true"
                          />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>

          </div>
        </div>

        <div class="modal-action mt-20 ">
          <button

            @click="createGroup"
            for="hl-confirm-modal"
            class="w-40 btn bg-gyjdGreen-500 border-gyjdGreen-500 hover:bg-gyjdGreen-600 hover:border-gyjdGreen-600 mx-4"

          >确定</button>
          <button
            @click="close"
            for="hl-confirm-modal"
            class="btn btn-warning w-40  mx-4"
          >取消</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script  >
import { defineComponent, reactive, ref, toRefs } from 'vue'
import HLDialog from '@/components/dialog.js'
import AV from 'leancloud-storage'
import DayJS from 'dayjs'
import Util from '@/utils/util.js'
import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption
} from '@headlessui/vue'
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
const generateCode = async (min) => {
  var str = ''
  var range = min
  var arr = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'J',
    'K',
    'L',
    'M',
    'N',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z'
  ]
  for (var i = 0; i < range; i++) {
    const pos = Math.round(Math.random() * (arr.length - 1))
    str += arr[pos]
  }
  return str
}
const allGrade = [
  { name: '7' },
  { name: '8' },
  { name: '9' }
]
const allClass = [
  { name: '1' },
  { name: '2' },
  { name: '3' },
  { name: '4' },
  { name: '5' },
  { name: '6' }, { name: '7' },
  { name: '8' },
  { name: '9' }, { name: '10' },
  { name: '11' },
  { name: '12' }, { name: '13' },
  { name: '14' },
  { name: '15' }, { name: '16' },
  { name: '17' },
  { name: '18' }, { name: '19' },
  { name: '20' },
  { name: '21' }, { name: '22' },
  { name: '23' },
  { name: '24' }, { name: '25' },
  { name: '26' },
  { name: '27' }, { name: '28' }, { name: '29' },
  { name: '30' },
  { name: '31' }
]
export default defineComponent({
  components: {
    Listbox,
    ListboxLabel,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    CheckIcon,
    SelectorIcon
  },
  name: 'HL-change-password',
  props: ['teacher'],
  emits: ['done'],
  setup (props, { emit }) {
    const state = reactive({
      selectedGrade: allGrade[0],
      selectedClass: allClass[0],
      requesting: ref(false)
    })
    const close = () => {
      emit('done')
    }

    const createGroup = async () => {
      state.requesting = true
      //   console.log(state.selectedGrade)
      const group = new AV.Object('GYJDGroup')
      group.set('teacher', props.teacher)
      group.set('school', props.teacher.get('school'))
      group.set('gradeIndex', Number(state.selectedGrade.name))
      group.set('classIndex', Number(state.selectedClass.name))
      group.set('numberOfMembers', 0)
      let invitationCode
      while (true) {
        invitationCode = await generateCode(8)
        const hasSameCode = await new AV.Query('GYJDGroup').equalTo('invitationCode', invitationCode).count() > 0
        if (!hasSameCode) {
          break
        }
      }

      group.set('invitationCode', invitationCode)
      try {
        await group.save()
        emit('done', group)
      } catch {
        HLDialog({
          content: '你已创建过该班级'
        })
      }

      //   if (!state.password || state.password.length === 0) {
      //     HLDialog({
      //       content: '请输入密码'
      //     })
      //     return
      //   }
      //   if (state.password.length < 6) {
      //     HLDialog({
      //       content: '密码不少于6位'
      //     })
      //     return
      //   }

    //   state.student.set('password', state.password)
    //   await state.student.save()
    //   close()
    }
    return {
      createGroup,
      allGrade,
      allClass,
      close,
      ...toRefs(state)

    }
  }
})
</script>
