<template>
  <!-- <router-view v-if="routerName == 'Login'" v-slot="{ Component }">

    <component :is="Component" />
  </router-view> -->

  <div
    class="h-screen  scrollbar scrollbar-thin scrollbar-thumb-gyjdGreen-500 bg-gray-50 "
  >
    <header v-if="routerName != 'Login' && routerName != 'ApplyTeacher' && routerName != 'Admin'  && routerName!='StudentPaper' &routerName!='StudentLearningDataDetail' ">
      <global-header></global-header>
    </header>

    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>

    <footer >
      <global-footer ></global-footer>
    </footer>
  </div>
</template>

<script>
import '@/assets/tailwind.css'
import { onMounted, reactive, toRefs, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import Util from '@/utils/util.js'
import AV from 'leancloud-storage'
import Footer from './views/common/footer.vue'
import Header from './views/common/header.vue'
import router from './router'

export default {
  components: {
    Footer,
    Header
  },

  setup () {
    const route = useRoute()
    const store = useStore()
    const state = reactive({
      initDone: false,
      isLogin: true,
      routerName: 'Login',
      studentJSON: {},
      version: 1.6
    })

    watch(
      () => route.name,
      () => {
        state.routerName = route.name
      }
    )

    onMounted(async () => {
      if (!localStorage.getItem('version') || Number(localStorage.getItem('version')) < state.version) {
        localStorage.setItem('version', state.version + '')
        if (AV.User.current()) {
          AV.User.logOut()
        }

        router.push({ name: 'Login' })
        return
      } else {
        localStorage.setItem('version', state.version + '')
      }
      if (AV.User.current() && !store.state.student) {
        store.state.student = await new AV.Query('GYJDStudent')
          .equalTo('user', AV.User.current())
          .include('group')
          .include('school')
          .include('teacher')
          .include('user')
          .equalTo('default', true)
          .first()
        if (store.state.student) {
          store.state.studentJSON = Util._jsonify(store.state.student)
          state.initDone = true
        } else {
          if (AV.User.current()) {
            AV.User.logOut()
          }
          router.push({ name: 'Login' })
        }

        // console.log(store.state.student)
        // location.reload()
      } else {
        state.isLogin = false
      }
    })
    return {
      ...toRefs(state)
    }
  }
}
</script>
<style>
@font-face {
  font-family: "HL-Font";
  src: url("https://file.nj.hlingsoft.com/iWJWAOIjd4Fw9QMrr7Joj4m2R0OOPJan/SourceHanSerifCN-Bold.otf") format("OpenType");
}
#app {
  font-family: "HL-Font";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
