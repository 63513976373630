<template>
  <div class="flex flex-row p-10">
    <!-- 题干-->
    <div class="flex-1 flex flex-col justify-center items-center">
      <HLVoice
        :url="question.questionData[0][0].voice.url"
        :stop="requesting"
        @onEnd="onQuestionStemVoiceEnd"
        @onPlay="onQuestionStemVoicePlay"
      />
      <div class="text-gray-500 mt-2">语音播放完毕后,系统自动开启录音.</div>
      <img
       :src="question.questionData[0][0].image.url+'?imageView2/2/500' "
        class="mt-10 h-40"
      >
    </div>

    <div class="flex-1 flex flex-col  justify-center items-center">
      <article
        v-for="(questionItem,index)  in question.questionData[0]"
        :key="index"
        class="prose prose-lg"
      >
        {{ questionItem.intro }}
      </article>
      <div class="w-full">
        <div class="w-full flex flex-col  justify-center items-center">
          <HLRecord
            v-if="showRecordComponent"
            :question="question"
            @onEnd="onRecordEnd"
          />
        </div>
        <div
          v-if="resultData"
          class="flex flex-row"
        >

          <HLEvaluationResult
            class="flex-1 mt-10"
            :question="question"
            :data="resultData"
          />
          <div class=" flex-1 flex flex-col p-10 justify-center ">
            <button class="btn btn-outline btn-primary w-60">
              <HLVoiceButton
                :url="resultData.userVoiceUrl"
                :text="myVoiceText"
              />
            </button>

            <button @click="toNextQuestion" class="btn btn-outline btn-primary mt-10" :disabled="requesting">下一题</button>
          </div>
        </div>

      </div>
    </div>

  </div>

</template>
<script>
import { reactive, toRefs, defineComponent, ref } from 'vue'
import HLVoice from '@/views/components/common/voice.vue'
import HLRecord from '@/views/components/common/record.vue'
import HLEvaluationResult from '@/views/components/common/evaluationResult.vue'
import HLVoiceButton from '@/views/components/common/voiceButton.vue'
import _ from 'lodash'
export default defineComponent({
  components: {
    HLVoice,
    HLRecord,
    HLEvaluationResult,
    HLVoiceButton

  },
  name: 'HL-kouyugendu',
  props: ['question'],
  emits: ['setAnswerData'],
  setup (props, { emit }) {
    const state = reactive({
      question: props.question,
      seconds: 0,
      myVoiceText: '我的录音',
      correct: false,
      requesting: false,
      choiceIndex: -1,
      showRecordComponent: false,
      currentShowVoiceButtonIndex: -1,
      resultData: ref(null),
      timerInterval: setInterval(function () {
        state.seconds += 1
      }, 1000)

    })

    const onQuestionStemVoicePlay = async () => {
      state.showRecordComponent = false
      state.resultData = ref(null)
    }
    const onQuestionStemVoiceEnd = async () => {
      _.delay(function () {
        state.showRecordComponent = true
        state.resultData = ref(null)
      }, 500, '')
    }
    const onRecordEnd = async (data) => {
    //   console.log('onRecordEnd', data)
      state.showRecordComponent = false
      state.resultData = data
    }

    const toNextQuestion = async () => {
      if (!state.requesting) {
        state.requesting = true
        const evaluationData = state.resultData.evaluationData.EngineResult
        emit('setAnswerData', {
          useSeconds: state.seconds,
          evalType: evaluationData.EvalType,
          answerDetail: evaluationData,
          voiceUrl: state.resultData.userVoiceUrl,
          evaluationSeconds: state.resultData.evaluationSeconds,
          score: evaluationData.score,
          correct: evaluationData.score >= 60,
          voiceSpeed: evaluationData.voiceSpeed,
          fluency: Math.ceil(evaluationData.lines[0].fluency),
          sample: evaluationData.lines[0].sample,
          usertext: evaluationData.lines[0].usertext,
          integrity: Math.ceil(evaluationData.lines[0].integrity),
          pronunciation: Math.ceil(evaluationData.lines[0].pronunciation)
        })
      }
    }
    return {
      toNextQuestion,
      onQuestionStemVoicePlay,
      onQuestionStemVoiceEnd,

      onRecordEnd,
      ...toRefs(state)
    }
  }
})

</script>
