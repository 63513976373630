<template>
  <div
  style="background:#F4F6F1"
    class="w-full min-h-screen   flex flex-row justify-center items-center "
  >
    <!-- 列表页面 -->
    <div
      v-if="allTestPaper && allTestPaper.length>0"
      class="w-11/12 h-full mx-auto bg-white   rounded-2xl"
    >

      <ul class="w-5/6 mx-auto pb-10 ">
        <li
           @click="startTest(training.objectId)"
          v-for="training in allTestPaper"
          :key="training.objectId"
          style="box-shadow: 0px 3px 20px rgba(159, 159, 159, 0.16);"
          class="cursor-pointer flex flex-row  justify-between items-center h-20 w-50 rounded-lg pl-6 pr-10  mt-4"
        >
          <div class="flex flex-row justify-center items-center">
            <img src="@/assets/images/test_paper.png" alt="" class="w-5 h-6" />
            <div class="ml-4">{{ training.name }}</div>
          </div>
          <div
            class="bg-gyjdGreen-600 text-white rounded-lg pt-2 pb-2 pl-6 pr-6 "

          >
           <!-- operationTypeTextArray: ['专项训练-听力', '专项训练-口语', '模拟训练-考试', '模拟训练-练习', '随机组卷-考试', '随机组卷-练习'] -->
            {{operationType==2?'开始考试':'进入练习'}}
          </div>
        </li>
      </ul>
    </div>
    <!-- 加载提示页面 -->
  <div v-else>

    <button class="btn loading">数据加载中...</button>
  </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, defineComponent } from 'vue'
import { useStore } from 'vuex'
import AV, { Query } from 'leancloud-storage'
import DayJS from 'dayjs'
import Util from '@/utils/util.js'

import router from '@/router'

export default defineComponent({
  name: 'gyjd-grade-list',
  props: ['series', 'operationType'],
  emits: ['toDoAnswer'],
  setup (props, { emit }) {
    const store = useStore()
    const state = reactive({
      allTestPaper: []
    })

    onMounted(async () => {
      state.allTestPaper = await getAllTestPaper()
    })

    // 获取所有的试卷
    const getAllTestPaper = async () => {
      const grades = await new AV.Query('GYJDGrade')
        .equalTo('series', props.series)
        .descending('indexInt')
        .find()

      return Util._jsonify(grades)
    }
    const startTest = (gradeId) => {
      router.push({ name: 'Answer', params: { gradeId, operationType: props.operationType } })
    }
    // 开始考试
    // const startTest = (event) => {
    //   console.log('进入模拟考试,先进入加载页面')
    //   console.log(event.target._value)
    //   // state.isShow = false;
    //   console.log('数据加载完成，进入答题页面')

    //   router.push({
    //     name: 'Answer',
    //     params: { gradeId: event.target._value }
    //   })
    // }
    return {

      getAllTestPaper,
      startTest,
      ...toRefs(state)
    }
  }
})
</script>
