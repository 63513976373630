<template>
  <div class="font-HLFont ">
    <input type="checkbox" id="hl-dialog-modal" class="modal-toggle" checked>
    <div class="modal">
     <div class="modal-box flex flex-col  items-center " style="width:300px" >
        <p class="text-xs text-warmGray-500">小提示</p>
        <p class="  text-warmGray-800 p-4 text-base">{{content}}</p>
        <div class="modal-action mt-5">
          <button @click="doneAndNext" for="hl-confirm-modal" class="w-40 btn bg-gyjdGreen-500 border-gyjdGreen-500 hover:bg-gyjdGreen-600 hover:border-gyjdGreen-600" style="border-radius: 55px 10px 55px 10px;">知道了</button>
          <!-- <button @click="close" for="hl-confirm-modal" class="btn btn-warning w-20  " style="border-radius: 10px 10px 245px 10px;">否</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script  >
import { defineComponent } from 'vue'

export default defineComponent({
  components: {

  },
  name: 'hl-dialog',
  props: {
    content: {
      type: String,
      required: true
    },
    doneAndNext: {
      type: Function,
      default: () => {
        return Function
      }
    }

  },
  setup () {
    return {

    }
  }
})
</script>
