<template>
   <div class="hidden w-28 bg-indigo-600 overflow-y-auto md:block scrollbar scrollbar-thumb-purple-400 scrollbar-track-gray-100">
      <div class="w-full py-3 flex flex-col items-center">
        <div class="flex-shrink-0 flex items-center">
          <img class="h-20 w-auto" src="@/assets/logo.png" alt="Workflow" />
        </div>
        <div class="flex-1 mt-6 w-full px-2 space-y-1 py-8">
          <a v-for="item in sidebarNavigation" :key="item.name"  @click="toView(item.routeName)"  :class="[item.current ? 'bg-indigo-500 text-white' : 'text-indigo-100 hover:bg-indigo-700 hover:text-white', 'group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium']" :aria-current="item.current  ? 'page' : undefined">
            <component :is="item.icon" :class="[item.current  ? 'text-white' : 'text-indigo-300 group-hover:text-white', 'h-6 w-6']" aria-hidden="true" />
            <span class="mt-2">{{ item.name }}</span>
          </a>
        </div>
      </div>

    </div>

</template>

<script>
import { defineComponent, watch, reactive, toRefs } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import {
  CollectionIcon,
  ViewGridIcon,
  HomeIcon,
  UserGroupIcon,
  UserIcon,
  SparklesIcon,
  ShoppingBagIcon,
  QuestionMarkCircleIcon,
  CurrencyYenIcon

} from '@heroicons/vue/outline'

export default defineComponent({
  components: {

  },
  name: 'global-left',
  setup () {
    const route = useRoute()
    const router = useRouter()
    const state = reactive({
      sidebarNavigation: [
        { name: '首页', index: 0, href: '', icon: HomeIcon, routeName: 'Home', pre: 'home', current: true },
        { name: '店铺管理', index: 1, href: '', icon: SparklesIcon, routeName: 'Shop', pre: 'shop', current: false },

        { name: '素材管理', index: 2, href: '', icon: CollectionIcon, pre: 'material', routeName: 'Material', current: false },
        { name: '课程管理', index: 3, href: '', icon: ViewGridIcon, pre: 'course', routeName: 'Course', current: false },
        { name: '课后练习', index: 4, href: '', icon: QuestionMarkCircleIcon, pre: 'question', routeName: 'Question', current: false },
        { name: '用户管理', index: 5, href: '', icon: UserGroupIcon, pre: 'student', routeName: 'Student', current: false },
        { name: '会员卡管理', index: 6, href: '', icon: CurrencyYenIcon, pre: 'memberCard', routeName: 'MemberCard', current: false },
        { name: '订单管理', index: 7, href: '', icon: ShoppingBagIcon, pre: 'order', routeName: 'Order', current: false },
        { name: '优惠券管理', index: 8, href: '', icon: UserIcon, routeName: 'Function', pre: 'function', current: false },
        { name: '分销推广', index: 9, href: '', icon: UserIcon, routeName: 'Function', pre: 'function', current: false }
        // { name: '个人信息管理', index: 5, href: '', icon: UserIcon, pre: 'mine', routeName: 'Mine', current: false }
        //   { name: '用户管理', href: '#', icon: CollectionIcon, current: false }
      ]

    })

    watch(() => route.name, () => {
      state.sidebarNavigation.forEach(navigator => {
        console.log(navigator.pre, route.path)
        // eslint-disable-next-line eqeqeq
        if (route.path == '/' || route.path == '') {
          // eslint-disable-next-line eqeqeq
          if (navigator.index == 0) {
            navigator.current = true
          } else {
            navigator.current = false
          }
        } else {
          // eslint-disable-next-line eqeqeq
          if (route.path.split('/')[1] == navigator.pre || route.path.split('/')[0] == navigator.pre) {
            navigator.current = true
          } else {
            navigator.current = false
          }
        }
      })

      console.log('sidebarNavigation', state.sidebarNavigation)
    }
    )

    const toView = (routeName) => {
      router.push({ name: routeName })
    }

    return {
      route,
      router,
      toView,
      ...toRefs(state)

    }
  }
})
</script>
